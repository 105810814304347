import * as THREE from 'three';
import { isStrAt, strFromArrayBuffer } from '../utils/binary-utils.js'

export class LaserScan {
    timeSec = 0;
    timeNanosec = 0;
    angleMin = 0.0;
    angleMax = 0.0;
    angleIncrement = 0.0;
    timeIncrement = 0.0;
    scanTime = 0;
    rangeMin = 0.0;
    rangeMax = 0.0;
    ranges = [];
    visibleCount = 0;

    load(url) {
        const loader = new THREE.FileLoader();
        loader.setResponseType('arraybuffer');
        loader.loadAsync(url)
            .then(data => {
                this.parseDaaveBinary(data);
            })
            .catch(err => {
                console.error('Error loading file:', err);
            });
    }

    parseDaaveBinary(buffer, byteOffset=0) {
        const dv = new DataView(buffer, byteOffset);

        // 0 - 7: header id string
        const DAAVE_LS = 'DAAVE_LS';
        if (!isStrAt(dv, DAAVE_LS, 0)) {
            const headerId = strFromArrayBuffer(buffer, byteOffset, DAAVE_LS.length);
            console.error(`Invalid file format. Unknown header ID: '${headerId}'.`);
            return 0;
        }

        // 8: version - uint8
        const ver = dv.getUint8(8);
        if (ver !== 1 && ver !== 2) {
            console.error(`DAAVE_LS: Invalid file version. Should be 1 or 2, but is ${ver}.`);
            return 0;
        }

        // 9 - 12: timestamp - sec - int32
        this.timeSec = dv.getInt32(9, true);

        // 13 - 16: timestamp - nanosec - uint32
        this.timeNanosec = dv.getUint32(13, true);

        // 17 - 44: position - float32 * 7
        this.angleMin = dv.getFloat32(17, true);
        this.angleMax = dv.getFloat32(21, true);
        this.angleIncrement = dv.getFloat32(25, true);
        this.timeIncrement = dv.getFloat32(29, true);
        this.scanTime = dv.getFloat32(33, true);
        this.rangeMin = dv.getFloat32(37, true);
        this.rangeMax = dv.getFloat32(41, true);

       // 45 - 50: data id string
       if (!isStrAt(dv, 'RANGES', 45)) {
            console.error(`Invalid file format. Expected 'RANGES' marker at position 45.`);
            return 0;
        }

        // 51 - the end: ranges array
        let rangesOffset = 51;
       let rangesCount = 0;
        if (ver === 2) {
            rangesCount = dv.getUint32(51, true);
            rangesOffset += 4;
        } else {
            rangesCount = Math.ceil((this.angleMax - this.angleMin) / this.angleIncrement);
        }
        const rangesSize = this.rangesCount * Float32Array.BYTES_PER_ELEMENT;
        const rangesBegin = byteOffset + rangesOffset;
        const rangesEnd = rangesBegin + rangesSize + 1;
        this.ranges = new Float32Array(buffer.slice(rangesBegin, rangesEnd), 0, rangesCount);

        this.#calcVisibleCount();

        return rangesOffset + rangesSize; // size of a single record + size of ranges array in bytes
    }

    static fromJSON(json) {
        const ls = new LaserScan();
        Object.assign(ls, JSON.parse(json));
        ls.timeNanosec = ls.timeNanoSec
        ls.#calcVisibleCount()
        return ls;
    }

    #calcVisibleCount() {
        this.visibleCount = 0;
        for (let k = 0; k < this.ranges.length; ++k) {
            const range = this.ranges[k];
            if (range >= this.rangeMin && range <= this.rangeMax) {
                ++this.visibleCount;
            }
        }
    }
}

export class LaserScanCalc {
    #scanPos = new THREE.Matrix4();
    #scanRot = new THREE.Matrix4();
    #scanOrient = new THREE.Quaternion();
    #pos = new THREE.Matrix4();
    #rot = new THREE.Matrix4();
    #trans = new THREE.Matrix4();
    #smoothening = false;
    #globalRotation;
    #globalTranslation;

    constructor(globalRotation=0.0, globalXOffset=0.0, globalYOffset=0.0, smoothening=false) {
        this.#globalRotation = new THREE.Matrix4().makeRotationY(THREE.MathUtils.degToRad(globalRotation));
        this.#globalTranslation = new THREE.Matrix4().makeTranslation(globalXOffset, 0.0, globalYOffset);
        this.#smoothening = smoothening;
    }

    setScanPos(pos) {
        const p = pos.position;
        const o = pos.orientation;
        this.#scanPos.makeTranslation(p.x, p.y, p.z);
        if (this.#smoothening)
            this.#scanOrient.set(0.0, o.z, 0.0, o.w);
        else
            this.#scanOrient.set(o.x, o.z, o.y, o.w);
        this.#scanRot.makeRotationFromQuaternion(this.#scanOrient);
        this.#scanPos.multiply(this.#globalRotation);
        this.#scanPos.multiply(this.#scanRot);
    }

    calcPointMatrix(scan, pointIndex) {
        this.#pos.copy(this.#scanPos);
        this.#rot.makeRotationY(scan.angleMin + scan.angleIncrement * pointIndex);
        this.#trans.makeTranslation(-scan.ranges[pointIndex], 0.0, 0.0);
        this.#pos.multiply(this.#rot.multiply(this.#trans));
        return this.#pos;
    }
}