
export const lineIntersection = (p1, p2, p3, p4) => {
    const s = ((p4.x - p3.x) * (p1.y - p3.y) - (p4.y - p3.y) * (p1.x - p3.x))
            / ((p4.y - p3.y) * (p2.x - p1.x) - (p4.x - p3.x) * (p2.y - p1.y));
    return {
        x: p1.x + s * (p2.x - p1.x),
        y: p1.y + s * (p2.y - p1.y),
        s: s
    }
};

export const lineSegmentIntersection = (p1, p2, p3, p4) => {
    const i1 = lineIntersection(p1, p2, p3, p4);
    const i2 = lineIntersection(p3, p4, p1, p2);
    return {
        i1: i1,
        i2: i2,
        intersects: i1.s >= 0.0 && i1.s <= 1.0 && i2.s >= 0.0 && i2.s <= 1.0
    }
};

export const pointToPointDistance = (x1, y1, x2, y2) => {
    const dx = x1 - x2;
    const dy = y1 - y2;
    return Math.sqrt(dx * dx + dy * dy);
};
