import React, { useCallback, useState } from "react";
import { Project } from "../../types/project";
import "./styles.scss";
import { Button } from "reactstrap";
import { useRecoilState } from "recoil";
import { withAuthenticator } from "@aws-amplify/ui-react";
import DeleteProjectModal from "../../components/Modals/DeleteProjectModal/DeleteProjectModal";
import { selectedProjectAtom } from "../../recoil/atoms/selectedProject.atom";

interface IDeleteProjectModal {
  name: "DeleteProject";
}

type VisibleModal = IDeleteProjectModal | undefined;

type ProjectCardProps = {
  project: Project;
  signOut?: any;
  user?: any;
};

const ProjectCard: React.FC<ProjectCardProps> = ({ project }) => {
  const [, setSelectedProject] = useRecoilState(selectedProjectAtom);
  const [visibleModal, setVisibleModal] = useState<VisibleModal>();
  const [, setErrorMessage] = useState<string>("");

  const handleModalClose = useCallback(() => {
    setVisibleModal(undefined);
  }, []);

  const handleModalOpen = useCallback((modalType: any) => {
    setVisibleModal({ name: modalType });
  }, []);

  const renderModals = useCallback(() => {
    if (visibleModal !== undefined) {
      switch (visibleModal.name) {
        case "DeleteProject":
          return (
            <DeleteProjectModal
              onClose={handleModalClose}
              setErrorMessage={setErrorMessage}
              projectId={project.id}
            />
          );
      }
    }
  }, [handleModalClose, project.id, visibleModal]);

  return (
    <div className="projectCard col-lg-4 col-md-6">
      {renderModals()}
      <div className="card card-stats">
        <div className="card-body pb-0">
          <div className="row">
            <div className="card">
              <div className="card-header pt-0">
                <div className="text-right">
                  <Button
                    onClick={() => {
                      handleModalOpen("DeleteProject"); // @ts-ignore
                      setSelectedProject(project.id);
                    }}
                    className="btn-icon btn-simple"
                    color="secondary"
                    size="sm"
                    id={`delete-${project.name}`}
                  >
                    <i className="fa fa-times" />
                  </Button>
                </div>
                <p className="card-category">Project name</p>
                <a href={`/projects/${project.id}`}>
                  <h3 className="projectName card-title" title={project.name}>
                    {project.name}
                  </h3>
                </a>
              </div>
              <div className="card-body">
                <div className="tab-content">
                  <div title={project.address} className="entryLine">
                    <label className="card-label">Address:</label>&nbsp;
                    <p className="projectAddress d-inline-block ml-2">
                      {project.address ? (
                        <a href={`/map/${project.id}`}>{project.address}</a>
                      ) : (
                        <>&nbsp;</>
                      )}
                    </p>
                  </div>
                  <div>
                    <label className="card-label">Users:</label>&nbsp;
                    <p className="d-inline-block ml-1">{project.userCount}</p>
                  </div>
                  <div>
                    <label className="card-label">Drones:</label>&nbsp;
                    <p className="d-inline-block ml-2">{project.drones}</p>
                    <div className="isDroneActive d-inline-block"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card-footer">
          <div className="stats">
            <div>
              <label className="card-label">Site manager:</label>&nbsp;
              <p className="d-inline-block">
                {project.siteManager || <span>&mdash;</span>}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withAuthenticator(ProjectCard);
