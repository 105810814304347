import React from "react";
import { IconContext } from "react-icons";
import { MdOutlineArchitecture } from "react-icons/md";
import { BsFillCameraVideoFill } from "react-icons/bs";
import { Col, CustomInput, Row } from "reactstrap";

import "./styles.scss";
import { AnalysisScreen } from "../../pages/Analysis/Analysis";

interface Props {
  mainScreen: AnalysisScreen;
  setMainScreen: (val: AnalysisScreen) => void;
}

const ViewSwitcher: React.FC<Props> = ({ mainScreen, setMainScreen }) => {
  return (
    <Row className="viewSwitcherContainer">
      <Col className="text-right">
        <IconContext.Provider value={{ size: "1.5rem" }}>
          <MdOutlineArchitecture color="white" />
        </IconContext.Provider>
      </Col>
      <Col className="text-center customSwitch" xs={2}>
        <CustomInput
          type="switch"
          id="switch-camera-map"
          onChange={() =>
            setMainScreen(
              mainScreen === "building-plan" ? "camera" : "building-plan"
            )
          }
        />
      </Col>
      <Col className="text-left">
        <IconContext.Provider value={{ size: "1.5rem" }}>
          <BsFillCameraVideoFill color="white" />
        </IconContext.Provider>
      </Col>
    </Row>
  );
};

export default ViewSwitcher;
