import * as THREE from 'three';

import { TimeSeeker, dispatchOnTimeChangedEvent } from './utils/time-utils.js';
import { LaserScan, LaserScanCalc } from './model/laser-scan.js'


const boxColor = new THREE.Color('darkred');


export class LaserScanSet {
    #projectContext;
    #scanPoints;
    #boxGeometry;
    #boxMaterial;
    #scanPos = 0;
    #smoothening = true;
    #globalRotation = 0.0;
    #globalXOffset = 0.0;
    #globalYOffset = 0.0;
    #visible = true;
    #visibleSpan = 0;
    #timeSeeker;

    scans = []; // array of LaserScan items

    constructor(projectContext) {
        this.#projectContext = projectContext;

        this.#boxGeometry = new THREE.BoxGeometry(0.02, 0.02, 0.02);
        this.#boxMaterial = new THREE.MeshBasicMaterial({ color: boxColor });
        this.#initTimeSeeker()
    }


    #initTimeSeeker(){
        this.#timeSeeker = new TimeSeeker(this.scans, 'LaserScanSet');
    }


    loadSet(ddeUrl, laserScanSet) {
        console.log('Loading laser scan ...')
        laserScanSet.forEach(fileInfo => {
            const o = new LaserScan();
            o.load(ddeUrl + fileInfo.filename);
            this.scans.push(o);
        });
        this.#timeSeeker = new TimeSeeker(this.scans, 'LaserScanSet');
        this.#timeSeeker.sort();
        this.#dispatchLoadedEvent();
        this.#update();
    }

    wsReceive(laserScanJson){
      const ls =  LaserScan.fromJSON(laserScanJson)
      this.scans.push(ls)
      this.setPosByTime(ls.timeSec, ls.timeNanosec)
    }

    loadJoinedSet(data) {
        console.log('Loading joined laser scans ...')

        let dataSize = data.byteLength;
        let byteOffset = 0;
        while (byteOffset < dataSize) {
            const o = new LaserScan();
            const read = o.parseDaaveBinary(data, byteOffset);
            if (read === 0)
                break;
            byteOffset += read;
            this.scans.push(o);
        }

        this.scans.push(data)
        this.#timeSeeker = new TimeSeeker(this.scans, 'LaserScanSet');
        this.#timeSeeker.sort();
        this.#dispatchLoadedEvent();
        this.#update();
    }

    #dispatchLoadedEvent() {
        console.log(`laser scan set loaded: ${this.scans.length}`);
        const event = new CustomEvent('laser-scan-set-loaded', { detail: { scans: this.scans }});
        document.dispatchEvent(event);
    }

    set smoothening(s) {
        this.#smoothening = s;
        this.#update();
    }

    get smoothening() {
        return this.#smoothening;
    }

    set visible(visible) {
        this.#visible = visible;
        this.#update();
    }

    get visible() {
        return this.#visible;
    }

    set visibleSpan(s) {
        this.#visibleSpan = s;
        this.#update();
    }

    get visibleSpan() {
        return this.#visibleSpan;
    }

    get pos() {
        return this.#scanPos;
    }

    set globalRotation(gr) {
        this.#globalRotation = gr;
        this.#update();
    }

    get globalRotation() {
        return this.#globalRotation;
    }

    set globalXOffset(xOff) {
        this.#globalXOffset = xOff;
        this.#update();
    }

    set globalYOffset(yOff) {
        this.#globalYOffset = yOff;
        this.#update();
    }

    getLaserScan(idx) {
        if (idx > 0 && idx < this.scans.length) return this.scans[idx];
    }

    setPos(pos, timeChangeNotify=true) {
        if (pos > -1 && pos < this.scans.length) {
            this.#scanPos = pos;
            this.#update();

            if (timeChangeNotify)
                dispatchOnTimeChangedEvent(this, this.scans[pos], this.#projectContext.renderer.domElement);
        }
    }

    setPosByTime(sec, nanoSec) {
        const i = this.#timeSeeker.getIndexAt(sec, nanoSec);
        if (i > -1)
            this.setPos(i, false);
        return i;
    }

    clear() {
        if (this.scans.length) {
            this.scans = [];
            this.#update();
        }
    }

    dispose() {
        this.#disposeScanPoints();

        if (this.#boxGeometry) {
            this.#boxGeometry.dispose();
            this.#boxGeometry = null;
        }
        if (this.#boxMaterial) {
            this.#boxMaterial.dispose();
            this.#boxMaterial = null;
        }
    }

    #disposeScanPoints() {
        if (this.#scanPoints) {
            this.#projectContext.scene.remove(this.#scanPoints);
            this.#scanPoints.dispose();
            this.#scanPoints = null;
        }
    }

    #update() {
        if (this.scans.length > 0) {
            const halfSpan = this.#visibleSpan;
            let spanStart = this.#scanPos - halfSpan;
            let spanEnd = this.#scanPos + halfSpan + 1;
            if (spanStart < 0) spanStart = 0;
            if (spanEnd >= this.scans.length) spanEnd = this.scans.length;

            let visiblePointsCount = 0;
            for (let s = spanStart; s < spanEnd; ++s) {
                const scan = this.scans[s];
                visiblePointsCount += scan.visibleCount;
            }
            this.#disposeScanPoints();
            if (!this.#visible) return;

            if (!this.#scanPoints || this.#scanPoints.instanceMatrix.count < visiblePointsCount) {
                this.#scanPoints = new THREE.InstancedMesh(this.#boxGeometry, this.#boxMaterial, Math.max(100000, visiblePointsCount));
                this.#projectContext.scene.add(this.#scanPoints);
            }
            this.#scanPoints.count = visiblePointsCount;

            let drawRange = 0;
            const localPosTrack = this.#projectContext.localPosTrack;
            if (localPosTrack) {
                const lsc = new LaserScanCalc(this.#globalRotation, this.#globalXOffset, this.#globalYOffset);
                for (let s = spanStart; s < spanEnd; ++s) {
                    const scan = this.scans[s];
                    const pos = localPosTrack.getLocalPosAtTime(scan.timeSec, scan.timeNanosec);
                    if (pos) {
                        lsc.setScanPos(pos, this.#smoothening);
                        for (let k = 0; k < scan.ranges.length; ++k) {
                            const range = scan.ranges[k];
                            if (range >= scan.rangeMin && range <= scan.rangeMax) {
                                this.#scanPoints.setMatrixAt(drawRange++, lsc.calcPointMatrix(scan, k));
                            }
                        }
                    }
                }
            }

            this.#scanPoints.instanceMatrix.needsUpdate = true;
            this.#scanPoints.geometry.setDrawRange(0, drawRange);
            this.#scanPoints.geometry.computeBoundingBox();
            this.#scanPoints.geometry.computeBoundingSphere();
        }
    }
}