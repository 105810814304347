import { useCallback, useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { WEBSOCKET_URL } from "../../config";

type WsConfig = {
  userId: string | undefined;
  missionId: string | undefined;
  connectionType: "mission" | "analysis";
  onMessage?: (message: MessageEvent["data"]) => void;
  onError?: (error: unknown) => void;
};

type ReturnType = {
  connected: boolean;
  socket: WebSocket | undefined;
  position: any;
  laserScan: any;
};

function useUAVWebsocket({
  userId,
  missionId,
  connectionType,
  onMessage,
  onError,
}: WsConfig): ReturnType {
  const [connected, setConnected] = useState(false);
  const [socket, setSocket] = useState<WebSocket>();
  const [position, setPosition] = useState();
  const [laserScan, setLaserScan] = useState();

  const handleMessage = useCallback((message: MessageEvent["data"]) => {
    if (message.type === "UAVPosition") {
      setPosition(message);
    }

    if (message.type === "LaserScan") {
      setLaserScan(message);
    }
  }, []);

  const connectWs = useCallback(async () => {
    if (!missionId || !userId) return;

    console.log("attempting WS connection");

    const currentTenant = localStorage.getItem("tenantName") || "Daave";
    const magicLinkToken = localStorage.getItem("magicLinkToken");

    let authKey = "";
    if (magicLinkToken) {
      authKey = magicLinkToken;
    } else {
      const user = await Auth.currentAuthenticatedUser();
      authKey = user.signInUserSession.idToken.jwtToken;
    }

    const connectionQuery = `?tenant=${currentTenant}&connectionType=${connectionType}&relatedEntityId=${missionId}&authKey=${authKey}`;

    const socket = new WebSocket(WEBSOCKET_URL + connectionQuery);
    setSocket(socket);

    socket.addEventListener("message", (event) => {
      // console.log("WS message from server ", event.data);
      const message = JSON.parse(event.data);
      handleMessage(message);

      if (onMessage) {
        onMessage(message);
      }
    });

    socket.addEventListener("error", (event) => {
      console.log("Error: ", event);
      if (onError) {
        onError(event);
      }
    });

    socket.addEventListener("open", () => {
      console.log("WS connection opened");
      setConnected(true);
      socket.send(JSON.stringify({ type: "UAVPosition" }));
    });

    socket.addEventListener("close", () => {
      console.log("WS connection closed");
      setConnected(false);
    });

    return socket;
  }, [missionId, userId, connectionType, handleMessage, onMessage, onError]);

  useEffect(() => {
    const socketReq = connectWs();

    return () => {
      socketReq.then((socket) => {
        if (socket) {
          console.log("disconnecting WS", socket);
          socket?.close();
        }
      });
    };
  }, [connectWs]);

  return { connected, socket, position, laserScan };
}

export default useUAVWebsocket;
