export const strFromArrayBuffer = (buffer, pos, len) => {
    return String.fromCharCode.apply(String, new Uint8Array(buffer, pos, len))
};

export const isStrAt = (dataView, str, pos) => {
    for (let n = 0; n < str.length; ++n) {
        // eslint-disable-next-line eqeqeq
        if (dataView.getUint8(pos + n) != str.charCodeAt(n))
            return false;
    }
    return true;
}

export const readString = (dataView, pos) => {
    const len = dataView.getUint8(pos);
    const str = strFromArrayBuffer(dataView.buffer, pos + 1, len);
    return [str, pos + len + 1];
}