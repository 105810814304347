import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import "./styles.scss";
import { userInfoAtom } from "../../recoil/atoms/userInfo.atom";
import BuildingPlanList from "../../components/BuildingPlanList/BuildingPlanList";
import CustomSidebar from "../../components/CustomSidebar/CustomSidebar";
import { Project } from "../../types/project";
import { securedApi } from "../../api";

interface Props {
  signOut?: any;
  user?: any;
}

export type ProjectPageParams = {
  projectId: string;
};

const ProjectPage: React.FC<Props> = () => {
  const [userInfo] = useRecoilState(userInfoAtom);
  const [project, setProject] = useState<Project>();
  const { projectId } = useParams<ProjectPageParams>();

  useEffect(() => {
    const fetchProject = () => {
      securedApi.get(`/projects/${projectId}`).then((res) => {
        setProject({
          name: res.data.name,
          id: res.data.id,
          address: res.data.address,
          drones: res.data.uavCount,
          userCount: res.data.userCount,
          siteManager: "",
        });
      });
    };

    fetchProject();
  }, [projectId, userInfo.userId]);

  return (
    <div className="projectPageContainer">
      <CustomSidebar />
      {project ? <BuildingPlanList projectName={project.name} /> : null}
    </div>
  );
};

export default ProjectPage;
