import React, { useEffect } from "react";
import Sidebar from "../../blackdashboard-react/components/Sidebar/Sidebar";
import { useLocation } from "react-router-dom";
import DashboardRoutes from "./routes/DashboardRoutes";
import ProjectRoutes from "./routes/ProjectRoutes";
import { useRecoilValue } from "recoil";
import { sidebarAtom } from "../../recoil/atoms/sidebar.atom";
import "./styles.scss";

interface Props {
  isExpanded?: boolean;
}

const CustomSidebar: React.FC<Props> = ({ isExpanded }) => {
  const path = useLocation().pathname.split("/")[1];
  const sidebar = useRecoilValue(sidebarAtom);

  const closeSidebar = () => {
    document.documentElement.classList.remove("nav-open");
  };

  useEffect(() => {
    if (isExpanded !== undefined) {
      if (isExpanded) {
        document.body.classList.remove("sidebar-mini");
      } else {
        document.body.classList.add("sidebar-mini");
      }
    } else {
      if (sidebar.miniSidebar) {
        document.body.classList.add("sidebar-mini");
      } else {
        document.body.classList.remove("sidebar-mini");
      }
    }
  }, [isExpanded, sidebar.miniSidebar]);

  return (
    <Sidebar
      routes={path === "projects" ? ProjectRoutes : DashboardRoutes}
      activeColor={"blue"}
      closeSidebar={closeSidebar}
    />
  );
};

export default CustomSidebar;
