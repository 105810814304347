import * as THREE from "three";
import { SVGLoader } from "three/addons/loaders/SVGLoader.js";

import * as PARAMS from "../params.js";
import { MARKER_EVENTS, MARKER_TYPES } from "../params.js";
import { saveBlob, loadLocalJson } from "../io.js";
import { PhotoSpot } from "./photo-spot.js";

const ROT90 = THREE.MathUtils.degToRad(90);

const arrowColor = new THREE.Color().setRGB(1.0, 0.1, 0.1);
const smallArrowColor = new THREE.Color().setRGB(0.7, 0.2, 0.2);

const tiangleShape = new THREE.Shape()
  .moveTo(0, 0)
  .lineTo(2.5, 3.0)
  .lineTo(-2.5, 3.0)
  .lineTo(0, 0);

const ARROW_SCALE = 0.06;
const arrowShape = new THREE.Shape()
  .moveTo(0.0, -1.5)
  .lineTo(2.5, 1.5)
  .lineTo(0.0, 0.5)
  .lineTo(-2.5, 1.5)
  .lineTo(0.0, -1.5);

const hoveredColor = new THREE.Color(0xff0000);
const selectedColor = new THREE.Color(0x0000ff);

const outlineStrokeStyle = {
  color: hoveredColor,
  strokeWidth: 1.0,
};

const EditState = Object.freeze({
  None: Symbol("None"),
  DrawStart: Symbol("DrawStart"),
  Draw: Symbol("Draw"),
  DrawEnd: Symbol("DrawEnd"),
  MoveStart: Symbol("MoveStart"),
  Move: Symbol("Move"),
});

export class FlightPlan {
  #projectContext;
  #group;
  #photoSpots = [];
  #trianglesInstancedMesh;
  #triangleShapeGeometry;
  #arrowInstancedMesh;
  #arrowShapeGeometry;
  #hoveredNode;
  #selectedNode;
  #hoveredArrowOutline;
  #selectedArrowOutline;
  #movedNode;
  #hit = false;
  #hitDx = 0;
  #hitDy = 0;
  #editState = EditState.None;
  #undoStack = [];
  #redoStack = [];
  #collCoord = null;
  #collisionCircleMesh;
  #collisionPathMesh;
  #pathMaterial;

  id = null;
  coordinates = [];

  constructor(projectContext) {
    this.#projectContext = projectContext;

    this.#triangleShapeGeometry = new THREE.ShapeGeometry(tiangleShape);
    this.#arrowShapeGeometry = new THREE.ShapeGeometry(arrowShape);

    const outlineMaterial = new THREE.MeshBasicMaterial({
      side: THREE.DoubleSide,
      transparent: true,
      opacity: 0.5,
    });
    const outlineGeometry = SVGLoader.pointsToStroke(
      arrowShape.getPoints(),
      outlineStrokeStyle
    );

    this.#hoveredArrowOutline = new THREE.Mesh(
      outlineGeometry.clone(),
      outlineMaterial.clone()
    );
    this.#hoveredArrowOutline.material.color = hoveredColor;
    this.#hoveredArrowOutline.scale.setScalar(ARROW_SCALE);
    this.#hoveredArrowOutline.visible = false;
    this.#projectContext.scene.add(this.#hoveredArrowOutline);

    this.#selectedArrowOutline = new THREE.Mesh(
      outlineGeometry.clone(),
      outlineMaterial.clone()
    );
    this.#selectedArrowOutline.material.color = selectedColor;
    this.#selectedArrowOutline.scale.setScalar(ARROW_SCALE);
    this.#selectedArrowOutline.visible = false;
    this.#projectContext.scene.add(this.#selectedArrowOutline);

    outlineGeometry.dispose();
    outlineMaterial.dispose();

    const circleGeometry = new THREE.CircleGeometry(0.1, 99);
    const circleMaterial = new THREE.MeshBasicMaterial({
      color: hoveredColor,
      side: THREE.DoubleSide,
      transparent: true,
      opacity: 0.5,
    });
    this.#collisionCircleMesh = new THREE.Mesh(circleGeometry, circleMaterial);
    this.#projectContext.scene.add(this.#collisionCircleMesh);

    this.#pathMaterial = new THREE.MeshBasicMaterial({
      side: THREE.DoubleSide,
      transparent: true,
      opacity: 0.1,
    });

    this.#undoPush();
  }

  setCoordinates(coordinates) {
    this.coordinates = coordinates;
    this.#update();
  }

  setData(flightPlanData) {
    this.id = flightPlanData.id;
    this.coordinates = flightPlanData.coordinates;
    this.#update();
  }

  getData() {
    return {
      id: this.id,
      coordinates: this.coordinates,
    };
  }

  deselect() {
    this.#selectedNode = null;
    this.#hoveredNode = null;
    this.#update();
  }

  clear() {
    if (this.coordinates.length) {
      this.#undoPush();
      this.coordinates = [];
      this.#update();
    }
  }

  #photoSpotsMouseHandler(event, xPos, yPos) {
    const func = `onMouse${event}`;
    for (let ps of this.#photoSpots) {
      if (ps[func](xPos, yPos)) return true;
    }
    return false;
  }

  onMouseDown(xPos, yPos) {
    if (this.#photoSpotsMouseHandler("Down", xPos, yPos)) return;

    this.#selectedNode = null;
    if (this.#hoveredNode) {
      this.#editState = EditState.MoveStart;
      const hn = this.#hoveredNode;
      this.#hitDx = hn.point.x - this.coordinates[hn.instanceId].x;
      this.#hitDy = hn.point.z - this.coordinates[hn.instanceId].y;
      this.#setPhotoSpotsVisible(false);
    } else if (!this.#hit) {
      this.#editState = EditState.DrawStart;
      this.#mouseHandler(xPos, yPos);
    }
    this.#update();
  }

  onMouseMove(xPos, yPos) {
    if (this.#photoSpotsMouseHandler("Move", xPos, yPos)) return;

    switch (this.#editState) {
      case EditState.DrawStart:
        this.#editState = EditState.Draw;
        break;
      case EditState.MoveStart:
        this.#editState = EditState.Move;
        if (this.#hoveredNode) {
          this.#movedNode = this.#hoveredNode;
          this.#hoveredNode = null;
          this.#selectedNode = null;
          this.#selectedArrowOutline.visible = false;
        }
        break;
      default:
        break;
    }
    this.#mouseHandler(xPos, yPos);
  }

  onMouseUp(xPos, yPos) {
    if (this.#photoSpotsMouseHandler("Up", xPos, yPos)) return;

    switch (this.#editState) {
      case EditState.MoveStart:
        if (this.#hoveredNode) {
          this.#selectedNode = this.#hoveredNode;
          this.#selectedArrowOutline.visible = true;
        }
        this.#projectContext.renderer.domElement.dispatchEvent(
          new CustomEvent("marker-event", {
            detail: {
              eventType: MARKER_EVENTS.SELECTED,
              item: this,
              itemId: this.id,
              itemType: MARKER_TYPES.FLIGHT_PATH,
            },
          })
        );
        break;
      case EditState.Move:
        this.#setPhotoSpotsVisible(true);
        this.#undoPush();
        break;
      case EditState.DrawStart:
      case EditState.Draw:
        this.#editState = EditState.DrawEnd;
        this.#mouseHandler(xPos, yPos);
        this.#undoPush();
        this.#projectContext.renderer.domElement.dispatchEvent(
          new CustomEvent("marker-event", {
            detail: {
              eventType: MARKER_EVENTS.CREATED,
              item: this,
              itemId: this.id,
              itemType: MARKER_TYPES.FLIGHT_PATH,
              coordinates: this.coordinates,
            },
          })
        );
        break;
      default:
        break;
    }

    this.#editState = EditState.None;
    this.#hoveredNode = null;
    this.#movedNode = null;
    this.#update();
  }

  hitTest(xPos, yPos) {
    const rect = this.#projectContext.renderer.domElement.getBoundingClientRect();
    const x = ((xPos - rect.left) / rect.width) * 2 - 1;
    const y = -((yPos - rect.top) / rect.height) * 2 + 1;
    this.#projectContext.rayCaster.setFromCamera({ x, y }, this.#projectContext.camera);

    this.#hit = false;
    if (this.#arrowInstancedMesh) {
      const intersections = this.#projectContext.rayCaster.intersectObject(this.#arrowInstancedMesh);
      if (intersections.length) {
        const prevHoveredId = this.#hoveredNode
          ? this.#hoveredNode.instanceId
          : -1;
        this.#hoveredNode = intersections[0];
        this.#hit = true;
        this.#hoveredArrowOutline.visible = this.#editState !== EditState.Move;
        if (prevHoveredId !== this.#hoveredNode.instanceId) this.#update();
      } else {
        this.#hoveredArrowOutline.visible = false;
        if (this.#hoveredNode) {
          this.#hoveredNode = null;
          this.#update();
        }
      }
    }

    if (!this.#hit && this.#photoSpots) {
      for (let ps of this.#photoSpots) {
        this.#hit = ps.hitTest(xPos, yPos);
        if (this.#hit) break;
      }
    }

    return this.#hit;
  }

  #findCollision(nodeId, x, y) {
    const c = this.coordinates;
    if (c.length > 1) {
      const p1 =
        nodeId > 0
          ? new THREE.Vector3(c[nodeId - 1].x, 0.0, c[nodeId - 1].y)
          : null;
      const p2 = new THREE.Vector3(x, 0.0, y);
      const p3 =
        c.length - 1 > nodeId
          ? new THREE.Vector3(c[nodeId + 1].x, 0.0, c[nodeId + 1].y)
          : null;
      const i = this.#projectContext.buildingPlan.findWallIntersection(
        p1,
        p2,
        p3
      );
      if (i && i.intersects) this.#collCoord = { x: i.i2.x, y: i.i2.y };
      else this.#collCoord = null;
    }

    return this.#collCoord != null;
  }

  #mouseHandler(xPos, yPos) {
    this.hitTest(xPos, yPos);

    if (this.#editState !== EditState.None) {
      const intersections = this.#projectContext.rayCaster.intersectObject(
        this.#projectContext.drawGrid
      );
      if (intersections && intersections.length) {
        const p = intersections[0].point;
        const x = p.x - this.#hitDx;
        const y = p.z - this.#hitDy;
        const MIN_DIST = 0.3;
        const dist = this.#projectContext.buildingPlan.distToWall(
          x,
          y,
          MIN_DIST
        );
        if (dist > MIN_DIST) {
          if (this.#editState === EditState.Move) {
            if (this.#movedNode) {
              const nodeId = this.#movedNode.instanceId;
              if (!this.#findCollision(nodeId, x, y)) {
                this.coordinates[nodeId].x = x;
                this.coordinates[nodeId].y = y;
              }
            }
          } else {
            let prevCoord = null;
            if (
              this.#editState === EditState.Draw &&
              this.coordinates.length > 1
            ) {
              prevCoord = this.coordinates.pop();
            }
            if (this.#editState !== EditState.DrawEnd) {
              this.coordinates.push({ x: p.x, y: p.z });
              if (
                this.coordinates.length > 1 &&
                (prevCoord || this.#editState === EditState.DrawStart)
              ) {
                const collision = this.#findCollision(
                  this.coordinates.length - 1,
                  p.x,
                  p.z
                );
                if (collision) {
                  this.coordinates.pop();
                  if (prevCoord) this.coordinates.push(prevCoord);
                }
              }
            }
          }
        }
        this.#update();
      }
    }
  }

  dispose() {
    this.#cleanup();
    if (this.#triangleShapeGeometry) {
      this.#triangleShapeGeometry.dispose();
      this.#triangleShapeGeometry = null;
    }
    if (this.#arrowShapeGeometry) {
      this.#arrowShapeGeometry.dispose();
      this.#arrowShapeGeometry = null;
    }
    if (this.#pathMaterial) {
      this.#pathMaterial.dispose();
      this.#pathMaterial = null;
    }
    if (this.#collisionCircleMesh) {
      this.#projectContext.scene.remove(this.#collisionCircleMesh);
      this.#collisionCircleMesh.material.dispose();
      this.#collisionCircleMesh.geometry.dispose();
      this.#collisionCircleMesh = null;
    }
    if (this.#hoveredArrowOutline) {
      this.#projectContext.scene.remove(this.#hoveredArrowOutline);
      this.#hoveredArrowOutline.material.dispose();
      this.#hoveredArrowOutline.geometry.dispose();
      this.#hoveredArrowOutline = null;
    }
    if (this.#selectedArrowOutline) {
      this.#projectContext.scene.remove(this.#selectedArrowOutline);
      this.#selectedArrowOutline.material.dispose();
      this.#selectedArrowOutline.geometry.dispose();
      this.#selectedArrowOutline = null;
    }
  }

  #cleanup() {
    if (this.#group) {
      this.#group.traverse((obj3d) => {
        if (obj3d.material) obj3d.material.dispose();
        if (obj3d.geometry) obj3d.geometry.dispose();
      });
      this.#projectContext.scene.remove(this.#group);
      this.#group = null;
    }
    this.#collisionPathMesh = null;
    this.#trianglesInstancedMesh = null;
    this.#arrowInstancedMesh = null;
  }

  #update() {
    this.#cleanup();

    this.#group = new THREE.Group();
    this.#projectContext.scene.add(this.#group);

    if (this.coordinates.length > 0) {
      const flightPlanLength = this.coordinates.length;
      if (flightPlanLength === 1) {
        // only start point exists, add second fake point to make this coordinates visible
        const p = this.coordinates[0];
        this.coordinates.push({ x: p.x, y: p.y + 0.01 });
      }

      const shape = new THREE.Shape(this.coordinates);
      const lengths = shape.getCurveLengths();
      const spacedPoints = shape.getSpacedPoints(
        lengths[lengths.length - 1] * 8
      );
      const obj3d = new THREE.Object3D();

      this.#trianglesInstancedMesh = this.#addInstancedMesh(
        this.#triangleShapeGeometry.clone(),
        spacedPoints.length - 1,
        smallArrowColor
      );
      for (let n = 1; n < spacedPoints.length; ++n) {
        const p1 = spacedPoints[n];
        const p2 = spacedPoints[n - 1];
        const r = Math.atan2(p1.y - p2.y, p1.x - p2.x) + ROT90;

        obj3d.position.set(p1.x, PARAMS.FLIGHT_PLAN_Z_POS, p1.y);
        obj3d.scale.set(0.02, 0.02, 0.02);
        obj3d.rotation.set(ROT90, 0.0, r);
        obj3d.updateMatrix();
        this.#trianglesInstancedMesh.setMatrixAt(n - 1, obj3d.matrix);
      }
      this.#trianglesInstancedMesh.instanceMatrix.needsUpdate = true;

      this.#arrowInstancedMesh = this.#addInstancedMesh(
        this.#arrowShapeGeometry.clone(),
        this.coordinates.length,
        arrowColor
      );
      for (let n = 0; n < this.coordinates.length; ++n) {
        let r = 0,
          p1,
          p2;
        if (n > 0) {
          p1 = this.coordinates[n];
          p2 = this.coordinates[n - 1];
          r = Math.atan2(p1.y - p2.y, p1.x - p2.x) + ROT90;
        } else {
          p1 = this.coordinates[1];
          p2 = this.coordinates[0];
          r = Math.atan2(p1.y - p2.y, p1.x - p2.x) + ROT90;
          p1 = p2;
        }

        const x = p1.x;
        const y = PARAMS.FLIGHT_PLAN_Z_POS + PARAMS.Z_POS_DELTA;
        const z = p1.y;
        obj3d.position.set(x, y, z);
        obj3d.rotation.set(ROT90, 0.0, r);
        obj3d.scale.setScalar(ARROW_SCALE);
        obj3d.updateMatrix();
        this.#arrowInstancedMesh.setMatrixAt(n, obj3d.matrix);

        if (this.#hoveredNode && n === this.#hoveredNode.instanceId) {
          const o = this.#hoveredArrowOutline;
          o.position.set(x, y - PARAMS.Z_POS_DELTA, z);
          o.rotation.set(ROT90, 0.0, r);
          o.updateMatrix();
        }

        if (this.#selectedNode && n === this.#selectedNode.instanceId) {
          const o = this.#selectedArrowOutline;
          o.position.set(x, y - PARAMS.Z_POS_DELTA, z);
          o.rotation.set(ROT90, 0.0, r);
          o.updateMatrix();
        }
      }
      this.#arrowInstancedMesh.instanceMatrix.needsUpdate = true;

      if (flightPlanLength === 1) {
        // remove fake second point
        this.coordinates.pop();
      }

      if (this.#collCoord) {
        const y = PARAMS.FLIGHT_PLAN_Z_POS + PARAMS.Z_POS_DELTA;
        this.#collisionCircleMesh.rotation.set(ROT90, 0.0, 0.0);
        this.#collisionCircleMesh.position.set(
          this.#collCoord.x,
          y,
          this.#collCoord.y
        );
        this.#collisionCircleMesh.updateMatrix();
        this.#collisionCircleMesh.visible = true;
      } else {
        this.#collisionCircleMesh.visible = false;
      }

      if (this.#editState !== EditState.None) {
        const collisionPathGeometry =
          this.#projectContext.buildingPlan.calcCollisionPathGeometry(
            this.coordinates,
            true
          );
        if (collisionPathGeometry) {
          const points = collisionPathGeometry.attributes.position.array;
          points.forEach((_, n) => {
            if ((n + 1) % 3 === 0)
              points[n] = -(PARAMS.FLIGHT_PLAN_Z_POS + PARAMS.Z_POS_DELTA);
          });
          this.#collisionPathMesh = new THREE.Mesh(collisionPathGeometry, this.#pathMaterial.clone());
          this.#collisionPathMesh.material.color = hoveredColor;
          this.#collisionPathMesh.rotation.set(ROT90, 0.0, 0.0);
          this.#group.add(this.#collisionPathMesh);
        }
      }

      this.#updatePhotoSpots();
    }
  }

    #addInstancedMesh(geometry, count, color) {
        const material = new THREE.MeshBasicMaterial({ color: color, side: THREE.DoubleSide });
        const mesh = new THREE.InstancedMesh(geometry, material, count);
        this.#group.add(mesh);
        return mesh;
    }

  #setPhotoSpotsVisible(visible) {
    for (let ps of this.#photoSpots) ps.visible = visible;
  }

  #updatePhotoSpots() {
    if (this.#editState === EditState.None) {
      const photoSpots = [];
      const photoSpotsGroup = new THREE.Group();
      for (let c of this.coordinates) {
        if (c.photoSpot) {
          const ps = new PhotoSpot(this.#projectContext, photoSpotsGroup, c);
          photoSpots.push(ps);
        }
      }
      this.#group.add(photoSpotsGroup);

      if (this.#photoSpots)
        for (let ps of this.#photoSpots) ps.dispose();
      this.#photoSpots = photoSpots;
    }
  }

  addPhotoSpot() {
    if (this.#selectedNode) {
      const n = this.#selectedNode.instanceId;
      if (this.coordinates.length > n && !this.coordinates[n].photoSpot) {
        this.coordinates[n].photoSpot = { angle: 0.0 };
        this.#undoPush();
        this.#update();
      }
    } else {
      console.warn("Adding photo spot failed! No node selected.");
    }
  }

  removePhotoSpot() {
    if (this.#selectedNode) {
      const n = this.#selectedNode.instanceId;
      if (this.coordinates.length > n && this.coordinates[n].photoSpot) {
        delete this.coordinates[n].photoSpot;
        this.#undoPush();
        this.#update();
      }
    } else {
      console.warn("Removing photo spot failed! No node selected.");
    }
  }

  deleteSelectedNode() {
    if (this.#selectedNode) {
      const n = this.#selectedNode.instanceId;
      if (this.coordinates.length > n) {
        this.coordinates.splice(n, 1);
        this.#undoPush();
      }
      this.#selectedNode = null;
      this.#selectedArrowOutline.visible = false;
      this.#update();
    }
  }

  save() {
    //TEMP to be removed
    if (this.coordinates.length > 0) {
      this.#savePythonFlightPlan(this.coordinates, "coordinates.py");
      this.#saveJsonFlightPlan(this.coordinates, "coordinates.json");

      const shape = new THREE.Shape(this.coordinates);
      const lengths = shape.getCurveLengths();
      const spacedPoints = shape.getSpacedPoints(
        lengths[lengths.length - 1] * 4
      );
      this.#savePythonFlightPlan(spacedPoints, "slowFlightPlan.py");
    }
  }

  #saveJsonFlightPlan(coordinates, fileName) {
    //TEMP to be removed
    saveBlob(JSON.stringify(coordinates), fileName);
  }

  #savePythonFlightPlan(coordinates, fileName) {
    //TEMP to be removed
    let data = "coordinates = [\n";
    for (let i = 0; i < coordinates.length; i++) {
      data += `\t(${-coordinates[i].x}, ${coordinates[i].y}),\n`;
    }
    data += "]\n";
    saveBlob(data, fileName);
  }

  load() {
    //TEMP to be removed
    loadLocalJson((coordinates) => {
      this.setCoordinates(coordinates);
    });
  }

  #compareFlightPlans(a, b) {
    if (a.length !== b.length) {
      return false;
    }
    for (let i = 0; i < a.length; i++) {
      if (a[i].x !== b[i].x || a[i].y !== b[i].y) {
        return false;
      }
    }
    return true;
  }

  #undoPush() {
    this.#undoStack.push(structuredClone(this.coordinates));
  }

  #redoPush() {
    this.#redoStack.push(structuredClone(this.coordinates));
  }

  undo() {
    if (this.#undoStack.length) {
      if (this.#compareFlightPlans(this.#undoStack.at(-1), this.coordinates)) {
        this.#undoStack.pop();
        this.#redoPush();
      }
      if (this.#undoStack.length) {
        this.coordinates = this.#undoStack.pop();
        this.#redoPush();
      }
      this.#update();
    }
  }

  redo() {
    if (this.#redoStack.length) {
      if (this.#compareFlightPlans(this.#redoStack.at(-1), this.coordinates)) {
        this.#redoStack.pop();
        this.#undoPush();
      }
      if (this.#redoStack.length) {
        this.coordinates = this.#redoStack.pop();
        this.#undoPush();
      }
      this.#update();
    }
  }
}
