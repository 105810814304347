import React from "react";
import { Button, Form, Modal, ModalBody, ModalFooter } from "reactstrap";

interface Props {
  title?: string;
  text: string;
  onConfirm: () => void;
  onCancel: () => void;
}

const ConfirmationModal: React.FC<Props> = ({
  title = "Confirm",
  text,
  onConfirm,
  onCancel,
}) => {
  return (
    <Modal isOpen={true} toggle={onCancel} modalClassName="modal-black">
      <div className="modal-header">
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={onCancel}
        >
          <i className="tim-icons icon-simple-remove"></i>
        </button>
        <h5 className="modal-title">{title}</h5>
      </div>
      <ModalBody>
        <Form action="" className="form" method="">
          <div className="card-content">{text}</div>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button color="danger" onClick={onConfirm}>
          Confirm
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmationModal;
