import * as THREE from "three";
import { SVGLoader } from "three/addons/loaders/SVGLoader.js";
import * as PARAMS from "../params.js";

const ROT90 = THREE.MathUtils.degToRad(90);
const FOV_RAY_LENGTH = 15;

const hoveredColor = new THREE.Color(0xff0000);
const selectedColor = new THREE.Color(0x0000ff);

const outlineStrokeStyle = {
  strokeWidth: 20,
};

const DEFAULT_CAMERA_VIEW_ANGLE = 120;

const EditState = Object.freeze({
  None: Symbol("None"),
  RotateStart: Symbol("RotateStart"),
  Rotate: Symbol("Rotate"),
  RotateEnd: Symbol("RotateEnd")
});

export class PhotoSpot {
  #projectContext;
  #sceneGroup;
  #coord = {
    x: 0.0,
    y: 0.0,
    angle: 0.0
  };
  #lineHit = false;
  #line1;
  #line2;
  #hovered;
  #startX = 0.0;
  #startAngle = 0.0;
  #cameraIconMesh;
  #cameraIconMeshOutline;
  #iconMaterial;
  #iconOutlineMaterial;
  #fovMaterial;
  #cameraViewAngle;
  #fovLinesGroup;
  #isSelected = false;
  #editState = EditState.None;


  constructor(projectContext, sceneGroup, coord, cameraViewAngle=DEFAULT_CAMERA_VIEW_ANGLE) {
    this.#projectContext = projectContext;
    this.#sceneGroup = sceneGroup;
    this.#coord = coord;
    this.#cameraViewAngle = cameraViewAngle;

    this.#fovMaterial = new THREE.LineBasicMaterial({
      color: "skyblue",
      transparent: true,
      opacity: 0.5
    });

    const loader = new SVGLoader();
    loader.load(`${PARAMS.PATHS.IMAGES}/camera.svg`, (data) => {
      this.#iconMaterial = new THREE.MeshBasicMaterial({
        color: "black",
        side: THREE.DoubleSide
      });
      this.#iconOutlineMaterial = new THREE.MeshBasicMaterial({
        side: THREE.DoubleSide,
        transparent: true,
        opacity: 0.5,
      });
      this.#cameraIconMesh = new THREE.Group();
      this.#cameraIconMeshOutline = new THREE.Group();
      for (let path of data.paths) {
        const shapes = SVGLoader.createShapes(path);
        let mesh;
        for (let shape of shapes) {
          let geometry = new THREE.ShapeGeometry(shape);
          geometry.computeBoundingBox();
          mesh = new THREE.Mesh(geometry, this.#iconMaterial.clone());
          this.#cameraIconMesh.add(mesh);
          geometry = SVGLoader.pointsToStroke(shape.getPoints(), outlineStrokeStyle);
          mesh = new THREE.Mesh(geometry, this.#iconOutlineMaterial.clone());
          this.#cameraIconMeshOutline.add(mesh);
        }
      }
      this.#sceneGroup.add(this.#cameraIconMesh);
      this.#sceneGroup.add(this.#cameraIconMeshOutline);
      this.#cameraIconMeshOutline.visible = false;
      this.#update();
    });
  }

  setPos(x, y) {
    this.#coord.x = x;
    this.#coord.y = y;
    this.#update();
  }

  setAngle(angle) {
    this.#coord.photoSpot.angle = angle;
    this.#update();
  }

  setCameraViewAngle(angle) {
    this.#cameraViewAngle = angle;
    this.#update();
  }

  onMouseDown(xPos, yPos) {
    if (this.#hovered) {
      this.#editState = EditState.RotateStart;
      this.#startX = this.#hovered.point.x;
      this.#startAngle = this.#coord.photoSpot.angle;
      this.#update();
      return true;
    }
    return false;
  }

  onMouseMove(xPos, yPos) {
    let handled = false;
    switch (this.#editState) {
      case EditState.RotateStart:
        this.#editState = EditState.Rotate;
        this.#hovered = null;
        handled = true;
        break;
      case EditState.Rotate:
        handled = true;
        break;
      default:
        break;
    }
    if (handled) this.#mouseHandler(xPos, yPos);
    return handled;
  }

  onMouseUp(xPos, yPos) {
    this.hitTest(xPos, yPos);

    let handled = false;
    switch (this.#editState) {
      case EditState.Rotate:
        this.#editState = EditState.RotateEnd;
        this.#mouseHandler(xPos, yPos);
        this.#editState = EditState.None;
        this.#hovered = null;
        this.#update();
        handled = true;
        break;
      default:
        break;
    }
    return handled;
  }

  #mouseHandler(xPos, yPos) {
    this.hitTest(xPos, yPos);

    if (this.#editState !== EditState.None) {
      const intersections = this.#projectContext.rayCaster.intersectObject(this.#projectContext.drawGrid);
      if (intersections.length) {
        if (this.#editState === EditState.Rotate) {
          const p = intersections[0].point;
          const angle = (this.#startX - p.x) * -120;
          this.setAngle(this.#startAngle + angle);
        } else if (this.#editState !== EditState.RotateEnd) {
          //TODO dispatch event camera-rotation-changed
        }
      }
    }
  }

  hitTest(xPos, yPos) {
    if (!this.#cameraIconMesh) return;

    const rayCaster = this.#projectContext.rayCaster;
    const rect = this.#projectContext.renderer.domElement.getBoundingClientRect();
    const x = ((xPos - rect.left) / rect.width) * 2 - 1;
    const y = -((yPos - rect.top) / rect.height) * 2 + 1;
    rayCaster.setFromCamera({ x, y }, this.#projectContext.camera);

    this.#hovered = null;
    let update = false;
    let hit = false;
    let intersections = rayCaster.intersectObject(this.#cameraIconMesh);
    if (intersections.length) {
      this.#hovered = this.#cameraIconMesh;
      this.#hovered.point = intersections[0].point;
      hit = true;
      update = true;
    } else {
      if (this.#cameraIconMeshOutline.visible === true) update = true;
      const prevLineHit = this.#lineHit;
      if (this.#line1) {
        intersections = rayCaster.intersectObject(this.#line1);
        hit = intersections.length === 1;
        this.#lineHit = hit;
        if (hit) {
          this.#hovered = this.#line1;
          this.#hovered.point = intersections[0].point;
        }
      }
      if (!hit && this.#line2) {
        intersections = rayCaster.intersectObject(this.#line2);
        hit = intersections.length === 1;
        this.#lineHit = hit;
        if (hit) {
          this.#hovered = this.#line2;
          this.#hovered.point = intersections[0].point;
        }
      }
      update = update || prevLineHit !== this.#lineHit;
    }

    if (update) this.#update();

    return hit;
  }

  destroy() {
    this.#cleanup();
  }

  dispose() {
    this.#cleanup();
    if (this.#cameraIconMesh) {
      this.#cameraIconMesh.traverse(obj3d => {
        if (obj3d.material) obj3d.material.dispose();
        if (obj3d.geometry) obj3d.geometry.dispose();
      });
      this.#sceneGroup.remove(this.#cameraIconMesh);
      this.#cameraIconMesh = null;
    }
    if (this.#cameraIconMeshOutline) {
      this.#cameraIconMeshOutline.traverse(obj3d => {
        if (obj3d.material) obj3d.material.dispose();
        if (obj3d.geometry) obj3d.geometry.dispose();
      });
      this.#sceneGroup.remove(this.#cameraIconMeshOutline);
      this.#cameraIconMeshOutline = null;
    }
    if (this.#iconMaterial) {
      this.#iconMaterial.dispose();
      this.#iconMaterial = null;
    };
    if (this.#iconOutlineMaterial) {
      this.#iconOutlineMaterial.dispose();
      this.#iconOutlineMaterial = null;
    };
    if (this.#fovMaterial) {
      this.#fovMaterial.dispose();
      this.#fovMaterial = null;
    };
  }

  #cleanup() {
    if (this.#line1) {
      this.#sceneGroup.remove(this.#line1);
      this.#line1.material.dispose();
      this.#line1.geometry.dispose();
      this.#line1 = null;
    }
    if (this.#line2) {
      this.#sceneGroup.remove(this.#line2);
      this.#line2.material.dispose();
      this.#line2.geometry.dispose();
      this.#line2 = null;
    }
    if (this.#fovLinesGroup) {
      this.#sceneGroup.remove(this.#fovLinesGroup);
      this.#fovLinesGroup.traverse(l => {
        if (l.geometry) l.geometry.dispose();
      });
      this.#fovLinesGroup = null;
    }
  }

  #update() {
    this.#cleanup();

    const y = PARAMS.FLIGHT_PLAN_Z_POS + PARAMS.Z_POS_DELTA * 3;
    const p1 = new THREE.Vector3(this.#coord.x, y, this.#coord.y);
    const p2 = new THREE.Vector3(this.#coord.x, y, this.#coord.y - 1);
    const A1 = THREE.MathUtils.degToRad(this.#cameraViewAngle / 2 + this.#coord.photoSpot.angle);
    const A2 = THREE.MathUtils.degToRad(this.#cameraViewAngle / 2 - this.#coord.photoSpot.angle);
    const rY1 = new THREE.Matrix4().makeRotationY(A1);
    const rY2 = new THREE.Matrix4().makeRotationY(-A2);
    const t1 = new THREE.Matrix4().makeTranslation(-p1.x, 0.0, -p1.z);
    const t2 = new THREE.Matrix4().makeTranslation(p1.x, 0.0, p1.z);
    const m1 = new THREE.Matrix4().multiply(t2.clone().multiply(rY1.multiply(t1)));
    const m2 = new THREE.Matrix4().multiply(t2.clone().multiply(rY2.multiply(t1)));

    const lineHit = this.#lineHit && this.#editState === EditState.None;
    const rangeMaterial = new THREE.LineDashedMaterial({
      color: lineHit ? hoveredColor : (this.#isSelected ? selectedColor : PARAMS.MEASURE_ARROW_COLOR),
      dashSize: 0.05,
      gapSize: 0.05
    });

    const geometry1 = new THREE.BufferGeometry().setFromPoints([p1, p2.clone().applyMatrix4(m1)]);
    this.#line1 = new THREE.LineSegments(geometry1, rangeMaterial.clone());
    this.#line1.computeLineDistances();
    this.#sceneGroup.add(this.#line1);

    const geometry2 = new THREE.BufferGeometry().setFromPoints([p1, p2.clone().applyMatrix4(m2)]);
    this.#line2 = new THREE.LineSegments(geometry2, rangeMaterial.clone());
    this.#line2.computeLineDistances();
    this.#sceneGroup.add(this.#line2);

    rangeMaterial.dispose();

    if (this.#editState !== EditState.None) {
      this.#fovLinesGroup = new THREE.Group();
      this.#sceneGroup.add(this.#fovLinesGroup);

      const steps = this.#cameraViewAngle * 3;
      const angleStep = this.#cameraViewAngle / (steps - 1);
      const bp = this.#projectContext.buildingPlan;
      const bpRot = new THREE.Matrix4().makeRotationY(THREE.MathUtils.degToRad(-bp.rotation)).invert();
      for (let n = 0; n < steps; ++n) {
        var angle = THREE.MathUtils.degToRad(n * angleStep);
        const r = new THREE.Matrix4().makeRotationY(A1 - angle);
        const m = new THREE.Matrix4().multiply(t2.clone().multiply(r.multiply(t1)));
        const p = new THREE.Vector3(this.#coord.x, y, this.#coord.y - FOV_RAY_LENGTH).applyMatrix4(m);

        const i = bp.findWallIntersection(p1.clone(), p, null, false);
        if (i && i.intersects) { p.x = i.i1.x; p.z = i.i1.y; }

        const g = new THREE.BufferGeometry().setFromPoints([p1, p.applyMatrix4(bpRot)]);
        this.#fovLinesGroup.add(new THREE.LineSegments(g, this.#fovMaterial));
      }
    }

    this.#updateIconPos();
  }

  #updateIconPos() {
    if (this.#cameraIconMesh) {
      this.#cameraIconMesh.scale.setScalar(1);
      const bbox = new THREE.Box3().setFromObject(this.#cameraIconMesh);
      const scale = 0.002;
      const dx = (bbox.max.x - bbox.min.x) / 2.0 * scale;
      const x = this.#coord.x - dx;
      const y = PARAMS.FLIGHT_PLAN_Z_POS + PARAMS.Z_POS_DELTA * 3;
      const z = this.#coord.y + dx * 2;
      this.#cameraIconMesh.position.set(x, y, z);
      this.#cameraIconMesh.scale.setScalar(scale);
      this.#cameraIconMesh.rotation.set(ROT90, 0.0, 0.0);
      this.#cameraIconMesh.visible = true;

      if (this.#cameraIconMeshOutline) {
        const visible = this.#hovered === this.#cameraIconMesh;
        this.#cameraIconMeshOutline.traverse(obj3d => {
          if (obj3d.material) obj3d.material.color = hoveredColor;
        });
        this.#cameraIconMeshOutline.position.set(x, y - PARAMS.Z_POS_DELTA, z);
        this.#cameraIconMeshOutline.scale.setScalar(scale);
        this.#cameraIconMeshOutline.rotation.set(ROT90, 0.0, 0.0);
        this.#cameraIconMeshOutline.visible = visible;
      }
    }
  }
}
