import * as THREE from "three";
import * as PARAMS from "../params.js";
import { isStrAt, strFromArrayBuffer } from "./utils/binary-utils.js";

export class OccGrid {
  #projectContext;
  #points;
  #pointsGeometry;
  #pointsMaterial;
  #outline = [];
  #outlineRotation = 0;
  #grid = [];
  #colorized = false;
  #visible = false;
  #init = true;

  timeSec = 0;
  timeNanosec = 0;
  rotation = 0.0;
  width = 0;
  height = 0;
  originPositionX = 0;
  originPositionY = 0;
  xOffset = 0.0;
  yOffset = 0.0;

  constructor(projectContext) {
    this.#projectContext = projectContext;
    this.#pointsMaterial = new THREE.PointsMaterial({
      vertexColors: true,
      transparent: true,
      opacity: 0.9,
      size: 3.0,
    });
  }

  load(yamlData, pointCount = -1) {
    // const isDaaveBinary = url.endsWith('.daave');
    const isDaaveBinary = false;
    // TODO recognise future type of occupancy grid. yaml or binary .daave
    // loader.setResponseType(isDaaveBinary ? 'arraybuffer' : 'string');
    // loader.loadAsync(url)
    //     .then(data => {
    //       if (isDaaveBinary) {
    //         this.#parseDaaveBinary(data, pointCount);
    //       } else {
    //         const worker = new Worker('/workers/occ-grid-worker.js');
    //         worker.addEventListener('message', e => this.#onGridLoaded(e.data));
    //         worker.postMessage({ grid: data, url: url });
    //       }
    //     })
    if (isDaaveBinary) {
      this.#parseDaaveBinary(yamlData, pointCount);
    } else if (yamlData === "") {
    } else {
      const worker = new Worker("/workers/occ-grid-worker.js");
      worker.addEventListener("message", (e) => this.#onGridLoaded(e.data));
      worker.postMessage({ grid: yamlData });
    }
  }

  #parseDaaveBinary(buffer, bufferPointsCount) {
    const dv = new DataView(buffer);

    // 0 - 7: header id string
    const DAAVE_OG = "DAAVE_OG";
    if (!isStrAt(dv, DAAVE_OG, 0)) {
      const headerId = strFromArrayBuffer(buffer, 0, DAAVE_OG.length);
      console.error(`Invalid file format. Unknown header ID: '${headerId}'.`);
      return;
    }

    // 8: version - uint8 * 1
    const ver = dv.getUint8(8);
    if (ver !== 1) {
      console.error(
        `DAAVE_OG: Invalid file version. Should be 1, but is ${ver}.`
      );
      return;
    }

    // 9 - 12: timestamp - sec - int32
    this.timeSec = dv.getInt32(9, true);

    // 13 - 16: timestamp - nanosec - uint32
    this.timeNanosec = dv.getUint32(13, true);

    // 17 - 24: size - int32 * 2
    this.width = dv.getInt32(17, true);
    this.height = dv.getInt32(21, true);

    // 25 - 40: origin - float64 * 2
    this.originPositionX = dv.getFloat64(25, true);
    this.originPositionY = dv.getFloat64(33, true);

    // 41 - 44: resolution - float32
    this.resolution = dv.getFloat32(41, true);

    // 45 - 47: data id string
    if (!isStrAt(dv, "MAP", 45)) {
      console.error(
        `Invalid file format. Expected 'MAP' marker at position 45.`
      );
      return;
    }

    // 48 - the end: occupancy grid data - array of signed bytes
    let pointsCount = bufferPointsCount;
    if (pointsCount === -1) {
      const a = new Int8Array(buffer, 48);
      pointsCount =
        a.reduce((c, v) => {
          return c + (v > 0 ? 1 : 0);
        }) + 1;
    }

    const grid = new Float64Array(pointsCount * 3);
    const r = this.resolution;
    for (let y = 0, n = 48, m = 0; y < this.height; ++y) {
      const yy = y * r + this.originPositionY;
      for (let x = 0; x < this.width; ++x) {
        const v = dv.getInt8(n++);
        if (v > 0) {
          grid[m++] = -(x * r + this.originPositionX);
          grid[m++] = yy;
          grid[m++] = 1.0 - v / 100.0;
        }
      }
    }

    this.#onGridLoaded(grid);
  }

  #onGridLoaded(grid) {
    this.#grid = grid;
    this.#init = true;
    if (this.#visible) this.#showPoints();
  }

  setOutline(outline, rot = 0.0) {
    this.#outline = outline;
    this.#outlineRotation = rot;
    if (this.#visible) this.#showPoints();
  }

  setOutlineRotation(rot) {
    this.#outlineRotation = rot;
    if (this.#visible) this.#showPoints();
  }

  setPointSize(size) {
    this.#pointsMaterial.size = size * 1.2;
  }

  set visible(visible) {
    this.#visible = visible;
    this.#visible ? this.#showPoints() : this.#hidePoints();
  }

  get visible() {
    return this.#visible;
  }

  set colorized(colorized) {
    this.#colorized = colorized;
    if (this.#visible) this.#showPoints();
  }

  get colorized() {
    return this.#colorized;
  }

  dispose() {
    this.#init = true;
    if (this.#points) {
      this.#projectContext.scene.remove(this.#points);
      this.#points = null;
    }
    if (this.#pointsGeometry) {
      this.#pointsGeometry.dispose();
      this.#pointsGeometry = null;
    }
  }

  #hidePoints() {
    this.dispose();
  }

  #showPoints() {
    if (!this.#grid) return;

    const r = new THREE.Matrix4().makeRotationY(
      THREE.MathUtils.degToRad(this.rotation)
    );
    const t = new THREE.Matrix4().makeTranslation(
      this.xOffset,
      0.0,
      this.yOffset
    );
    const m1 = t.multiply(r);
    const m2 = new THREE.Matrix4().makeRotationY(
      THREE.MathUtils.degToRad(-this.#outlineRotation)
    );

    const colors = this.#init
      ? new Float32Array(this.#grid.length)
      : this.#pointsGeometry.attributes.color.array;
    const positions = this.#init
      ? new Float32Array(this.#grid.length)
      : this.#pointsGeometry.attributes.position.array;
    const pt = new THREE.Vector3();
    for (let n = 0; n < this.#grid.length; n += 3) {
      pt.x = this.#grid[n + 0];
      pt.y = PARAMS.OCC_GRID_Z_POS;
      pt.z = this.#grid[n + 1];

      pt.applyMatrix4(m1);
      positions[n + 0] = pt.x;
      positions[n + 1] = pt.y;
      positions[n + 2] = pt.z;

      pt.applyMatrix4(m2);
      const v = this.#grid[n + 2];
      colors[n + 0] = this.#colorized ? this.#distToWall(pt) * 2 : v;
      colors[n + 1] = v;
      colors[n + 2] = v;
    }

    if (this.#init) {
      this.#init = false;

      this.#pointsGeometry = new THREE.BufferGeometry();
      this.#pointsGeometry.setAttribute(
        "position",
        new THREE.BufferAttribute(positions, 3)
      );
      this.#pointsGeometry.setAttribute(
        "color",
        new THREE.BufferAttribute(colors, 3)
      );
      this.#pointsGeometry.computeBoundingSphere();

      this.#points = new THREE.Points(
        this.#pointsGeometry,
        this.#pointsMaterial
      );
      this.#projectContext.scene.add(this.#points);
    } else {
      this.#pointsGeometry.attributes.position.needsUpdate = true;
      this.#pointsGeometry.attributes.color.needsUpdate = true;
    }
  }

  #distToWall(pt) {
    pt.y = 0.0;
    const target = new THREE.Vector3();
    let dist = 2.0;
    for (const line of this.#outline) {
      if (pt.x < line.bbox[0] || pt.x > line.bbox[1]) continue;
      if (pt.z < line.bbox[2] || pt.z > line.bbox[3]) continue;

      const d = line.closestPointToPoint(pt, true, target).distanceTo(pt);
      if (d < dist) dist = d;
    }
    return dist;
  }

  rotateZ(rotation) {
    this.rotation = rotation;
    this.#showPoints();
  }

  moveX(xOffset) {
    this.xOffset = xOffset;
    this.#showPoints();
  }

  moveZ(zOffset) {
    this.yOffset = zOffset;
    this.#showPoints();
  }

  setPos(xOffset, yOffset, rotation) {
    this.rotation = rotation;
    this.xOffset = xOffset;
    this.yOffset = yOffset;
    if (this.#visible) this.#showPoints();
  }
}
