import { withAuthenticator } from "@aws-amplify/ui-react";
import React, { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { userInfoAtom } from "../recoil/atoms/userInfo.atom";
import { securedApi } from "../api";
import { AxiosError } from "axios";
import LoaderPage from "../pages/LoaderPage/LoaderPage";
import ErrorPage from "../pages/ErrorPage/ErrorPage";

interface Props {
  children: any;
}

const ProtectedRoute: React.FC<Props> = ({ children }) => {
  const [userInfo, setUserInfo] = useRecoilState(userInfoAtom);
  const [loginError, setLoginError] = useState<string | undefined>();
  const [loading, setLoading] = useState(false);
  const magicUserId = localStorage.getItem("magicUserId");
  const { projectId } = useParams();

  useEffect(() => {
    if (magicUserId && !userInfo.userId && projectId) {
      setUserInfo((info) => ({ ...info, type: "limited" }));
      setLoginError(undefined);
      setLoading(true);

      securedApi
        .get(`/projects/${projectId}/users/${magicUserId}`)
        .then(({ data }) => {
          if (!data.userId) {
            setLoginError("No user data returned");
            return;
          }
          setUserInfo(() => ({
            type: "limited",
            userId: data.userId,
            userName: data.name,
            userEmail: data.email,
            userColor: data.userColor,
            role: data.role,
            tenants: [],
            tenantName: null,
          }));
        })
        .catch((error: AxiosError) => {
          setLoginError(error.message);
        })
        .finally(() => setLoading(false));
    }
  }, [magicUserId, userInfo.userId, projectId, setUserInfo]);

  if (loading) {
    return <LoaderPage />;
  }

  if (loginError) {
    return <ErrorPage error={loginError} />;
  }

  if (userInfo.type === "limited") {
    return children;
  }

  return AmplifyProtectedRoute({ children });
};

interface AmplifyProps {
  signOut?: any;
  user?: any;
  children: any;
}

const AmplifyProtectedRoute: React.FC<AmplifyProps> = withAuthenticator(
  ({ signOut, user, children }) => {
    const [, setUserInfo] = useRecoilState(userInfoAtom);
    const tenants = user.signInUserSession.idToken.payload["cognito:groups"];

    useEffect(() => {
      setUserInfo((userInfo) => ({
        ...userInfo,
        userId: user.attributes.sub,
        userName: user.attributes.name,
        userEmail: user.username,
        type: "privileged",
        role: "admin",
        tenants: tenants,
      }));
    }, [setUserInfo, tenants, user]);

    if (!user) {
      return <Navigate to="/" replace />;
    }

    return children;
  }
);

export default ProtectedRoute;
