import React from "react";
import { Container, Row } from "react-bootstrap";
import "./styles.scss";
import ProjectCard from "../ProjectCard/ProjectCard";
import { Project } from "../../types/project";

type ProjectsListProps = {
  projects: Project[];
};

const ProjectsList: React.FC<ProjectsListProps> = ({ projects }) => {
  return (
    <Container className="projectsListContainer">
      <Row>
        {projects.map((project) => (
          <ProjectCard project={project} key={project.id} />
        ))}
      </Row>
    </Container>
  );
};

export default ProjectsList;
