import * as THREE from 'three';

import { TimeSeeker, dispatchOnTimeChangedEvent } from './utils/time-utils.js';
import { LocalPos } from './model/local-pos.js'

const boxColor = new THREE.Color('darkgreen');

export class LocalPosTrack {
  #projectContext;
  #boxesMesh;
  #boxMaterial;
  #boxGeometry;
  #timeSeeker;
  #trackPos = 0;
  #globalMtx = new THREE.Matrix4();
  #globalRotation = new THREE.Matrix4();
  #globalTranslation = new THREE.Matrix4();
  #globalXOffset = 0.0;
  #globalYOffset = 0.0;
  #visible = true;

  track = []; // array of LocalPos items

  constructor(projectContext) {
    this.#projectContext = projectContext;

    this.#boxMaterial = new THREE.MeshBasicMaterial({ color: boxColor, side: THREE.DoubleSide });
    this.#boxGeometry = new THREE.BoxGeometry(0.02, 0.02, 0.02);
    this.#timeSeeker = new TimeSeeker(this.track, 'LocalPosTrack');
  }

  loadSet(ddeUrl, localPosSet) {
    console.log('Loading track ...')
    let l = localPosSet.length;
    document.addEventListener('local-pos-loaded', () => {
      if (--l === 0) {
        this.#dispatchLoadedEvent();
        this.#update();
      }
    });

    localPosSet.forEach(fileInfo => {
      const o = new LocalPos();
      o.load(ddeUrl + fileInfo.filename);
      this.track.push(o);
    });
    this.#timeSeeker = new TimeSeeker(this.track, 'LocalPosTrack');
    this.#timeSeeker.sort();
  }

  loadJoinedSet(data) {
    console.log('Loading joined track ...')

    let dataSize = data.byteLength;
    let byteOffset = 0;
    while (byteOffset < dataSize) {
      const o = new LocalPos();
      byteOffset += o.parseDaaveBinary(data, byteOffset);
      this.track.push(o);
    }

    this.#timeSeeker = new TimeSeeker(this.track, 'LocalPosTrack');
    this.#timeSeeker.sort();
    this.#dispatchLoadedEvent();
    this.#update();
  }

  #dispatchLoadedEvent() {
    console.log(`local pos track loaded: ${this.track.length}`);
    const event = new CustomEvent('track-loaded', { detail: { 'track': this.track } });
    document.dispatchEvent(event);
  }

  #getGlobalTrackPos(n) {
    const t = structuredClone(this.track[n]);
    const pos = t.position;
    const p = new THREE.Matrix4().makeTranslation(pos.x, pos.y, pos.z)
    const m = this.#globalMtx.clone().multiply(p);
    const v = new THREE.Vector3().applyMatrix4(m);
    t.position.x = v.x;
    t.position.y = v.y;
    t.position.z = v.z;
    return t;
  }

  getLocalPosAtTime(sec, nanosec) {
    const r = this.#timeSeeker.getIndexAt(sec, nanosec);
    if (r > -1) {
      return this.#getGlobalTrackPos(r);
    } else {
      return null;
    }
  }

  getLocalPos(pos) {
    if (pos > -1 && pos < this.track.length) {
      return this.#getGlobalTrackPos(pos);
    } else {
      return null;
    }
  }

  get pos() {
    return this.#trackPos;
  }

  setPos(pos, timeChangeNotify = true) {
    this.#trackPos = pos;

    if (timeChangeNotify)
      dispatchOnTimeChangedEvent(this, this.track[pos], this.#projectContext.renderer.domElement);
  }

  setPosByTime(sec, nanosec) {
    const i = this.#timeSeeker.getIndexAt(sec, nanosec);
    if (i > -1)
      this.setPos(i, false);
    return i;
  }

  set globalRotation(gr) {
    this.#globalRotation = new THREE.Matrix4()
      .makeRotationY(THREE.MathUtils.degToRad(gr));
    this.#calcLocalToGlobalMatrix();
    this.#update();
  }

  set globalXOffset(xOff) {
    this.#globalXOffset = xOff;
    this.#globalTranslation = new THREE.Matrix4()
      .makeTranslation(this.#globalXOffset, 0.0, this.#globalYOffset);
    this.#calcLocalToGlobalMatrix();
    this.#update();
  }

  set globalYOffset(yOff) {
    this.#globalYOffset = yOff;
    this.#globalTranslation = new THREE.Matrix4()
      .makeTranslation(this.#globalXOffset, 0.0, this.#globalYOffset);
    this.#calcLocalToGlobalMatrix();
    this.#update();
  }

  #calcLocalToGlobalMatrix() {
    const trans = this.#globalTranslation.clone();
    this.#globalMtx = new THREE.Matrix4()
      .multiply(trans.multiply(this.#globalRotation));
  }

  set visible(visible) {
    this.#visible = visible;
    this.#update();
  }

  get visible() {
    return this.#visible;
  }

  clear() {
    if (this.track.length) {
      this.track = [];
      this.#update();
    }
  }

  onMouseDown(xPos, yPos) {
  }

  onMouseMove(xPos, yPos) {
    this.#mouseHandler(xPos, yPos);
    this.#update();
  }

  onMouseUp(xPos, yPos) {
  }

  #mouseHandler(xPos, yPos) {
    const rect = this.#projectContext.renderer.domElement.getBoundingClientRect();
    const x = ((xPos - rect.left) / rect.width) * 2 - 1;
    const y = -((yPos - rect.top) / rect.height) * 2 + 1;
    this.#projectContext.rayCaster.setFromCamera({ x, y }, this.#projectContext.camera);
  }

  dispose() {
    this.#cleanup();
    if (this.#boxGeometry) {
      this.#boxGeometry.dispose();
      this.#boxGeometry = null;
    }
    if (this.#boxMaterial) {
      this.#boxMaterial.dispose();
      this.#boxMaterial = null;
    }
  }

  #cleanup() {
    if (this.#boxesMesh) {
      this.#projectContext.scene.remove(this.#boxesMesh);
      this.#boxesMesh.dispose();
      this.#boxesMesh = null;
    }
  }

  #update() {
    this.#cleanup();
    if (!this.visible) return;

    if (this.track.length > 0) {
      this.#boxesMesh = new THREE.InstancedMesh(this.#boxGeometry, this.#boxMaterial, this.track.length);
      this.#projectContext.scene.add(this.#boxesMesh);
      for (let n = 0; n < this.track.length; ++n) {
        const pos = this.track[n].position;
        const p = new THREE.Matrix4().makeTranslation(-pos.x, pos.z, pos.y);
        const m = this.#globalMtx.clone().multiply(p);
        this.#boxesMesh.setMatrixAt(n, m);
      }
      this.#boxesMesh.instanceMatrix.needsUpdate = true;
      this.#boxesMesh.geometry.computeBoundingBox();
      this.#boxesMesh.geometry.computeBoundingSphere();
    }
  }
}