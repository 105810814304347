import React, { useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";

import "./styles.scss";
import { useRecoilState } from "recoil";
import { userInfoAtom } from "../../recoil/atoms/userInfo.atom";
import { useParams } from "react-router-dom";
import { ProjectPageParams } from "../../pages/ProjectPage/ProjectPage";
import { securedApi } from "../../api";

const fileTypes = ["JSON"];

interface Props {
  setFileToUpload: (el: any) => void;
  setCreatedBuildingPlanId: (el: string) => void;
  setFileName: (el: any) => void;
  signOut?: any;
  user?: any;
}

const DragAndDropUpload: React.FC<Props> = ({
  setFileToUpload,
  setFileName,
  setCreatedBuildingPlanId,
}) => {
  const [, setFile] = useState(null);
  const { projectId } = useParams<ProjectPageParams>();

  const handleChange = (file: any) => {
    setFileToUpload(file);
    setFileName(file.name);
    setFile(file[0]);
    const reader = new FileReader();
    reader.onload = (event: any) => {
      const content = event.target.result;
      try {
        const parsedData = JSON.parse(content);
        setFileToUpload(parsedData);
        uploadBuildingPlan(parsedData);
      } catch (error) {
        console.error(error);
      }
    };
    reader.readAsText(file[0]);
  };

  const [userInfo, setUserInfo] = useRecoilState(userInfoAtom);

  useEffect(() => {
    const currentTenant = localStorage.getItem("tenantName");
    if (currentTenant !== null) {
      setUserInfo((userInfo) => ({
        ...userInfo,
        tenantName: currentTenant,
      }));
    }
  }, [setUserInfo, userInfo.userId]);

  function uploadBuildingPlan(props: any) {
    securedApi
      .post(`/projects/${projectId}/buildingPlans`, {
        projectId: projectId,
        data: props,
      })
      .then((res) => {
        setCreatedBuildingPlanId(res.data.buildingPlanId);
      });
  }

  return (
    <div className="DragAndDropUpload">
      <FileUploader
        multiple={true}
        handleChange={handleChange}
        name="file"
        types={fileTypes}
      />
    </div>
  );
};

export default DragAndDropUpload;
