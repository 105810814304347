import classnames from "classnames";
import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { userRoles } from "../../../utils/roles";
import { User } from "../../../types/user";
import schema from "./schema";
import "./styles.scss";

interface Props {
  onClose: () => void;
  onSave: (name: string, email: string, role: string, userId?: string) => void;
  selectedUser: User | undefined;
}

interface CreateNewUser {
  name: string;
  email: string;
  role: string;
}

const AddNewUserModal: React.FC<Props> = ({
  onClose,
  onSave,
  selectedUser,
}) => {
  const [newUser, setNewUser] = useState<CreateNewUser>({
    name: selectedUser ? selectedUser.name : "",
    email: selectedUser ? selectedUser.email : "",
    role: selectedUser ? selectedUser.role : userRoles[0].name,
  });

  const [validationErrors, setValidationErrors] = useState<CreateNewUser>({
    name: "",
    email: "",
    role: "",
  });
  const [initValidation, setInitValidation] = useState<boolean>(false);

  function handleSave() {
    if (newUser.email && newUser.name) {
      onSave(newUser.name, newUser.email, newUser.role, selectedUser?.userId);
    }
  }

  useEffect(() => {
    const validation = () => {
      const validationResult = schema.safeParse(newUser);
      if (!validationResult.success) {
        const formattedErrors = validationResult.error.format();
        if (formattedErrors._errors) {
          setValidationErrors({
            name: formattedErrors.name?._errors.join(", ") || "",
            email: formattedErrors.email?._errors.join(", ") || "",
            role: "",
          });
        }
      } else {
        setValidationErrors({
          name: "",
          email: "",
          role: "",
        });
      }
    };
    if (initValidation) {
      validation();
    }
  }, [initValidation, newUser]);

  return (
    <Modal isOpen={true} toggle={onClose} modalClassName="modal-black">
      <div className="modal-header">
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={onClose}
        >
          <i className="tim-icons icon-simple-remove"></i>
        </button>
        <h5 className="modal-title">
          {selectedUser ? "Edit User" : "Add New User"}
        </h5>
      </div>
      <ModalBody>
        <Form action="" className="form" method="">
          <div className="card-content">
            <div className="inputContainer">
              <InputGroup
                className={classnames("no-border form-control-lg", {
                  "input-group-focus": newUser.name,
                })}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="tim-icons icon-single-02" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="First and Last Name"
                  type="text"
                  value={newUser.name}
                  onChange={(e) =>
                    setNewUser((newUser) => ({
                      ...newUser,
                      name: e.target.value,
                    }))
                  }
                />
              </InputGroup>
              {
                <div
                  className="text-danger"
                  style={{
                    display: validationErrors.name ? "block" : "none",
                  }}
                >
                  {validationErrors.name}
                </div>
              }
            </div>
            <div className="inputContainer">
              <InputGroup
                className={classnames("no-border form-control-lg", {
                  "input-group-focus": newUser.email,
                })}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="tim-icons icon-email-85" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Email Address"
                  type="text"
                  value={newUser.email}
                  onChange={(e) =>
                    setNewUser((newUser) => ({
                      ...newUser,
                      email: e.target.value,
                    }))
                  }
                />
              </InputGroup>
              {
                <div
                  className="text-danger"
                  style={{
                    display: validationErrors.email ? "block" : "none",
                  }}
                >
                  {validationErrors.email}
                </div>
              }
            </div>
            <InputGroup
              className={classnames("no-border form-control-lg", {
                "input-group-focus": newUser.role,
              })}
            >
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="tim-icons icon-chat-33" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                type="select"
                value={newUser.role}
                placeholder="User Role"
                onChange={(e) =>
                  setNewUser((newUser) => ({
                    ...newUser,
                    role: e.target.value,
                  }))
                }
              >
                {userRoles
                  .filter((role) => role.name !== "admin")
                  .map((role) => (
                    <option value={role.name} key={role.name}>
                      {role.name}
                    </option>
                  ))}
              </Input>
            </InputGroup>
          </div>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onClose}>
          Close
        </Button>
        <Button
          color="primary"
          onClick={() => {
            setInitValidation(true);
            const validationResult = schema.safeParse(newUser);
            if (validationResult.success) {
              handleSave();
            }
          }}
        >
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddNewUserModal;
