import React, { useCallback, useState } from "react";
import { Button, Card, CardBody } from "reactstrap";
import cx from "classnames";
import { FormGroup, Input } from "reactstrap";

import getInitials from "../../utils/getInitials";
import { MarkerUpdate } from "../../utils/useMarkerComms";
import "./styles.scss";

interface Props {
  id: string;
  text: string;
  name?: string;
  color?: string;
  selected?: boolean;
  editable?: boolean;
  accepted?: boolean;
  onUpdate: (update: MarkerUpdate) => void;
  onDelete: (id: string) => void;
  toggleAccepted: (id: string) => void;
}

const Comment: React.FC<Props> = ({
  id,
  text,
  name = "?",
  color = "white",
  selected,
  editable,
  accepted,
  onUpdate,
  onDelete,
  toggleAccepted,
}) => {
  const [isEditing, setIsEditing] = useState(selected && !text && editable);
  const [value, setValue] = useState(text);

  const handleInputBlur = useCallback(() => {
    if (text !== value) {
      onUpdate({ tmpId: id, comment: value });
    }
    setIsEditing(false);
  }, [onUpdate, text, value, id]);

  const handleKeyUp = useCallback(
    (keyEvent: React.KeyboardEvent<HTMLElement>) => {
      keyEvent.stopPropagation();
    },
    []
  );

  const handleToggleAccepted = useCallback(
    (id: string, event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      document.getElementById("accept-button-" + id)?.blur();
      toggleAccepted(id);
    },
    [toggleAccepted]
  );

  const handleKeyDown = useCallback(
    (keyEvent: React.KeyboardEvent<HTMLElement>) => {
      if (keyEvent.key === "Enter") {
        handleInputBlur();
      }
      if (keyEvent.key === "Escape") {
        setValue(text);
        setIsEditing(false);
      }
      keyEvent.stopPropagation();
    },
    [handleInputBlur, setIsEditing, setValue, text]
  );

  if (isEditing) {
    return (
      <div className="input-card">
        <FormGroup className="grow-wrap">
          <Input
            value={value}
            type="textarea"
            name="text"
            autoFocus
            onChange={(evt) => setValue(evt.target.value)}
            onBlur={handleInputBlur}
            onKeyUp={handleKeyUp}
            onKeyDown={handleKeyDown}
          />
        </FormGroup>
      </div>
    );
  }

  return (
    <Card
      className="commentCard mb-2"
      onDoubleClick={() => editable && setIsEditing(true)}
    >
      <CardBody className={cx("commentBody", { selected })}>
        <div
          className="profileIcon"
          title={name}
          style={{ backgroundColor: color }}
        >
          {getInitials(name)}
        </div>
        <div
          className={cx("comment-text", { ellipsis: !selected })}
          title={text}
        >
          {text}
        </div>
        <div className="buttons">
          {(editable || accepted) && (
            <Button
              id={"accept-button-" + id}
              size="sm"
              className={cx([
                "btn-link",
                "btn-icon",
                "thick",
                { clickable: editable },
              ])}
              color={accepted ? "primary" : "secondary"}
              title={
                accepted ? "is included in report" : "not included in report"
              }
              onClick={(event) => editable && handleToggleAccepted(id, event)}
            >
              <i className="tim-icons icon-check-2" />
            </Button>
          )}
          {editable && (
            <>
              <Button
                size="sm"
                title="edit"
                className="btn-link btn-icon clickable"
                onClick={() => setIsEditing(true)}
              >
                <i className="tim-icons icon-pencil" />
              </Button>
              <Button
                size="sm"
                title="delete"
                className="btn-link btn-icon clickable"
                onClick={() => onDelete(id)}
              >
                <i className="tim-icons icon-trash-simple" />
              </Button>
            </>
          )}
        </div>
      </CardBody>
    </Card>
  );
};

export default Comment;
