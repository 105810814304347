import React from "react";
import { Button, Form, Modal, ModalBody, ModalFooter } from "reactstrap";
import { useRecoilState } from "recoil";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { selectedProjectAtom } from "../../../recoil/atoms/selectedProject.atom";
import Swal from "sweetalert2";
import { securedApi } from "../../../api";

interface Props {
  onClose: () => void;
  setErrorMessage: (val: string) => void;
  projectId: string;
}

const DeleteProjectModal: React.FC<Props> = ({
  onClose,
  setErrorMessage,
  projectId,
}) => {
  const [, setSelectedProject] = useRecoilState(selectedProjectAtom);

  const deleteProject = (projectId: string) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      customClass: "toast-custom-class",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    securedApi.delete(`/projects/${projectId}`).then(() => {
      setSelectedProject({ id: undefined });
      Toast.fire({
        icon: "success",
        title: "Project deleted!",
      });
      onClose();
    });
  };

  return (
    <Modal isOpen={true} toggle={onClose} modalClassName="modal-black">
      <div className="modal-header">
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={onClose}
        >
          <i className="tim-icons icon-simple-remove"></i>
        </button>
        <h5 className="modal-title">Delete project</h5>
      </div>
      <ModalBody>
        <Form action="" className="form" method="">
          <div className="card-content">
            Are you sure you want to delete this project?
          </div>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onClose}>
          Close
        </Button>
        <Button color="danger" onClick={() => deleteProject(projectId)}>
          <i className="tim-icons icon-trash-simple pr-2 font-weight-bold" />
          Delete
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default withAuthenticator(DeleteProjectModal);
