import * as THREE from 'three';
import { TimeSeeker, dispatchOnTimeChangedEvent } from './utils/time-utils.js';
import { Odometry } from './model/odometry.js';

const boxColor = new THREE.Color('midnightblue');

export class OdometrySet {
    #projectContext;
    #boxes;
    #boxGeometry;
    #boxMaterial;
    #odomsPos = 0;
    #timeSeeker;
    #globalMtx = new THREE.Matrix4();
    #globalRotation = new THREE.Matrix4();
    #globalTranslation = new THREE.Matrix4();
    #globalXOffset = 0.0;
    #globalYOffset = 0.0;
    #visible = true;

    odoms = []; // array of Odometry items

    constructor(projectContext) {
        this.#projectContext = projectContext;
        this.#timeSeeker = new TimeSeeker(this.odoms, 'OdometrySet');

        this.#boxGeometry = new THREE.BoxGeometry(0.02, 0.02, 0.02);
        this.#boxMaterial = new THREE.MeshBasicMaterial({ color: boxColor, side: THREE.DoubleSide });
    }

    loadJoinedSet(data) {
        console.log('Loading joined odometry ...')

        let byteOffset = 0;
        while (byteOffset < data.byteLength) {
            const o = new Odometry();
            byteOffset += o.parseDaaveBinary(data, byteOffset);
            this.odoms.push(o);
        }

        this.#timeSeeker.sort();
        this.#dispatchLoadedEvent();
        this.#update();
    }

    #dispatchLoadedEvent() {
        console.log(`odometry loaded: ${this.odoms.length}`);
        const event = new CustomEvent('odometry-loaded', { detail: { 'odoms': this.odoms }});
        document.dispatchEvent(event);
    }

    getIndexAt(sec, nanosec) {
        const r = this.#timeSeeker.getIndexAt(sec, nanosec);
        return r > -1 ? this.odoms[r] : null;
    }

    get pos() {
        return this.#odomsPos;
    }

    setPos(pos, timeChangeNotify=true) {
        this.#odomsPos = pos;

        if (timeChangeNotify)
            dispatchOnTimeChangedEvent(this, this.odoms[pos], this.#projectContext.renderer.domElement);
    }

    setPosByTime(sec, nanosec) {
        const i = this.#timeSeeker.getIndexAt(sec, nanosec);
        if (i > -1)
            this.setPos(i, false);
        return i;
    }

    set globalRotation(gr) {
        this.#globalRotation = new THREE.Matrix4()
            .makeRotationY(THREE.MathUtils.degToRad(gr));
        this.#calcLocalToGlobalMatrix();
        this.#update();
    }

    set globalXOffset(xOff) {
        this.#globalXOffset = xOff;
        this.#globalTranslation = new THREE.Matrix4()
            .makeTranslation(this.#globalXOffset, 0.0, this.#globalYOffset);
        this.#calcLocalToGlobalMatrix();
        this.#update();
    }

    set globalYOffset(yOff) {
        this.#globalYOffset = yOff;
        this.#globalTranslation = new THREE.Matrix4()
            .makeTranslation(this.#globalXOffset, 0.0, this.#globalYOffset);
        this.#calcLocalToGlobalMatrix();
        this.#update();
    }

    set visible(visible) {
        this.#visible = visible;
        this.#update();
    }

    get visible() {
        return this.#visible;
    }

    #calcLocalToGlobalMatrix() {
        const trans = this.#globalTranslation.clone();
        this.#globalMtx = new THREE.Matrix4()
            .multiply(trans.multiply(this.#globalRotation));
    }

    onMouseDown(xPos, yPos) {
    }

    onMouseMove(xPos, yPos) {
        this.#mouseHandler(xPos, yPos);
        this.#update();
    }

    onMouseUp(xPos, yPos) {
    }

    #mouseHandler(xPos, yPos) {
        const rect = this.#projectContext.renderer.domElement.getBoundingClientRect();
        const x = ((xPos - rect.left) / rect.width) * 2 - 1;
        const y = -((yPos - rect.top) / rect.height) * 2 + 1;
        this.#projectContext.rayCaster.setFromCamera({ x, y }, this.#projectContext.camera);

        const intersections = this.#projectContext.rayCaster.intersectObject(this.#projectContext.drawGrid);
        if (intersections && intersections.length) {
            // const p = intersections[0].point;
            //TODO const nodeId = this.#hoveredNode.instanceId;
            //TODO this.track[nodeId] = { x: p.x, y: p.z };
        }
    }

    dispose() {
        this.#boxGeometry.dispose();
        this.#boxMaterial.dispose();
        this.#cleanup();
    }

    #cleanup() {
        if (this.#boxes) {
            this.#projectContext.scene.remove(this.#boxes);
            this.#boxes.dispose();
            this.#boxes = null;
        }
    }

    #update() {
        this.#cleanup();
        if (!this.#visible) return;

        if (this.odoms.length > 0) {
            this.#boxes = new THREE.InstancedMesh(this.#boxGeometry, this.#boxMaterial, this.odoms.length);
            this.#projectContext.scene.add(this.#boxes);
            for (let n = 0; n < this.odoms.length; ++n) {
                const pos = this.odoms[n].position;
                const p = new THREE.Matrix4().makeTranslation(-pos.x, pos.z, pos.y);
                const m = this.#globalMtx.clone().multiply(p);
                this.#boxes.setMatrixAt(n, m);
            }
            this.#boxes.instanceMatrix.needsUpdate = true;
            this.#boxes.geometry.computeBoundingBox();
            this.#boxes.geometry.computeBoundingSphere();
        }
    }
}