import { atom } from "recoil";

export interface flightScreenInterface {
  droneSelected: boolean;
  startVideo: boolean;
  isDroneLoading: undefined | boolean;
  visibleStream: boolean;
}

export const flightScreenAtom = atom<flightScreenInterface>({
  key: "flightScreen",
  default: {
    droneSelected: false,
    startVideo: false,
    isDroneLoading: undefined,
    visibleStream: false,
  } as flightScreenInterface,
});
