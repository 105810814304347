import { atom } from "recoil";

interface SelectedProjectInterface {
  id: undefined | string;
}

export const selectedProjectAtom = atom<SelectedProjectInterface>({
  key: "selectedProject",
  default: {
    id: undefined,
  },
});
