import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { securedApi } from "../../api";
import "./styles.scss";
import { AxiosError } from "axios";
import { userInfoAtom } from "../../recoil/atoms/userInfo.atom";
import { useRecoilState } from "recoil";

const MagicLinkVerification: React.FC = () => {
  const { projectId, userId, magicLinkId } = useParams();
  const [error, setError] = useState<AxiosError>();
  const [token, setToken] = useState();

  const navigate = useNavigate();
  const [, setUserInfo] = useRecoilState(userInfoAtom);

  useEffect(() => {
    if (projectId && magicLinkId && userId) {
      securedApi
        .get(`/links/${magicLinkId}/login`)
        .then((res) => {
          setToken(res.data.idToken);
          localStorage.setItem("magicLinkToken", res.data.idToken);
          localStorage.setItem("magicLinkId", magicLinkId);
          localStorage.setItem("magicUserId", userId);

          setTimeout(() => navigate(`/projects/${projectId}`), 300);
        })
        .catch((error) => {
          setError(error);
          localStorage.setItem("magicLinkToken", "");
        });
    }
  }, [projectId, magicLinkId, userId, navigate, setUserInfo]);

  return (
    <div className="magic-link-verification">
      <h2>MAGIC LINK VERIFICATION</h2>
      <h3>Your magic link ID: {magicLinkId}</h3>
      {token && (
        <>
          <h1>Success!</h1>
        </>
      )}

      {error && (
        <>
          <h1>Error:</h1>
          <h4>{error.message}</h4>
        </>
      )}

      {!token && !error && <h3>...</h3>}
    </div>
  );
};

export default MagicLinkVerification;
