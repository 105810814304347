import { object, string } from "zod";

const schema = object({
  name: string({ required_error: "Name is required." })
    .min(3, "Name is too short.")
    .max(50, "Name is too long."),
  description: string().max(50, "Description is too long."),
});

export default schema;
