import React, { useEffect, useState } from "react";
import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";
import { useParams } from "react-router-dom";

import { securedApi } from "../../../api";
import { User } from "../../../types/user";

interface Props {
  onClose: () => void;
  onSend: () => void;
  setErrorMessage: (val: string) => void;
}

const AddUserToMeetingModal: React.FC<Props> = ({
  onClose,
  onSend,
  setErrorMessage,
}) => {
  const { projectId } = useParams();

  const [users, setUsers] = useState<User[]>();
  const [isCheck, setIsCheck] = useState<any>([]);

  useEffect(() => {
    securedApi
      .get(`/projects/${projectId}/users`)
      .then((response) => {
        setUsers(response.data.users);
      })
      .catch((error) => console.error(error));
  }, [projectId]);

  const handleSelectAll = (e: any) => {
    if (users !== undefined) {
      if (isCheck.length < users?.length) {
        setIsCheck(users?.map((user) => user.userId));
      } else {
        setIsCheck([]);
      }
    }
  };

  const handleClick = (e: any) => {
    const { id, checked } = e.target;
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item: string) => item !== id));
    }
  };

  return (
    <Modal
      isOpen={true}
      toggle={onClose}
      modalClassName="modal-black"
      size="lg"
    >
      <div className="modal-header">
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={onClose}
        >
          <i className="tim-icons icon-simple-remove"></i>
        </button>
        <h5 className="modal-title">Send Email invitation to users</h5>
      </div>
      <ModalBody>
        <Table striped className="usersInvitationList">
          <thead className="text-primary">
            <tr>
              <th className="text-center">
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      onChange={handleSelectAll}
                      checked={isCheck.length === users?.length}
                    />
                    <span className="form-check-sign" />
                  </Label>
                </FormGroup>
              </th>
              <th>Name</th>
              <th>Email</th>
              <th className="text-center">Role</th>
            </tr>
          </thead>
          <tbody>
            {users?.map((user) => (
              <tr key={user.userId}>
                <td className="text-center">
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        name={user.userId}
                        id={user.userId}
                        onChange={handleClick}
                        checked={isCheck.includes(user.userId)}
                      />
                      <span className="form-check-sign" />
                    </Label>
                  </FormGroup>
                </td>
                <td>{user.name}</td>
                <td>{user.email}</td>
                <td className="text-center">{user.role}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onClose}>
          Close
        </Button>
        <Button color="info" className="ml-5">
          <i className="tim-icons icon-send pr-2 font-weight-bold" />
          Send invitation
        </Button>
        <Button color="primary" onClick={onSend}>
          Start flight <i className="tim-icons icon-double-right pl-1 pb-1" />
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddUserToMeetingModal;
