import React from "react";
import "./styles.scss";
import { Auth } from "aws-amplify";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { userInfoAtom } from "../../recoil/atoms/userInfo.atom";
import NavbarSelectTenant from "../NavbarSelectTenant/NavbarSelectTenant";
import {
  Button,
  Navbar as Nav,
  NavbarBrand,
  UncontrolledTooltip,
} from "reactstrap";
import { sidebarAtom } from "../../recoil/atoms/sidebar.atom";

export type NavbarParams = {
  projectId: string;
};

const Navbar: React.FC = () => {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useRecoilState(userInfoAtom);
  const [sidebar, setSidebar] = useRecoilState(sidebarAtom);

  const handleMiniClick = () => {
    setSidebar({ ...sidebar, miniSidebar: !sidebar.miniSidebar });
  };

  const brandText = useLocation().pathname.split("/")[1];

  return (
    <Nav expand="lg" color="dark">
      <div className="container-fluid">
        <div className="navbar-minimize d-inline">
          {userInfo.userId && (
            <>
              <Button
                className="minimize-sidebar btn-just-icon"
                color="link"
                id="minimize-button"
                onClick={handleMiniClick}
              >
                <i className="tim-icons icon-align-center visible-on-sidebar-regular" />
                <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini" />
              </Button>

              <UncontrolledTooltip
                delay={0}
                target="minimize-button"
                placement="right"
              >
                Sidebar toggle
              </UncontrolledTooltip>
            </>
          )}
          <NavbarBrand
            className="sidebarTab"
            href="/"
            onClick={(e) => e.preventDefault()}
          >
            {brandText}
          </NavbarBrand>
        </div>

        {userInfo.userId && userInfo.userName ? (
          <div className="usernameContainer">
            {userInfo.tenants.length >= 1 && <NavbarSelectTenant />}
            <div className="username">{userInfo.userName}</div>
            <Button
              className="button"
              size="sm"
              onClick={() => {
                Auth.signOut().then(() => {
                  setUserInfo((userInfo) => ({
                    ...userInfo,
                    userId: "",
                    userName: "",
                  }));
                  navigate("/");
                });
                localStorage.setItem("magicUserId", "");
                localStorage.setItem("magicLinkId", "");
                localStorage.setItem("magicLinkToken", "");
              }}
            >
              Sign&nbsp;out
            </Button>
          </div>
        ) : (
          <button
            className="btn btn-default btn-sm"
            onClick={() => navigate("dashboard")}
          >
            Log In
          </button>
        )}
      </div>
    </Nav>
  );
};

export default Navbar;
