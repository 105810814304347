import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { initial, last, omit } from "lodash";
import { useRecoilValue } from "recoil";

import { securedApi } from "../api";
import {
  MARKER_EVENTS,
  MARKER_TYPES,
} from "../components/SLAM/slam-lib/params";
import { MarkerEvent } from "../components/SLAM/SLAM";
import { userInfoAtom } from "../recoil/atoms/userInfo.atom";
import { Coords, Marker } from "../types/marker";
import { userRoles } from "./roles";
import { User } from "../types/user";

export type MarkerAction = "create" | "delete" | "edit";

export type MarkerUpdate = {
  tmpId: string;
  comment?: string;
  coordinates?: Coords | null;
  measurementVector?: Coords[] | null;
};

export type UndoRedoStep = {
  action: MarkerAction;
  marker: Marker;
};

type Props = {
  projectId: string | undefined;
  buildingPlanId: string | undefined;
  flightPlanId: string | undefined;
  missionId: string | undefined;
  selectedMarkerId: string | undefined;
  setSelectedMarkerId: Dispatch<SetStateAction<string | undefined>>;
  users: User[];
};

function normalizeMarker(marker: Marker) {
  return omit(marker, [
    "tmpId",
    "projectId", // TODO: remove parent entity id stripping once backend bug is fixed
    "buildingPlanId",
    "flightPlanId",
    "missionId",
  ]);
}

function slamToBackendCoords(evt: MarkerEvent) {
  const measurementVector =
    evt.itemType === MARKER_TYPES.MEASUREMENT ? evt.coordinates : null;

  const coordinates =
    evt.itemType === MARKER_TYPES.PIN_MARKER
      ? {
          x: evt.coordinates[0].x,
          y: evt.coordinates[0].y,
        }
      : null;

  return { measurementVector, coordinates };
}

const useMarkerComms = ({
  projectId,
  buildingPlanId,
  flightPlanId,
  missionId,
  selectedMarkerId,
  setSelectedMarkerId,
  users,
}: Props) => {
  const userInfo = useRecoilValue(userInfoAtom);

  const [markers, setMarkers] = useState<Marker[]>([]);
  const [undoList, setUndoList] = useState<UndoRedoStep[]>([]);
  const [redoList, setRedoList] = useState<UndoRedoStep[]>([]);

  const getMarkerColor = useCallback(
    (marker: Marker) => {
      const userRole =
        users.find((u) => u.userId === marker.userId)?.role || "";
      const color =
        userRoles.find((r) => r.name === userRole)?.color || undefined;
      return color;
    },
    [users]
  );

  const fetchMarkers = useCallback(() => {
    if (
      !projectId ||
      !buildingPlanId ||
      !flightPlanId ||
      !missionId ||
      !users.length
    )
      return;

    securedApi
      .get(
        `/projects/${projectId}/buildingPlans/${buildingPlanId}/flightPlans/${flightPlanId}/missions/${missionId}/markers`
      )
      .then((res) => {
        if (res.data?.markers?.length) {
          setMarkers(
            res.data.markers.map((m: Marker) => ({
              ...m,
              tmpId: m.markerId,
              color: getMarkerColor(m),
              editable: m.userId === userInfo.userId,
            }))
          );
        }
      })
      .catch((e) => console.error(e));
  }, [
    projectId,
    buildingPlanId,
    flightPlanId,
    missionId,
    users,
    userInfo,
    getMarkerColor,
  ]);

  useEffect(() => {
    fetchMarkers();
  }, [fetchMarkers]);

  const addUndo = useCallback((step: UndoRedoStep) => {
    setUndoList((list) => [
      ...list,
      { action: step.action, marker: { ...step.marker } },
    ]);
  }, []);

  const onMarkerMessage = useCallback(
    (message: MessageEvent["data"]) => {
      if (
        message.type === "MarkerCreated" &&
        message.userId !== userInfo.userId
      ) {
        setMarkers((markers) => [
          ...markers,
          {
            ...message,
            tmpId: message.markerId,
            color: getMarkerColor(message),
            editable: message.userId === userInfo.userId,
          },
        ]);
      }

      if (message.type === "MarkerDeleted") {
        setMarkers((markers) =>
          markers.filter((marker) => marker.markerId !== message.markerId)
        );
      }

      if (
        message.type === "MarkerCreated" &&
        message.userId === userInfo.userId
      ) {
        setMarkers((markers) =>
          markers.map((marker) => {
            if (marker.markerId === message.markerId) {
              return { ...marker, ...message };
            }
            return marker;
          })
        );
      }
    },
    [userInfo.userId, getMarkerColor]
  );

  const createMarkerRequest = useCallback(
    (marker: Marker) => {
      securedApi
        .post(
          `/projects/${projectId}/buildingPlans/${buildingPlanId}/flightPlans/${flightPlanId}/missions/${missionId}/markers`,
          normalizeMarker(marker)
        )
        .then((res) => {
          setMarkers((markers) => {
            const otherMarkers = markers.filter(
              (m) => m.tmpId !== marker.tmpId
            );
            return [...otherMarkers, { ...marker, ...res.data }];
          });
        })
        .catch((e) => {
          console.error(e);
        });
    },
    [projectId, buildingPlanId, flightPlanId, missionId]
  );

  const updateMarkerRequest = useCallback(
    (marker: Marker) => {
      securedApi
        .put(
          `/projects/${projectId}/buildingPlans/${buildingPlanId}/flightPlans/${flightPlanId}/missions/${missionId}/markers/${marker.markerId}`,
          normalizeMarker(marker)
        )
        .then((res) => {})
        .catch((e) => {
          console.error(e);
        });
    },
    [projectId, buildingPlanId, flightPlanId, missionId]
  );

  const deleteMarkerRequest = useCallback(
    (markerId: string) => {
      securedApi
        .delete(
          `/projects/${projectId}/buildingPlans/${buildingPlanId}/flightPlans/${flightPlanId}/missions/${missionId}/markers/${markerId}`
        )
        .then((res) => {})
        .catch((e) => {
          console.error(e);
        });
    },
    [projectId, buildingPlanId, flightPlanId, missionId]
  );

  const createMarker = useCallback(
    (evt: MarkerEvent, skipUndoUpdate = false) => {
      const coords = slamToBackendCoords(evt);
      const newMarker = {
        tmpId: evt.itemId,
        markerId: "",
        userId: userInfo.userId,
        // projectId: projectId,  // TODO: restore ids or remove completely depending on backend bug fix
        // buildingPlanId: buildingPlanId,
        // flightPlanId: flightPlanId,
        // missionId: missionId,
        timestamp: new Date().toISOString(),
        comment: "",
        color: userRoles.find((r) => r.name === userInfo.role)?.color || "",
        editable: true,
        isAccepted: false,
        ...coords,
      };

      setMarkers((markers) => [...markers, newMarker]);

      createMarkerRequest(newMarker);

      if (!skipUndoUpdate) {
        addUndo({ action: "create", marker: newMarker });
      }
    },
    [
      // projectId,
      // buildingPlanId,
      // flightPlanId,
      // missionId,
      userInfo,
      createMarkerRequest,
      addUndo,
    ]
  );

  const onMarkerUpdate = useCallback(
    (update: MarkerUpdate, skipUndoUpdate = false) => {
      setMarkers((markers) =>
        markers.map((marker) => {
          if (marker.tmpId === update.tmpId) {
            if (!skipUndoUpdate) {
              addUndo({ action: "edit", marker });
            }

            const updatedMarker = {
              ...marker,
              ...update,
              isAccepted: false,
            };
            updateMarkerRequest(updatedMarker);
            return updatedMarker;
          }
          return marker;
        })
      );
    },
    [updateMarkerRequest, addUndo]
  );

  const onMarkerDelete = useCallback(
    (tmpId: string, skipUndoUpdate = false) => {
      if (selectedMarkerId === tmpId) {
        setSelectedMarkerId(undefined);
      }

      setMarkers((markers) => {
        const deletedMarker = markers.find((marker) => marker.tmpId === tmpId);
        if (deletedMarker?.markerId) {
          if (!skipUndoUpdate) {
            addUndo({ action: "delete", marker: deletedMarker });
          }
          deleteMarkerRequest(deletedMarker.markerId);
        }

        return markers.filter((marker) => marker.tmpId !== tmpId);
      });
    },
    [deleteMarkerRequest, selectedMarkerId, addUndo, setSelectedMarkerId]
  );

  const toggleAcceptedComment = useCallback(
    (tmpId: string) => {
      setMarkers((markers) =>
        markers.map((marker) => {
          if (marker.tmpId === tmpId) {
            const updatedMarker = {
              ...marker,
              isAccepted: !marker.isAccepted,
            };
            updateMarkerRequest(updatedMarker);
            return updatedMarker;
          }
          return marker;
        })
      );
    },
    [updateMarkerRequest]
  );

  const onMarkerEvent = useCallback(
    (markerEvent: MarkerEvent) => {
      if (markerEvent.eventType === MARKER_EVENTS.SELECTED) {
        setSelectedMarkerId(markerEvent.itemId);
      } else if (markerEvent.eventType === MARKER_EVENTS.CREATED) {
        createMarker(markerEvent);
        setSelectedMarkerId(markerEvent.itemId);
      } else if (markerEvent.eventType === MARKER_EVENTS.UPDATED) {
        const coords = slamToBackendCoords(markerEvent);
        onMarkerUpdate({
          tmpId: markerEvent.itemId,
          ...coords,
        });
      }
    },
    [createMarker, onMarkerUpdate, setSelectedMarkerId]
  );

  const undo = useCallback(() => {
    if (!undoList.length) return;
    const step = last(undoList);
    if (step) {
      const redoStep = { ...step };
      if (step.action === "create") {
        onMarkerDelete(step.marker.tmpId, true);
      } else if (step.action === "delete") {
        setMarkers((markers) => [...markers, step.marker]);
        createMarkerRequest(step.marker);
      } else if (step.action === "edit") {
        redoStep.marker =
          markers.find((m) => m.tmpId === step.marker.tmpId) || step.marker;
        onMarkerUpdate(step.marker, true);
      }

      setRedoList((list) => [...list, redoStep]);
      setUndoList((list) => initial(list));
    }
  }, [undoList, createMarkerRequest, onMarkerDelete, onMarkerUpdate, markers]);

  const redo = useCallback(() => {
    if (!redoList.length) return;
    const step = last(redoList);
    if (step) {
      const undoStep = { ...step };

      if (step.action === "create") {
        setMarkers((markers) => [...markers, step.marker]);
        createMarkerRequest(step.marker);
      } else if (step.action === "delete") {
        onMarkerDelete(step.marker.tmpId, true);
      } else if (step.action === "edit") {
        undoStep.marker =
          markers.find((m) => m.tmpId === step.marker.tmpId) || step.marker;
        onMarkerUpdate(step.marker, true);
      }

      setUndoList((list) => [...list, undoStep]);
      setRedoList((list) => initial(list));
    }
  }, [redoList, createMarkerRequest, onMarkerDelete, onMarkerUpdate, markers]);

  const returnObject = useMemo(
    () => ({
      markers,
      undo,
      redo,
      onMarkerMessage,
      onMarkerEvent,
      onMarkerUpdate,
      onMarkerDelete,
      toggleAcceptedComment,
    }),
    [
      markers,
      undo,
      redo,
      onMarkerMessage,
      onMarkerEvent,
      onMarkerUpdate,
      onMarkerDelete,
      toggleAcceptedComment,
    ]
  );

  return returnObject;
};

export default useMarkerComms;
