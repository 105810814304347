import React, { useState } from "react";
import spinnerImage from "../../img/daave_icon.png";
import "./styles.scss";

const CustomSpinner: React.FC = () => {
  const [isLoading] = useState<boolean>(true);

  return (
    <div className="customSpinnerContainer">
      {isLoading ? (
        <img className="spinner" src={spinnerImage} alt="Spinner" width={100} />
      ) : null}
    </div>
  );
};

export default CustomSpinner;
