import * as THREE from "three";
import { SVGLoader } from "three/addons/loaders/SVGLoader.js";

import * as PARAMS from "../params.js";
import { MARKER_EVENTS, MARKER_TYPES } from "../params.js";
import { Label, LabelZoomMode, DEFAULT_LABEL_FONT_SIZE,
         LabelHorizAlignMode, LabelVertAlignMode } from "./label.js"
import { saveBlob, loadLocalJson } from "../io.js";

const ROT90 = THREE.MathUtils.degToRad(90);

const EditState = Object.freeze({
  None: Symbol("None"),
  DrawStart: Symbol("DrawStart"),
  Draw: Symbol("Draw"),
  DrawEnd: Symbol("DrawEnd"),
  MoveStart: Symbol("MoveStart"),
  Move: Symbol("Move"),
});

const hoveredColor = new THREE.Color(0xff0000);
const selectedColor = new THREE.Color(0x0000ff);

const outlineStrokeStyle = {
  strokeWidth: 20,
};


export class PinMarker {
  #projectContext;
  #group;
  #pinMesh;
  #pinMeshOutline;
  #hoveredPin;
  #selectedPin;
  #label;
  #labelText = "";
  #labelZoomMode = LabelZoomMode.Fixed;
  #labelFontSize = DEFAULT_LABEL_FONT_SIZE;
  #hitDx = 0;
  #hitDy = 0;
  #editState = EditState.None;
  #undoStack = [];
  #redoStack = [];

  id = null;
  coordinates = {};
  color = null;
  editable = false;

  constructor(projectContext, color, editable) {
    this.#projectContext = projectContext;
    if (color) {
      this.color = color;
    }
    if (editable !== undefined) {
      this.editable = editable;
    }

    this.#group = new THREE.Group();
    this.#projectContext.scene.add(this.#group);

    const loader = new SVGLoader();
    loader.load(`${PARAMS.PATHS.IMAGES}/pin.svg`, (data) => {
      const path = data.paths[0];
      const fillColor = color || "forestgreen";
      if (fillColor !== undefined && fillColor !== "none") {
        const material = new THREE.MeshBasicMaterial({
          color: new THREE.Color().setStyle(fillColor),
          opacity: path.userData.style.fillOpacity,
          transparent: true,
          side: THREE.DoubleSide,
          depthWrite: false,
          wireframe: false,
        });
        const shapes = SVGLoader.createShapes(path);
        const shape = shapes[0];
        const geometry = new THREE.ShapeGeometry(shape);
        geometry.computeBoundingBox();
        this.#pinMesh = new THREE.Mesh(geometry, material);

        const outlineGeometry = SVGLoader.pointsToStroke(
          shape.getPoints(),
          outlineStrokeStyle
        );
        const outlineMaterial = new THREE.MeshBasicMaterial({
          side: THREE.DoubleSide,
          transparent: true,
          opacity: 0.5,
        });
        this.#pinMeshOutline = new THREE.Mesh(outlineGeometry, outlineMaterial);
      }

      this.#pinMesh.visible = false;
      this.#pinMeshOutline.visible = false;
      this.#projectContext.scene.add(this.#pinMesh);
      this.#projectContext.scene.add(this.#pinMeshOutline);
      this.#update();
    });

    this.#undoPush();
  }

  setCoordinates(coordinates) {
    this.coordinates = coordinates;
    this.#update();
  }

  setData(pinData) {
    this.id = pinData.id;
    this.coordinates = pinData.coordinates;
    this.#update();
  }

  getData() {
    return {
      id: this.id,
      coordinates: this.coordinates,
    };
  }

  select() {
    this.#selectedPin = true; // TODO: not exactly the expected type :P
    this.#update();
  }

  deselect() {
    this.#selectedPin = null;
    this.#hoveredPin = null;
    this.#update();
  }

  clear() {
    if (this.coordinates) {
      this.#undoPush();
      this.coordinates = {};
      this.#update();
    }
  }

  onMouseDown(xPos, yPos) {
    this.#selectedPin = null;
    if (this.#hoveredPin) {
      this.#editState = EditState.MoveStart;
      this.#hitDx = this.#hoveredPin.point.x - this.coordinates.x;
      this.#hitDy = this.#hoveredPin.point.z - this.coordinates.y;
    } else {
      this.#editState = EditState.DrawStart;
      this.#mouseHandler(xPos, yPos);
    }
    this.#update();
  }

  onMouseMove(xPos, yPos) {
    if (!this.editable) return;

    switch (this.#editState) {
      case EditState.DrawStart:
        this.#editState = EditState.Draw;
        break;
      case EditState.MoveStart:
        this.#editState = EditState.Move;
        break;
      default:
        break;
    }
    this.#mouseHandler(xPos, yPos);
  }

  onMouseUp(xPos, yPos) {
    switch (this.#editState) {
      case EditState.MoveStart:
        if (this.#hoveredPin) this.#selectedPin = this.#hoveredPin;
        this.dispatchMarkerEvent(MARKER_EVENTS.SELECTED);
        break;
      case EditState.Move:
        this.#mouseHandler(xPos, yPos);
        this.#undoPush();
        this.dispatchMarkerEvent(MARKER_EVENTS.UPDATED);
        break;
      case EditState.DrawStart:
      case EditState.Draw:
        this.#editState = EditState.DrawEnd;
        this.#mouseHandler(xPos, yPos);
        this.#undoPush();
        this.dispatchMarkerEvent(MARKER_EVENTS.CREATED);
        break;
      default:
        break;
    }

    this.#editState = EditState.None;
    this.#hoveredPin = null;
    this.#update();
  }

  hitTest(xPos, yPos) {
    const rect = this.#projectContext.renderer.domElement.getBoundingClientRect();
    const x = ((xPos - rect.left) / rect.width) * 2 - 1;
    const y = -((yPos - rect.top) / rect.height) * 2 + 1;
    this.#projectContext.rayCaster.setFromCamera({ x, y }, this.#projectContext.camera);

    if (this.#pinMesh) {
      const prevHovered = this.#hoveredPin;
      this.#hoveredPin = null;
      let hit = false;
      let update = false;
      const intersections = this.#projectContext.rayCaster.intersectObject(this.#pinMesh);
      if (intersections.length) {
        hit = true;
        this.#hoveredPin = intersections[0];
        if (this.#pinMeshOutline && this.#pinMeshOutline.visible !== hit) {
          this.#pinMeshOutline.visible = hit;
          update = true;
        }
      }
      if (prevHovered !== this.#hoveredPin || update) this.#update();
      return hit;
    }
    return false;
  }

  #mouseHandler(xPos, yPos) {
    this.hitTest(xPos, yPos);

    if (
      this.#editState !== EditState.DrawEnd &&
      this.#editState !== EditState.None
    ) {
      const intersections = this.#projectContext.rayCaster.intersectObject(this.#projectContext.drawGrid);
      if (intersections.length) {
        const p = intersections[0].point;
        this.coordinates = { x: p.x - this.#hitDx, y: p.z - this.#hitDy };
        this.#update();
      }
    }
  }

  addLabel(text, size) {
    this.#labelText = text;
    this.#labelFontSize = size;
    this.#update();
  }

  setLabelText(text) {
    this.#labelText = text;
    if (this.#label) this.#label.setText(text);
  }

  setLabelFontSize(size) {
    this.#labelFontSize = size;
    if (this.#label) this.#label.setFontSize(size);
  }

  setLabelZoomMode(labelZoomMode) {
    this.#labelZoomMode = labelZoomMode;
    if (this.#label) this.#label.setZoomMode(labelZoomMode);
  }

  destroy() {
    this.dispose();
  }

  dispose() {
    if (this.#pinMeshOutline) {
      this.#projectContext.scene.remove(this.#pinMeshOutline);
      this.#pinMeshOutline.material.dispose();
      this.#pinMeshOutline.geometry.dispose();
      this.#pinMeshOutline = null;
    }
    if (this.#pinMesh) {
      this.#projectContext.scene.remove(this.#pinMesh);
      this.#pinMesh.material.dispose();
      this.#pinMesh.geometry.dispose();
      this.#pinMesh = null;
    }
    this.#cleanup();
  }

  #cleanup() {
    if (this.#group) {
      this.#projectContext.scene.remove(this.#group);
      this.#group = null;
    }
    if (this.#label) {
      this.#label.dispose();
      this.#label = null;
    }
  }

  #update() {
    this.#cleanup();

    this.#group = new THREE.Group();
    this.#projectContext.scene.add(this.#group);

    if (this.coordinates && this.#pinMesh) {
      const bbox = this.#pinMesh.geometry.boundingBox;
      const scale = 0.002;
      const dx = ((bbox.max.x - bbox.min.x) / 2.0) * scale;
      const dy = (bbox.max.y - bbox.min.y) * scale;
      const x = this.coordinates.x - dx;
      const y = PARAMS.MEASURINGS_Z_POS - 0.0007;
      const z = this.coordinates.y - dy;

      this.#pinMesh.visible = true;
      this.#pinMesh.position.set(x, y, z);
      this.#pinMesh.scale.setScalar(scale);
      this.#pinMesh.rotation.set(ROT90, 0.0, 0.0);
      this.#pinMesh.updateMatrix();

      if (this.#pinMeshOutline) {
        const marker = this.#hoveredPin ? this.#hoveredPin : this.#selectedPin;
        if (marker && this.#editState !== EditState.Move) {
          const color = (marker === this.#hoveredPin) ? hoveredColor : selectedColor;
          this.#pinMeshOutline.material.color = color;
          this.#pinMeshOutline.position.set(x, y - 0.0001, z);
          this.#pinMeshOutline.scale.setScalar(scale);
          this.#pinMeshOutline.rotation.set(ROT90, 0.0, 0.0);
          this.#pinMeshOutline.updateMatrix();
          this.#pinMeshOutline.visible = true;
        } else {
          this.#pinMeshOutline.visible = false;
        }
      } else {
        this.#pinMeshOutline.visible = false;
      }

      if (this.#labelText) {
        const labelPos = new THREE.Vector3(x + dx, y, z + dy * 1.3);
        this.#label = new Label(
          this.#projectContext, this.#labelText,
          this.#labelFontSize, labelPos,
          this.#labelZoomMode,
          LabelHorizAlignMode.Center,
          LabelVertAlignMode.Top
        );
      }
    }
  }

  deleteSelectedNode() {
    if (this.#selectedPin) {
      if (this.coordinates) {
        this.coordinates = {};
        this.#undoPush();
      }
      this.#selectedPin = null;
      if (this.#pinMeshOutline) this.#pinMeshOutline.visible = false;
      this.#update();
    }
  }

  save() {
    if (this.coordinates) {
      this.#saveJsonPath(this.coordinates, "pin_coord.json");
    }
  }

  #saveJsonPath(path, fileName) {
    saveBlob(JSON.stringify(path), fileName);
  }

  load() {
    loadLocalJson((path) => {
      //TODO this.points = path;
      this.#update();
    });
    this.#update();
  }

  #compareCoord(a, b) {
    return a.x === b.x && a.y === b.y;
  }

  #undoPush() {
    this.#undoStack.push(structuredClone(this.coordinates));
  }

  #redoPush() {
    this.#redoStack.push(structuredClone(this.coordinates));
  }

  undo() {
    if (this.#undoStack.length) {
      if (this.#compareCoord(this.#undoStack.at(-1), this.coordinates)) {
        this.#undoStack.pop();
        this.#redoPush();
      }
      if (this.#undoStack.length) {
        this.coordinates = this.#undoStack.pop();
        this.#redoPush();
      }
      this.#update();
    }
  }

  redo() {
    if (this.#redoStack.length) {
      if (this.#compareCoord(this.#redoStack.at(-1), this.coordinates)) {
        this.#redoStack.pop();
        this.#undoPush();
      }
      if (this.#redoStack.length) {
        this.coordinates = this.#redoStack.pop();
        this.#undoPush();
      }
      this.#update();
    }
  }

  dispatchMarkerEvent(eventType) {
    const event = new CustomEvent("marker-event", {
      detail: {
        eventType,
        item: this,
        itemId: this.id,
        itemType: MARKER_TYPES.PIN_MARKER,
        coordinates: [this.coordinates],
      },
    })
    this.#projectContext.renderer.domElement.dispatchEvent(event);
  }
}
