export const config = {
  Auth: {
    region: "eu-central-1",
    userPoolId: "eu-central-1_zTPRX5JoA",
    userPoolWebClientId: "136bqoeqaedh5rhktm2hm806oj",
    mandatorySignIn: true,
    oauth: {
      domain: "daave-test.auth.eu-central-1.amazoncognito.com",
      scope: ["openid"],
      redirectSignIn: process.env.REACT_APP_URL,
      redirectSignOut: process.env.REACT_APP_URL,
      responseType: "code",
    },
  },
};
