import React, { useEffect, useState } from "react";
import { Button, Form, Modal, ModalBody, ModalFooter } from "reactstrap";
import { User } from "../../../types/user";
import { securedApi } from "../../../api";
import { AxiosError } from "axios";

interface Props {
  projectId: string;
  user: User;
  onCancel: () => void;
}

const MagicLinkModal: React.FC<Props> = ({ projectId, user, onCancel }) => {
  const [magicLinkId, setMagicLinkId] = useState();
  const [error, setError] = useState<AxiosError>();
  const baseUrl = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    securedApi
      .get(`/projects/${projectId}/users/${user.userId}/link`)
      .then((res) => {
        setMagicLinkId(res.data.linkId);
      })
      .catch((error) => {
        console.error(error);
        setError(error);
      });
  }, [projectId, user.userId]);

  return (
    <Modal isOpen={true} toggle={onCancel} modalClassName="modal-black">
      <div className="modal-header">
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={onCancel}
        >
          <i className="tim-icons icon-simple-remove"></i>
        </button>
        <h5 className="modal-title">Magic Link Generation</h5>
      </div>
      <ModalBody>
        <Form action="" className="form" method="">
          <div className="card-content">
            Invitation magic link for {user.name}:
          </div>
        </Form>
        <div>
          {magicLinkId ? (
            <a
              className="text-break"
              href={`${baseUrl}/magicLinkVerification/${projectId}/${user.userId}/${magicLinkId}`}
            >{`${baseUrl}/magicLinkVerification/${projectId}/${user.userId}/${magicLinkId}`}</a>
          ) : (
            "..."
          )}
          {error?.message}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onCancel}>
          OK
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default MagicLinkModal;
