import { Button, Card, CardBody, CardFooter, CardHeader } from "reactstrap";
import { IconContext } from "react-icons";
import { BiLocationPlus, BiPointer, BiSolidReport } from "react-icons/bi";
import { TbDownload, TbRulerMeasure } from "react-icons/tb";
import React from "react";
import CommentSection from "../../components/CommentSection/CommentSection";

import "./styles.scss";
import ViewSwitcher from "../ViewSwitcher/ViewSwitcher";
import { AnalysisScreen } from "../../pages/Analysis/Analysis";
import { ControlMode } from "../SLAM/slam-lib/project-editor";
import { Marker } from "../../types/marker";
import FullScreenSwitcher from "../../components/FullScreenSwitcher/FullScreenSwitcher";
import { User } from "../../types/user";
import { UserInfoInterface } from "../../recoil/atoms/userInfo.atom";
import { MarkerUpdate } from "../../utils/useMarkerComms";

interface Props {
  downloadDXF: () => void;
  userInfo: UserInfoInterface;
  allUsers: User[];
  connectedUsers: User[];
  markers: Marker[];
  mainScreen: AnalysisScreen;
  setMainScreen: (val: AnalysisScreen) => void;
  fullScreen: boolean;
  setFullScreen: (val: boolean) => void;
  controlMode?: symbol;
  setControlMode: (val: symbol) => void;
  selectedMarkerId?: string;
  onItemSelected: (itemId: string) => void;
  onMarkerUpdate: (item: MarkerUpdate) => void;
  onMarkerDelete: (itemId: string) => void;
  toggleAccepted: (itemId: string) => void;
  onGenerateReport: () => void;
}

const AnalysisToolbar: React.FC<Props> = ({
  downloadDXF,
  userInfo,
  allUsers,
  connectedUsers,
  markers,
  mainScreen,
  setMainScreen,
  fullScreen,
  setFullScreen,
  controlMode,
  setControlMode,
  selectedMarkerId,
  onItemSelected,
  onMarkerUpdate,
  onMarkerDelete,
  toggleAccepted,
  onGenerateReport,
}) => {
  const handleControlModeChange = (val: symbol) => {
    if (controlMode === val) {
      setControlMode(ControlMode.Orbit);
    } else {
      setControlMode(val);
    }
  };

  const connectedUserNames = connectedUsers.map((user) => user.name).join("\n");

  return (
    <Card className="analysis-toolbar">
      <CardHeader className="d-flex">
        <Button
          data-placement="top"
          title="Selection mode"
          className={
            controlMode === ControlMode.Orbit ? "btn-success m-2" : "m-2"
          }
          onClick={() => handleControlModeChange(ControlMode.Orbit)}
        >
          <IconContext.Provider value={{ size: "1.5rem" }}>
            <BiPointer />
          </IconContext.Provider>
        </Button>
        <Button
          data-placement="top"
          title="Add new marker to map"
          className={
            controlMode === ControlMode.EditPinMarker
              ? "btn-success m-2"
              : "m-2"
          }
          onClick={() => handleControlModeChange(ControlMode.EditPinMarker)}
        >
          <IconContext.Provider value={{ size: "1.5rem" }}>
            <BiLocationPlus />
          </IconContext.Provider>
        </Button>
        <Button
          data-placement="top"
          title="Add distance measurement to map"
          className={
            controlMode === ControlMode.EditMeasure ? "btn-success m-2" : "m-2"
          }
          onClick={() => handleControlModeChange(ControlMode.EditMeasure)}
        >
          <IconContext.Provider value={{ size: "1.5rem" }}>
            <TbRulerMeasure />
          </IconContext.Provider>
        </Button>
      </CardHeader>
      <CardBody className="mt-4">
        <div className="userCounter" title={connectedUserNames}>
          {connectedUsers.length}{" "}
          {connectedUsers.length === 1 ? "user" : "users"} connected
        </div>
        <CommentSection
          userInfo={userInfo}
          users={allUsers}
          markers={markers}
          selectedMarkerId={selectedMarkerId}
          onItemSelected={onItemSelected}
          onItemUpdate={onMarkerUpdate}
          onItemDelete={onMarkerDelete}
          toggleAccepted={toggleAccepted}
        />
      </CardBody>
      <CardFooter>
        <div>
          <ViewSwitcher mainScreen={mainScreen} setMainScreen={setMainScreen} />
          <FullScreenSwitcher
            fullScreen={fullScreen}
            setFullScreen={setFullScreen}
          />
        </div>
        <div className="d-flex flex-column ">
          {userInfo.type === "privileged" && (
            <Button
              size="sm"
              onClick={onGenerateReport}
              className="d-flex justify-content-center align-items-center"
            >
              <IconContext.Provider value={{ size: "1.5rem" }}>
                <BiSolidReport />
              </IconContext.Provider>
              Generate Report
            </Button>
          )}
          <Button
            size="sm"
            onClick={downloadDXF}
            className="d-flex justify-content-center align-items-center"
            style={{ marginLeft: 0 }}
          >
            <IconContext.Provider value={{ size: "1.5rem" }}>
              <TbDownload />
            </IconContext.Provider>
            Download DXF
          </Button>
        </div>
      </CardFooter>
    </Card>
  );
};

export default AnalysisToolbar;
