import * as THREE from 'three';
import { isStrAt, strFromArrayBuffer, readString } from '../utils/binary-utils.js'

export class Odometry {
    timeSec;
    timeNanosec;
    frameId;
    childFrameId;
    position = {
        x: 0.0,
        y: 0.0,
        z: 0.0
    };
    orientation = {
        x: 0.0,
        y: 0.0,
        z: 0.0,
        w: 0.0
    };
    twist = {
        linear: {
            x: 0.0,
            y: 0.0,
            z: 0.0
        },
        angular: {
            x: 0.0,
            y: 0.0,
            z: 0.0
        },

    };

    load(url) {
        const loader = new THREE.FileLoader();
        loader.setResponseType('arraybuffer');
        loader.loadAsync(url)
            .then(data => {
                this.parseDaaveBinary(data);
            })
            .catch(err => {
                console.error('Error loading file:', err);
            });
    }

    parseDaaveBinary(buffer, byteOffset=0) {
        const dv = new DataView(buffer, byteOffset);

        // 0 - 9: header id string
        const DAAVE_ODOM = 'DAAVE_ODOM';
        if (!isStrAt(dv, DAAVE_ODOM, 0)) {
            const headerId = strFromArrayBuffer(buffer, byteOffset, DAAVE_ODOM.length);
            console.error(`Invalid file format. Unknown header ID: '${headerId}'.`);
            return 0;
        }

        let pos = DAAVE_ODOM.length;

        // version - uint8
        const ver = dv.getUint8(pos);
        if (ver !== 1) {
            console.error(`DAAVE_ODOM: Invalid file version. Should be 1, but is ${ver}.`);
            return 0;
        }
        pos += 1;

        // timestamp - sec - int32
        this.timeSec = dv.getInt32(pos, true);
        pos += 4;

        // timestamp - nanosec - uint32
        this.timeNanosec = dv.getUint32(pos, true);
        pos += 4;

        [this.frameId, pos] = readString(dv, pos);
        [this.childFrameId, pos] = readString(dv, pos);

        // position - float64 * 3
        this.position.x = dv.getFloat64(pos, true);
        pos += 8;
        this.position.y = dv.getFloat64(pos, true);
        pos += 8;
        this.position.z = dv.getFloat64(pos, true);
        pos += 8;

        // orientation - float64 * 4
        this.orientation.x = dv.getFloat64(pos, true);
        pos += 8;
        this.orientation.y = dv.getFloat64(pos, true);
        pos += 8;
        this.orientation.z = dv.getFloat64(pos, true);
        pos += 8;
        this.orientation.w = dv.getFloat64(pos, true);
        pos += 8;

        // twist linear - float64 * 3
        this.twist.linear.x = dv.getFloat64(pos, true);
        pos += 8;
        this.twist.linear.y = dv.getFloat64(pos, true);
        pos += 8;
        this.twist.linear.z = dv.getFloat64(pos, true);
        pos += 8;

        // twist angular - float64 * 3
        this.twist.angular.x = dv.getFloat64(pos, true);
        pos += 8;
        this.twist.angular.y = dv.getFloat64(pos, true);
        pos += 8;
        this.twist.angular.z = dv.getFloat64(pos, true);
        pos += 8;

        return pos;
    }
}