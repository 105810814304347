import React, { useEffect } from "react";
import "./App.scss";
import { Routes, Route } from "react-router-dom";
import LandingPage from "./pages/LandingPage/LandingPage";
import "@aws-amplify/ui-react/styles.css";
import Dashboard from "./pages/Dashboard/Dashboard";
import Navbar from "./components/Navbar/Navbar";
import ProjectPage from "./pages/ProjectPage/ProjectPage";
import ProtectedRoute from "./components/ProtectedRoute";
import Map from "./pages/Map/Map";
import UsersManager from "./pages/UsersManager/UsersManager";
import FlightPlan from "./pages/FlightPlan/FlightPlan";
import MeetingPlanner from "./pages/MeetingPlanner/MeetingPlanner";
import Flight from "./pages/Flight/Flight";
import Analysis from "./pages/Analysis/Analysis";
import { useApiAuthInterceptor } from "./api";
import MagicLinkVerification from "./pages/MagicLinkVerification/MagicLinkVerification";

const App = () => {
  useApiAuthInterceptor();

  const magicLinkToken = localStorage.getItem("magicLinkToken");

  useEffect(() => {}, [magicLinkToken]);

  return (
    <div className="App">
      <Navbar />
      <Routes>
        <Route index element={<LandingPage />} />
        <Route
          path="dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />

        <Route
          path="projects/:projectId"
          element={
            <ProtectedRoute>
              <ProjectPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="projects/:projectId/users"
          element={
            <ProtectedRoute>
              <UsersManager />
            </ProtectedRoute>
          }
        />
        <Route
          path="projects/:projectId/meeting"
          element={
            <ProtectedRoute>
              <MeetingPlanner />
            </ProtectedRoute>
          }
        />
        <Route
          path="projects/:projectId/flightPlan/:buildingPlanId"
          element={
            <ProtectedRoute>
              <FlightPlan />
            </ProtectedRoute>
          }
        />
        <Route
          path="projects/:projectId/flight/:buildingPlanId/flightPlan/:flightPlanId/mission/:missionId"
          element={
            <ProtectedRoute>
              <Flight />
            </ProtectedRoute>
          }
        />
        <Route
          path="projects/:projectId/analysis/:buildingPlanId/flightPlan/:flightPlanId/mission/:missionId"
          element={
            <ProtectedRoute>
              <Analysis />
            </ProtectedRoute>
          }
        />
        <Route
          path="map"
          element={
            <ProtectedRoute>
              <Map />
            </ProtectedRoute>
          }
        />
        <Route
          path="map/:projectId"
          element={
            <ProtectedRoute>
              <Map />
            </ProtectedRoute>
          }
        />
        <Route
          path="magicLinkVerification/:projectId/:userId/:magicLinkId"
          element={<MagicLinkVerification />}
        />
        <Route path="*" element={<p>There's nothing here: 404!</p>} />
      </Routes>
    </div>
  );
};

export default App;
