import React, { useEffect, useState } from "react";
import {
  Button,
  CardBody,
  CardTitle,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { CreateNewBuildingPlan } from "../../../types/project";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { useRecoilValue } from "recoil";
import { userInfoAtom } from "../../../recoil/atoms/userInfo.atom";
import { useParams } from "react-router-dom";
import { ProjectPageParams } from "../../../pages/ProjectPage/ProjectPage";
import { securedApi } from "../../../api";
import CustomSpinner from "../../CustomSpinner/CustomSpinner";
import "./styles.scss";
import schema from "./schema";

interface Props {
  onClose: () => void;
  setErrorMessage: (val: string) => void;
  createdBuildingPlanId: undefined | string;
  createNewBuildingPlan: (val: any) => void;
  signOut?: any;
  user?: any;
}

const AddNewBuildingPlanModal: React.FC<Props> = ({
  onClose,
  createdBuildingPlanId,
  createNewBuildingPlan,
  user,
}) => {
  const userInfo = useRecoilValue(userInfoAtom);
  const { projectId } = useParams<ProjectPageParams>();
  const [thumbnailUrl, setThumbnailUrl] = useState();

  const [newBuildingPlan, setNewBuildingPlan] = useState<CreateNewBuildingPlan>(
    {
      name: "",
      description: "",
    }
  );
  const [validationErrors, setValidationErrors] =
    useState<CreateNewBuildingPlan>({
      name: "",
      description: "",
    });
  const [initValidation, setInitValidation] = useState<boolean>(false);

  useEffect(() => {
    const fetchThumbnail = () => {
      securedApi
        .get(`/projects/${projectId}/buildingPlans/${createdBuildingPlanId}`)
        .then((res) => {
          setThumbnailUrl(res.data.mapPreviewLocation);
        });
    };

    fetchThumbnail();
  }, [
    createdBuildingPlanId,
    projectId,
    user.signInUserSession.idToken,
    userInfo.tenantName,
  ]);

  useEffect(() => {
    const validation = () => {
      const validationResult = schema.safeParse(newBuildingPlan);
      if (!validationResult.success) {
        const formattedErrors = validationResult.error.format();
        if (formattedErrors._errors) {
          setValidationErrors({
            name: formattedErrors.name?._errors.join(", ") || "",
            description: formattedErrors.description?._errors.join(", ") || "",
          });
        }
      } else {
        setValidationErrors({
          name: "",
          description: "",
        });
      }
    };
    if (initValidation) {
      validation();
    }
  }, [initValidation, newBuildingPlan]);

  return (
    <Modal
      isOpen={true}
      modalClassName="modal-black"
      style={{ marginTop: "-5%" }}
    >
      <div className="modal-header">
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={onClose}
        >
          <i className="tim-icons icon-simple-remove"></i>
        </button>
      </div>
      <ModalBody>
        <Form action="" className="form" method="">
          <div className="card-content">
            <CardTitle tag="h4">Add new building plan</CardTitle>
            <div
              style={{ width: "100%", height: "300px" }}
              className="d-flex justify-content-center align-items-center"
            >
              {thumbnailUrl ? (
                <img alt="Loaded file preview" src={thumbnailUrl} />
              ) : (
                <CustomSpinner />
              )}
            </div>
            <CardBody>
              <Label for="projectName">name</Label>
              <FormGroup>
                <div className="inputContainer">
                  <Input
                    id="projectName"
                    type="text"
                    value={newBuildingPlan.name}
                    onChange={(e) =>
                      setNewBuildingPlan((prevProject) => ({
                        ...prevProject,
                        name: e.target.value,
                      }))
                    }
                  />
                  {
                    <div
                      className="text-danger"
                      style={{
                        display: validationErrors.name ? "block" : "none",
                      }}
                    >
                      {validationErrors.name}
                    </div>
                  }
                </div>
              </FormGroup>
              <FormGroup>
                <Label for="description">Description</Label>
                <div className="inputContainer">
                  <Input
                    type="text"
                    id="description"
                    value={newBuildingPlan.description}
                    onChange={(e) =>
                      setNewBuildingPlan((prevProject) => ({
                        ...prevProject,
                        description: e.target.value,
                      }))
                    }
                  />
                  {
                    <div
                      className="text-danger"
                      style={{
                        display: validationErrors.description
                          ? "block"
                          : "none",
                      }}
                    >
                      {validationErrors.description}
                    </div>
                  }
                </div>
              </FormGroup>
            </CardBody>
          </div>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onClose}>
          Close
        </Button>
        <Button
          className="btn-fill"
          color="primary"
          type="submit"
          onClick={() => {
            setInitValidation(true);
            const validationResult = schema.safeParse(newBuildingPlan);
            if (validationResult.success) {
              createNewBuildingPlan(newBuildingPlan);
            }
          }}
          disabled={!thumbnailUrl}
        >
          Add new building plan
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default withAuthenticator(AddNewBuildingPlanModal);
