import React, { useCallback, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import "./styles.scss";

import { useParams } from "react-router-dom";
import { FlightParams } from "../../pages/Flight/Flight";

import DroneAltitude from "./DroneAltitude/DroneAltitude";
import ControlButtons from "./ControlButtons/ControlButtons";
import Protractor from "./Protractor/Protractor";
import { Mission } from "../../types/project";
import { securedApi } from "../../api";

export enum Direction {
  Up,
  Down,
  Left,
  Right,
  Forward,
  Backward,
  RotateLeft,
  RotateRight,
}

interface Props {
  mission: Mission | undefined;
}

const FlightControlPanel: React.FC<Props> = ({ mission }) => {
  const deltaRotationAngle = 15;
  const deltaAltitude = 10;

  const { projectId, buildingPlanId, flightPlanId, missionId } =
    useParams<FlightParams>();

  const [positionAltitude, setPositionAltitude] = useState(0);
  const [positionRotation, setPositionRotation] = useState(0);

  const updatePosition = useCallback(
    (data: any) => {
      securedApi
        .post(
          `/projects/${projectId}/buildingPlans/${buildingPlanId}/flightPlans/${flightPlanId}/missions/${missionId}/sendCommand`,
          data
        )
        .catch((e) => console.error(e));
    },
    [buildingPlanId, flightPlanId, missionId, projectId]
  );

  const handleAltitude = useCallback(
    (direction: Direction) => {
      if (direction === Direction.Up && positionAltitude < 300) {
        setPositionAltitude(positionAltitude + 30);
        updatePosition({
          type: "changeAltitude",
          data: { distance: deltaAltitude },
        });
      } else if (direction === Direction.Down && positionAltitude > -300) {
        setPositionAltitude(positionAltitude - 30);

        updatePosition({
          type: "changeAltitude",
          data: { distance: -deltaAltitude },
        });
      }
    },
    [positionAltitude, updatePosition]
  );

  const handleRotation = useCallback(
    (direction: Direction) => {
      if (direction === Direction.RotateLeft) {
        setPositionRotation(positionRotation + deltaRotationAngle);
      } else if (direction === Direction.RotateRight) {
        setPositionRotation(positionRotation - deltaRotationAngle);
      }
    },
    [positionRotation]
  );

  return (
    <Container className="h-100">
      <Row>
        <ControlButtons isFlying={false} mission={mission} />
      </Row>
      <Row className="flightControlContainer">
        <Col xs={5}>
          <DroneAltitude
            positionAltitude={positionAltitude}
            handleAltitude={handleAltitude}
          />
        </Col>
        <Col xs={7}>
          <Protractor
            positionRotation={positionRotation}
            handleRotation={handleRotation}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default FlightControlPanel;
