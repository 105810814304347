import React from "react";
import cx from "classnames";
import "./styles.scss";

type BuildingPlanCardProps = {
  label?: string;
  labelDescription?: string;
  imageUrl?: string | null;
  indicator?: boolean;
  indicatorTitle?: string;
  linkUrl?: string;
};

const BuildingPlanCard: React.FC<BuildingPlanCardProps> = ({
  label,
  labelDescription,
  imageUrl,
  indicator,
  indicatorTitle,
  linkUrl,
}) => {
  const cardClasses = cx("card", { noData: !imageUrl });

  const indicatorClasses = cx("indicator d-inline-block ml-2", {
    active: indicator,
  });

  return (
    <div className="buildingPlanCard col-lg-4 col-md-6">
      <div className={cardClasses}>
        {linkUrl ? (
          <>
            {imageUrl ? (
              <a href={linkUrl}>
                <img className="card-img-top" alt={label} src={imageUrl} />
              </a>
            ) : (
              <a href={linkUrl}>
                <div className="imagePlaceholder">
                  <i className="tim-icons icon-image-02"></i>
                </div>
              </a>
            )}

            <div className="card-body">
              <p className="labelDescription">{labelDescription}</p>
              <p title={label} className="truncateLine">
                {label}
              </p>
              <div className={indicatorClasses} title={indicatorTitle}></div>
            </div>
          </>
        ) : (
          <>
            <div className="noData"></div>
            <div className="card-body">
              <p className="card-text d-inline-block"></p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default BuildingPlanCard;
