import { orderBy } from "lodash";
import React, { useMemo } from "react";

import Comment from "../Comment/Comment";
import { Marker } from "../../types/marker";
import { User } from "../../types/user";
import { UserInfoInterface } from "../../recoil/atoms/userInfo.atom";
import { MarkerUpdate } from "../../utils/useMarkerComms";
import "./styles.scss";

interface Props {
  userInfo: UserInfoInterface;
  users: User[];
  markers: Marker[];
  selectedMarkerId?: string;
  onItemSelected: (itemId: string) => void;
  onItemUpdate: (item: MarkerUpdate) => void;
  onItemDelete: (itemId: string) => void;
  toggleAccepted: (itemId: string) => void;
}

const CommentSection: React.FC<Props> = ({
  userInfo,
  users,
  markers,
  selectedMarkerId,
  onItemSelected,
  onItemUpdate,
  onItemDelete,
  toggleAccepted,
}) => {
  const orderedMarkers = useMemo(
    () => orderBy(markers, "creationTimestamp"),
    [markers]
  );

  return (
    <div className="commentSection">
      <div className="comments">
        <ul>
          {orderedMarkers?.map((marker) => {
            const user = users.find((u) => u.userId === marker.userId);

            return (
              <li
                key={marker.tmpId}
                onClick={() => onItemSelected(marker.tmpId)}
              >
                <Comment
                  editable={marker.userId === userInfo.userId}
                  id={marker.tmpId}
                  text={marker.comment}
                  name={user?.name}
                  color={marker.color}
                  selected={marker.tmpId === selectedMarkerId}
                  accepted={!!marker.isAccepted}
                  onUpdate={onItemUpdate}
                  onDelete={onItemDelete}
                  toggleAccepted={toggleAccepted}
                />
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default CommentSection;
