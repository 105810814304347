export function loadLocalJson(onLoad) {
  const input = document.createElement("input");
  input.type = "file";
  input.accept = "application/JSON";
  input.addEventListener("change", (event) => {
    const reader = new FileReader();
    reader.addEventListener("load", (e) => {
      onLoad(JSON.parse(e.target.result));
    });
    reader.readAsText(event.target.files[0]);
  });
  input.click();
}

export function loadTextFile(url, onLoad) {
  fetch(url)
    .then((r) => r.text())
    .then((t) => onLoad(t));
}

export function saveBlob(blob, fileName) {
  const link = document.createElement("a");
  link.download = fileName;
  link.href = URL.createObjectURL(new Blob([blob], { type: "text/plain" }));
  link.click();
}
