import { atom } from "recoil";

export interface UserInfoInterface {
  type: 'privileged' | 'limited' | undefined;
  userId: string;
  userName: string;
  userEmail: string;
  role: string;
  tenantName: string | null;
  tenants: string[];
  userColor: string;
}

export const userInfoAtom = atom<UserInfoInterface>({
  key: "userInfo",
  default: {
    type: undefined,
    userId: "",
    userName: "",
    userEmail: "",
    userColor: "",
    role: "",
    tenantName: null,
    tenants: [],
  } as UserInfoInterface,
});
