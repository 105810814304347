export interface User {
  userId: string;
  projectId: string;
  name: string;
  email: string;
  role: string;
  userColor: string;
  creationTime: string;
}

export interface Participant {
  missionId: string;
  userId: string;
  timestamps: {joinTime: string; leaveTime: string}[]
}

export const userColors = [
  "#1A73E8",
  "#12B5CB",
  "#079C98",
  "#E8710A",
  "#A8A116",
  "#F9AB00",
  "#9334E6",
  "#80868B",
  "#079C98",
  "#A8A116",
  "#EA4335",
  "#FF8168",
];
