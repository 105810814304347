import React, { useEffect, useRef, useState } from "react";
import { Button } from "reactstrap";
import {
  BuildingPlan,
  FlightPlanType,
  Mission,
  Project,
} from "../../types/project";
import "./styles.scss";
import { ControlMode, ProjectEditor } from "./slam-lib/project-editor";
import { Marker } from "../../types/marker";
import { differenceBy, differenceWith, isEqual, uniqueId } from "lodash";

export interface Coords {
  x: number;
  y: number;
}

export interface MarkerEvent {
  eventType: string;
  itemType: string;
  itemId: string;
  item: object;
  coordinates: Coords[];
}

interface Props {
  project?: Project;
  buildingPlan?: BuildingPlan;
  flightPlan?: FlightPlanType;
  clearFlightPath?: boolean;
  setClearFlightPath?: (val: any) => void;
  mission?: Mission;
  markers?: Marker[];
  showGui?: boolean;
  controlMode?: symbol;
  availableModes?: symbol[];
  selectedMarkerId?: string;
  labelZoomMode?: "Zoomable" | "Fixed";
  userColor?: string;
  onMarkerEvent?: (eventDetails: MarkerEvent) => void;
  onControlModeChange?: (mode: symbol) => void;
  UAVPosition?: any;
  laserScan?: any;
  droneVisible?: boolean;
}

const SLAM: React.FC<Props> = ({
  controlMode = ControlMode.Orbit,
  availableModes,
  project,
  buildingPlan,
  flightPlan,
  clearFlightPath,
  setClearFlightPath,
  mission,
  markers = [],
  showGui = true,
  selectedMarkerId,
  labelZoomMode = "Zoomable",
  userColor,
  onMarkerEvent,
  onControlModeChange,
  UAVPosition,
  laserScan,
  droneVisible,
}) => {
  const [containerId] = useState(uniqueId("slamContainer"));
  const containerRef = useRef(null);
  const guiRef = useRef(null);
  const prevMarkers = useRef<Marker[]>([]);

  const [projectEditor, setProjectEditor] = useState<ProjectEditor>();
  const [cheatsheetOpen, setCheatsheetOpen] = useState(false);

  useEffect(() => {
    return () => {
      if (projectEditor) {
        projectEditor.destroy();
      }
    };
  }, [projectEditor]);

  useEffect(() => {
    if (project) {
      const container = document.getElementById(containerId);
      const viewControlsContainer = document.getElementById(
        "viewControlsContainer"
      );

      if (!container) {
        console.error(
          "[SLAM component] No container found when initializing SLAM module."
        );
        return;
      }

      const pe = new ProjectEditor(
        container,
        viewControlsContainer,
        availableModes,
        labelZoomMode
      );

      pe.loadProject(project);
      setProjectEditor(pe);
    }
  }, [project, availableModes, containerId, labelZoomMode]);

  useEffect(() => {
    projectEditor?.setDroneVisible(droneVisible);
  }, [droneVisible, projectEditor]);

  useEffect(() => {
    projectEditor?.setControlMode(controlMode);
  }, [controlMode, projectEditor]);

  useEffect(() => {
    if (projectEditor && buildingPlan) {
      projectEditor.loadBuildingPlan(buildingPlan);
    }
  }, [projectEditor, buildingPlan]);

  useEffect(() => {
    if (projectEditor && flightPlan && buildingPlan) {
      if (clearFlightPath) {
        projectEditor.clearFlightPlan();
        // @ts-ignore
        projectEditor.selectedElement.coordinates = [];
        // @ts-ignore
        setClearFlightPath(false);
      }
    }
  }, [
    projectEditor,
    flightPlan,
    buildingPlan,
    clearFlightPath,
    setClearFlightPath,
  ]);

  useEffect(() => {
    if (projectEditor && flightPlan && buildingPlan)
      projectEditor.loadFlightPlan(flightPlan);
  }, [buildingPlan, flightPlan, projectEditor]);

  useEffect(() => {
    if (projectEditor && mission && buildingPlan) {
      projectEditor.loadMission(mission);
    }
  }, [projectEditor, mission, buildingPlan]);

  useEffect(() => {
    if (projectEditor && markers) {
      if (prevMarkers.current.length === 0) {
        projectEditor.loadMarkers(markers);
      } else {
        const removed = differenceBy(prevMarkers.current, markers, "tmpId");
        const added = differenceBy(markers, prevMarkers.current, "tmpId");
        const moved = differenceWith(
          markers,
          prevMarkers.current,
          (marker, prev) =>
            !prev ||
            !marker ||
            (marker.tmpId === prev.tmpId &&
              isEqual(marker.coordinates, prev.coordinates) &&
              isEqual(marker.measurementVector, prev.measurementVector))
        );

        added.forEach((m) => projectEditor.addMarker(m));
        moved.forEach((m) => projectEditor.moveMarker(m));
        removed.forEach((m) => projectEditor.removeMarker(m));
      }
      prevMarkers.current = markers;
    }
  }, [projectEditor, markers]);

  useEffect(() => {
    if (onMarkerEvent) {
      projectEditor?.setItemEventHandler(onMarkerEvent);
    }
  }, [onMarkerEvent, projectEditor]);

  useEffect(() => {
    if (onControlModeChange) {
      projectEditor?.setModeChangeHandler(onControlModeChange);
    }
  }, [onControlModeChange, projectEditor]);

  useEffect(() => {
    if (selectedMarkerId) {
      projectEditor?.setSelectedItem(selectedMarkerId);
    }
  }, [projectEditor, selectedMarkerId]);

  useEffect(() => {
    if (userColor) {
      projectEditor?.setUserColor(userColor);
    }
  }, [projectEditor, userColor]);

  useEffect(() => {
    if (laserScan !== undefined) {
      projectEditor?.feedLaserScanSet(UAVPosition.laserScan);
      projectEditor?.feedLocalPosTrack(UAVPosition.position);
    }
  }, [
    UAVPosition,
    laserScan,
    mission?.missionId,
    project,
    projectEditor,
    projectEditor?.project.laserScanSet,
  ]);

  const toggleViewControls = () => {
    if (projectEditor) {
      projectEditor.gui.show(projectEditor.gui._hidden);
    }
  };

  const toggleCheatSheet = () => {
    setCheatsheetOpen(!cheatsheetOpen);
  };

  const editableFlight = availableModes?.includes(ControlMode.EditFlightPlan);

  return (
    <div className="slamContainer" id={containerId} ref={containerRef}>
      {showGui && (
        <>
          <Button
            size="sm"
            className="btn-link btn-icon cheatsheet-button"
            onClick={toggleCheatSheet}
          >
            <i className="fa fa-keyboard" />
          </Button>
          <Button
            size="sm"
            className="btn-link btn-icon view-controls-button"
            onClick={toggleViewControls}
          >
            <i className="fa fa-cog" />
          </Button>
          <div
            className="view-controls-container"
            id="viewControlsContainer"
            ref={guiRef}
          ></div>
          {cheatsheetOpen && (
            <div className="cheatsheet">
              <div className="title">Keyboard Cheatsheet</div>
              <div className="content">
                <div>F - toggle drone symbol </div>
                <div>H - toggle building mesh (in 3D view)</div>
                {editableFlight && <div>C - clear flight path</div>}
                <div>X - delete node or marker</div>
                <div>Ctrl+Z - undo</div>
                <div>Ctrl+Y - redo</div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default SLAM;
