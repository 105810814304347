import React from "react";
import { Button, Form, Modal, ModalBody, ModalFooter } from "reactstrap";
import { User } from "../../../types/user";

interface Props {
  onClose: () => void;
  setErrorMessage: (val: string) => void;
  selectedUsers: User[] | undefined;
}

const MeetingModal: React.FC<Props> = ({
  onClose,
  setErrorMessage,
  selectedUsers,
}) => {
  return (
    <Modal isOpen={true} modalClassName="modal-black">
      <div className="modal-header">
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={onClose}
        >
          <i className="tim-icons icon-simple-remove"></i>
        </button>
        <h5 className="modal-title">Send invitation for selected users</h5>
      </div>
      <ModalBody>
        <Form action="" className="form" method="">
          <div className="card-content">
            {selectedUsers?.map((user) => {
              return (
                <ul className="fa-ul" key={user.userId}>
                  <li key={user.userId}>
                    <i className="tim-icons icon-send pr-3 font-weight-bold" />
                    {user.name} - {user.email}
                  </li>
                </ul>
              );
            })}
          </div>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onClose}>
          Close
        </Button>
        <Button color="primary">
          <i className="tim-icons icon-send pr-2 font-weight-bold" />
          Send invitation
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default MeetingModal;
