import React from "react";
import { IconContext } from "react-icons";
import { BsGrid1X2 } from "react-icons/bs";
import { Col, CustomInput, Row } from "reactstrap";

import "./styles.scss";
import { BiFullscreen } from "react-icons/bi";

interface Props {
  fullScreen: boolean;
  setFullScreen: (val: boolean) => void;
}

const FullScreenSwitcher: React.FC<Props> = ({ fullScreen, setFullScreen }) => {
  return (
    <Row className="fullScreenSwitcherContainer">
      <Col className="text-right">
        <IconContext.Provider value={{ size: "1.5rem" }}>
          <BiFullscreen color="white" />
        </IconContext.Provider>
      </Col>
      <Col className="text-center customSwitch" xs={2}>
        <CustomInput
          type="switch"
          id="switch-full-screen"
          onChange={() => setFullScreen(!fullScreen)}
        />
      </Col>
      <Col className="text-left">
        <IconContext.Provider value={{ size: "1.5rem" }}>
          <BsGrid1X2 style={{ transform: "rotate(90deg)" }} color="white" />
        </IconContext.Provider>
      </Col>
    </Row>
  );
};

export default FullScreenSwitcher;
