import React, { useCallback, useEffect } from "react";
import { useRecoilState } from "recoil";
import { userInfoAtom } from "../../recoil/atoms/userInfo.atom";
import "./styles.scss";
import Select from "react-select";
import { UncontrolledTooltip } from "reactstrap";

const NavbarSelectTenant: React.FC = () => {
  const [userInfo, setUserInfo] = useRecoilState(userInfoAtom);
  const currentTenant = localStorage.getItem("tenantName");

  const handleChangeTenant = useCallback(
    (tenant: string) => {
      localStorage.setItem("tenantName", tenant);
      setUserInfo((userInfo) => ({
        ...userInfo,
        tenantName: tenant,
      }));
    },
    [setUserInfo]
  );

  useEffect(() => {
    if (currentTenant !== null) {
      handleChangeTenant(currentTenant);
    } else {
      handleChangeTenant(userInfo.tenants[0]);
    }
  }, [currentTenant, handleChangeTenant, setUserInfo, userInfo.tenants]);

  const options: any = userInfo.tenants.map((tenant) => {
    return {
      value: tenant,
      label: tenant,
    };
  });

  return (
    <>
      <Select
        id="selectTenant"
        className="react-select primary select-customization"
        classNamePrefix="react-select"
        name="tenantSelect"
        value={
          currentTenant
            ? { value: currentTenant, label: currentTenant }
            : undefined
        }
        onChange={(event: any) => handleChangeTenant(event.value)}
        options={options}
        placeholder="Tenant..."
      />
      <UncontrolledTooltip placement="left" target="selectTenant" delay={0}>
        Selected tenant
      </UncontrolledTooltip>
    </>
  );
};

export default NavbarSelectTenant;
