import React, { useCallback, useEffect, useState } from "react";
import "./styles.scss";
import ProjectsList from "../../components/ProjectsList/ProjectsList";
import { CreateNewProject, Project } from "../../types/project";
import CustomSidebar from "../../components/CustomSidebar/CustomSidebar";
import { Button, Container, Row } from "reactstrap";
import { useRecoilState, useRecoilValue } from "recoil";
import { userInfoAtom } from "../../recoil/atoms/userInfo.atom";
import AddNewProjectModal from "../../components/Modals/AddNewProjectModal/AddNewProjectModal";
import { selectedProjectAtom } from "../../recoil/atoms/selectedProject.atom";
import Swal from "sweetalert2";
import { securedApi } from "../../api";
import CustomSpinner from "../../components/CustomSpinner/CustomSpinner";

interface IAddNewProjectModal {
  name: "AddNewProject";
}

type VisibleModal = IAddNewProjectModal | undefined;

interface Props {
  signOut?: any;
  user?: any;
}

const Dashboard: React.FC<Props> = ({ signOut, user }) => {
  const [projects, setProjects] = useState<Project[]>();
  const [userInfo, setUserInfo] = useRecoilState(userInfoAtom);
  const [visibleModal, setVisibleModal] = useState<VisibleModal>();
  const [, setErrorMessage] = useState<string>("");
  const selectedProject = useRecoilValue(selectedProjectAtom);

  const handleModalClose = useCallback(() => {
    setVisibleModal(undefined);
  }, []);

  const fetchData = useCallback(() => {
    securedApi
      .get("/projects")
      .then((res) => {
        setProjects(res.data.projects);
      })
      .catch((error) => console.error(error));
  }, []);

  const postData = useCallback(
    (obj: CreateNewProject) => {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        customClass: "toast-custom-class",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      securedApi
        .post("/projects", obj)
        .then((res) => {
          Toast.fire({
            icon: "success",
            title: "Created new project!",
          });
          handleModalClose();
          fetchData();
        })
        .catch((error) => console.error(error));
    },
    [fetchData, handleModalClose]
  );

  useEffect(() => {
    const currentTenant = localStorage.getItem("tenantName");
    if (currentTenant !== null) {
      setUserInfo((userInfo) => ({
        ...userInfo,
        tenantName: currentTenant,
      }));
    }
  }, [setUserInfo, userInfo.userId]);

  useEffect(() => {
    fetchData();
  }, [selectedProject, fetchData]);

  const handleModalOpen = useCallback((modalType: any) => {
    setVisibleModal({ name: modalType });
  }, []);

  const renderModals = useCallback(() => {
    if (visibleModal !== undefined) {
      switch (visibleModal.name) {
        case "AddNewProject":
          return (
            <AddNewProjectModal
              onClose={handleModalClose}
              postData={postData}
              setErrorMessage={setErrorMessage}
            />
          );
      }
    }
  }, [handleModalClose, visibleModal, postData]);

  return (
    <div className="dashboardContainer">
      {renderModals()}
      {/*TODO Validation: Users from magic link don't have tenants at all*/}
      {/*{userInfo.tenants.length !== 0 ? (*/}
      <>
        <CustomSidebar />
        <Container className="my-4">
          <Row className="justify-content-center">
            <Button onClick={() => handleModalOpen("AddNewProject")}>
              Add new project{" "}
              <i className="tim-icons icon-simple-add pl-2 pb-1 font-weight-bold" />
            </Button>
          </Row>
        </Container>
        <ProjectsList projects={projects || []} />
        {projects === undefined ? (
          <div
            className="d-flex w-100 justify-content-center align-items-center"
            style={{ height: "600px" }}
          >
            <CustomSpinner />
          </div>
        ) : null}
      </>
      {/*) : (*/}
      {/*  <Container className="d-flex justify-content-center pt-4">*/}
      {/*    <h2>*/}
      {/*      You are not assigned to any organization. Please contact with Admin.*/}
      {/*    </h2>*/}
      {/*  </Container>*/}
      {/*)}*/}
    </div>
  );
};

export default Dashboard;
