import { object, string } from "zod";

const schema = object({
  name: string({ required_error: "Name is required." })
    .min(3, "Name is too short.")
    .max(50, "Name is too long."),
  address: string()
    .min(3, "It is not correct address.")
    .max(100, "Address is too long."),
});

export default schema;
