import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Card, CardBody, Col } from "reactstrap";
import { useRecoilValue } from "recoil";

import CustomSidebar from "../../components/CustomSidebar/CustomSidebar";
import SLAM from "../../components/SLAM/SLAM";
import {
  BuildingPlan,
  FlightPlanType,
  Mission,
  Project,
} from "../../types/project";
import { ControlMode } from "../../components/SLAM/slam-lib/project-editor";
import AnalysisToolbar from "../../components/AnalysisToolbar/AnalysisToolbar";
import ReportPreview from "../../components/ReportPreview/ReportPreview";
import { userInfoAtom } from "../../recoil/atoms/userInfo.atom";
import { securedApi } from "../../api";
import useWebsockets from "../../api/websockets";
import { User } from "../../types/user";
import useMarkerComms from "../../utils/useMarkerComms";
import { Marker } from "../../types/marker";
import { userRoles } from "../../utils/roles";
import "./styles.scss";
import CustomSpinner from "../../components/CustomSpinner/CustomSpinner";
import YAML from "js-yaml";
import axios from "axios";
import BZip2 from "bzip2-wasm";

export type AnalysisParams = {
  projectId: string;
  buildingPlanId: string;
  flightPlanId: string;
  missionId: string;
};

export type AnalysisScreen = "building-plan" | "camera";

const availableControlModes = [
  ControlMode.EditMeasure,
  ControlMode.EditPinMarker,
  ControlMode.Orbit,
];

interface Props {
  user?: any;
}

const MODELS_PATH = "/assets/models";

const FullScreenWrapper = ({ condition, wrapper, children }: any) =>
  condition ? wrapper(children) : children;

const Analysis: React.FC<Props> = () => {
  const { projectId, buildingPlanId, flightPlanId, missionId } =
    useParams<AnalysisParams>();
  const userInfo = useRecoilValue(userInfoAtom);

  const [project, setProject] = useState<Project>();
  const [buildingPlan, setBuildingPlan] = useState<BuildingPlan>();
  const [flightPlan, setFlightPlan] = useState<FlightPlanType>();
  const [mission, setMission] = useState<Mission>();
  const [allUsers, setAllUsers] = useState<User[]>([]);

  const [controlMode, setControlMode] = useState<symbol>(ControlMode.Orbit);
  const [mainScreen, setMainScreen] = useState<AnalysisScreen>("building-plan");
  const [selectedMarkerId, setSelectedMarkerId] = useState<
    string | undefined
  >();
  const [fullScreen, setFullScreen] = useState<boolean>(true);

  const [reportModalOpen, setReportModalOpen] = useState(false);
  const [occGrid, setOccGrid] = useState<any>("");

  const {
    markers,
    undo,
    redo,
    onMarkerMessage,
    onMarkerEvent,
    onMarkerUpdate,
    onMarkerDelete,
    toggleAcceptedComment,
  } = useMarkerComms({
    projectId,
    buildingPlanId,
    flightPlanId,
    missionId,
    selectedMarkerId,
    setSelectedMarkerId,
    users: allUsers,
  });

  const { users: connectedUsers } = useWebsockets({
    userId: userInfo.userId,
    missionId: mission?.missionId,
    connectionType: "mission",
    onMessage: onMarkerMessage,
  });

  useEffect(() => {
    const fetchUsers = () => {
      securedApi
        .get(`/projects/${projectId}/users?type=all`)
        .then((res) => {
          setAllUsers(res.data.users);
        })
        .catch((error) => console.error(error));
    };

    const fetchProject = () => {
      securedApi
        .get(`/projects/${projectId}`)
        .then((res) => {
          setProject(res.data);
        })
        .catch((error) => console.error(error));
    };

    const fetchBuildingPlan = () => {
      securedApi
        .get(`/projects/${projectId}/buildingPlans/${buildingPlanId}`)
        .then((res) => {
          setBuildingPlan({
            name: res.data.name,
            buildingPlanId: res.data.buildingPlanId,
            projectId: res.data.projectId,
            created: res.data.creationTime,
            mapFileLocation: res.data.mapFileLocation,
            description: res.data.description,
            buildingGeometry: {
              /// TODO should come from backend
              meshUrl: `${MODELS_PATH}/biuro_base1.obj`,
              outlineUrl: res.data.mapFileLocation,
              xOffset: 0,
              yOffset: 0,
              rotation: 0,
            },
          });
        })
        .catch((error) => console.error(error));
    };

    const fetchFlightPlan = () => {
      securedApi
        .get(
          `/projects/${projectId}/buildingPlans/${buildingPlanId}/flightPlans`
        )
        .then((res) => {
          if (res.data.flightPlans?.length === 0) {
            console.warn("No flight plans returned from backend!");
          } else {
            setFlightPlan({
              id: res.data.flightPlans[0]?.flightPlanId,
              coordinates: res.data.flightPlans[0]?.data || [],
            });
          }
        })
        .catch((error) => console.error(error));
    };

    const fetchMission = () => {
      securedApi
        .get(
          `/projects/${projectId}/buildingPlans/${buildingPlanId}/flightPlans/${flightPlanId}/missions/${missionId}`
        )
        .then((res) => {
          setMission({
            ...res.data,
            missionId: res.data.missionId,
            occGrid: {
              url: occGrid,
              xOffset: -2.1,
              yOffset: 1.6,
              rotation: 0,
              visible: true,
            },
          });
        })
        .catch((e) => console.error(e));
    };

    if (projectId && buildingPlanId && flightPlanId && missionId && occGrid) {
      fetchUsers();
      fetchProject();
      fetchBuildingPlan();
      fetchFlightPlan();
      fetchMission();
    }
  }, [projectId, buildingPlanId, flightPlanId, missionId, occGrid]);

  useEffect(() => {
    const fetchData = async () => {
      if (missionId && buildingPlanId && projectId) {
        try {
          const getOccGridUrl = await securedApi.get(
            `/projects/${projectId}/buildingPlans/${buildingPlanId}/flightPlans/${flightPlanId}/missions/${missionId}/occupancyGrid`
          );

          const occGrid = await axios.get(getOccGridUrl.data.url, {
            responseType: "arraybuffer",
          });

          const bzip2 = new BZip2();
          await bzip2.init();

          const decompressed = bzip2.decompress(
            new Uint8Array(occGrid.data),
            100000000
          );

          const decompressedStr = new TextDecoder().decode(decompressed);
          const yamlData = YAML.load(decompressedStr);
          setOccGrid(yamlData);
        } catch (error) {
          console.error("An error occurred while loading the data:", error);
        }
      }
    };

    fetchData();
  }, [buildingPlanId, flightPlanId, missionId, projectId]);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "x" && selectedMarkerId) {
        const selectedMarker = markers.find(
          (m: Marker) => m.tmpId === selectedMarkerId
        );
        if (selectedMarker && selectedMarker.userId === userInfo.userId) {
          onMarkerDelete(selectedMarkerId);
        }
      } else if (event.key === "z" && event.ctrlKey) {
        undo();
      } else if (event.key === "y" && event.ctrlKey) {
        redo();
      }
    },
    [onMarkerDelete, selectedMarkerId, undo, redo, markers, userInfo]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown, false);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  const onReportModalOpen = useCallback(() => setReportModalOpen(true), []);
  const onReportModalClose = useCallback(() => setReportModalOpen(false), []);

  const title = `${project?.name} - ${buildingPlan?.name} - mission ${missionId}`;
  const userColor = userInfo
    ? userRoles.find((role) => role.name === userInfo.role)?.color
    : undefined;

  const fileUrl = mission?.gridDXFUrl;
  const downloadFile = (fileUrl: string) => {
    fetch(fileUrl)
      .then((res) => res.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `OccupancyGrid-${project?.name}-${buildingPlan?.name}-${mission?.missionId}`;
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) =>
        console.error("Error during file downloading: ", error)
      );
  };

  const handleDownload = () => {
    if (fileUrl) downloadFile(fileUrl);
  };

  return (
    <div className="analysisView">
      <CustomSidebar isExpanded={false} />
      <div className="flightContainer">
        <div className="title-bar">
          <h4 className="text-truncate" title={title}>
            {title}
          </h4>
        </div>
        {buildingPlan ? (
          <div className="main-controls">
            <FullScreenWrapper
              condition={fullScreen}
              wrapper={(children: any) => (
                <div className="fullscreen-container">{children}</div>
              )}
            >
              <Col md="12" className="large-container">
                {mainScreen === "building-plan" ? (
                  <Card className="map-container">
                    <SLAM
                      controlMode={controlMode}
                      availableModes={availableControlModes}
                      project={project}
                      buildingPlan={buildingPlan}
                      flightPlan={flightPlan}
                      mission={mission}
                      markers={markers}
                      selectedMarkerId={selectedMarkerId}
                      userColor={userColor}
                      onMarkerEvent={onMarkerEvent}
                      onControlModeChange={setControlMode}
                      droneVisible={false}
                    />
                  </Card>
                ) : (
                  <Card className="small-container placeholder">
                    <CardBody className="centered">camera view</CardBody>
                  </Card>
                )}
              </Col>
            </FullScreenWrapper>
            {fullScreen ? null : (
              <div className="horizontal-panels">
                <Col md="6">
                  {mainScreen === "camera" ? (
                    <Card className="centered small-map-container ">
                      <SLAM
                        controlMode={controlMode}
                        availableModes={availableControlModes}
                        project={project}
                        buildingPlan={buildingPlan}
                        flightPlan={flightPlan}
                        mission={mission}
                        markers={markers}
                        selectedMarkerId={selectedMarkerId}
                        userColor={userColor}
                        onMarkerEvent={onMarkerEvent}
                        onControlModeChange={setControlMode}
                        droneVisible={false}
                      />
                    </Card>
                  ) : (
                    <Card className="small-container placeholder">
                      <CardBody className="centered">camera view</CardBody>
                    </Card>
                  )}
                </Col>
                <Col md="6">
                  <Card className="control-panel placeholder">
                    <CardBody className="centered">controls</CardBody>
                  </Card>
                </Col>
              </div>
            )}
          </div>
        ) : (
          <div style={{ height: "100%" }}>
            <CustomSpinner />
          </div>
        )}
      </div>

      <AnalysisToolbar
        downloadDXF={handleDownload}
        userInfo={userInfo}
        allUsers={allUsers}
        connectedUsers={connectedUsers}
        markers={markers}
        mainScreen={mainScreen}
        setMainScreen={setMainScreen}
        fullScreen={fullScreen}
        setFullScreen={setFullScreen}
        controlMode={controlMode}
        setControlMode={setControlMode}
        selectedMarkerId={selectedMarkerId}
        onItemSelected={setSelectedMarkerId}
        onMarkerUpdate={onMarkerUpdate}
        onMarkerDelete={onMarkerDelete}
        toggleAccepted={toggleAcceptedComment}
        onGenerateReport={onReportModalOpen}
      />

      {reportModalOpen && (
        <ReportPreview
          project={project}
          buildingPlan={buildingPlan}
          flightPlan={flightPlan}
          mission={mission}
          markers={markers}
          userInfo={userInfo}
          allUsers={allUsers}
          onClose={onReportModalClose}
        />
      )}
    </div>
  );
};

export default Analysis;
