import React from "react";
import "./styles.scss";
import CustomSpinner from "../../components/CustomSpinner/CustomSpinner";

const LoaderPage: React.FC = () => {
  return (
    <div
      className="d-flex w-100 justify-content-center align-items-center loader-page"
      style={{ flex: 1 }}
    >
      <CustomSpinner />
    </div>
  );
};

export default LoaderPage;
