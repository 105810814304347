import * as THREE from "three";
import { GUI } from "three/addons/libs/lil-gui.module.min.js";
import { OrbitControls } from "three/addons/controls/OrbitControls.js";
import _ from "lodash";

import * as PARAMS from "./params.js";
import { BuildingPlan } from "./components/building-plan.js";
import { Drone } from "./components/drone.js";
import { Measure } from "./components/measure.js";
import { FlightPlan } from "./components/flight-plan.js";
import { OccGrid } from "./components/occ-grid.js";
import { PinMarker } from "./components/pin-marker.js";
import { OdometrySet } from "./components/odometry-set.js";
import { LocalPosTrack } from "./components/local-pos-track.js";
import { LaserScanSet } from "./components/laser-scan-set.js";
import { LabelZoomMode } from "./components/label.js";
import { LaserScan } from "./components/model/laser-scan";
import { LocalPos } from "./components/model/local-pos";
//import { LaserScanSetEx } from './components/laser-scan-set-ex.js';

export const ControlMode = Object.freeze({
  Orbit: Symbol("orbit"),
  EditFlightPlan: Symbol("editFlightPlan"),
  EditMeasure: Symbol("editMeasure"),
  EditPinMarker: Symbol("editPinMarker"),
});

export const CameraType = Object.freeze({
  Perspective: Symbol("perspective"),
  Orthographic: Symbol("orthographic"),
  Orthographic2D: Symbol("orthographic2D"),
});

const defaultModes = Object.keys(ControlMode);

const DDE_ROOT = "/dde/";
let DDE_PATH = "";

export class ProjectEditor {
  gui;
  #container;
  #guiContainer;
  #animationRequest = null;

  #perspCam;
  #orthoCam;
  #orthoCam2D;

  #orbitControlsPersp;
  #orbitControlsOrtho;
  #orbitControlsOrtho2D;
  #orbitWhenDraw = false;

  #hemiLight;
  #camLight;
  #dirLights = [];

  #projectContext = {
    scene: null,
    camera: null,
    renderer: null,
    drawGrid: null,
    rayCaster: null,
    localPosTrack: null,
    laserScanSet: null,
    odometrySet: null,
    drone: null,
    buildingPlan: null,
  };

  #handleItemEvent = () => {};
  #handleModeChange = () => {};

  #guiState = {
    controlMode: ControlMode.Orbit,
    availableModes: defaultModes,
    cameraType: CameraType.Orthographic2D,
    lightsShadows: true,
    dirLightsPos: 20,
    modelRot: 0,
    modelRotPrec: 0,
    modelOffsetX: 0,
    modelOffsetY: 0,
    occRot: 0.0,
    occRotPrec: 0.0,
    occXOffset: 0.0,
    occYOffset: 0.0,
    droneVisible: true,
    buildingMeshVisible: true,
    labelZoomMode: LabelZoomMode.Fixed,
    labelTextSize: 0.25,
  };

  data = {
    project: null,
    buildingPlan: null,
    flightPlan: null,
    occGrid: null,
    measurements: [],
    pinMarkers: [],
  };

  #buildingPlan = null;
  #flightPlan = null;
  #occGrid = null;
  #measurements = [];
  #pinMarkers = [];

  #occGrids = [];
  #gridIdx = 0;
  #gridRotation = 0.0;
  #gridXOffset = 0.0;
  #gridYOffset = 0.0;

  selectedElement = null;
  userColor = null;

  constructor(container, guiContainer, availableModes, labelZoomMode) {
    this.onKeyUp = this.onKeyUp.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onWindowResize = this.onWindowResize.bind(this);

    this.#container = container;
    this.#guiContainer = guiContainer;
    this.#init();
    this.#animate();

    if (availableModes) {
      this.#guiState.availableModes = availableModes;
    }

    if (labelZoomMode) {
      this.#guiState.labelZoomMode = labelZoomMode;
    }
    this.#projectContext.laserScanSet = new LaserScanSet(this.#projectContext);
    this.#projectContext.localPosTrack = new LocalPosTrack(
      this.#projectContext
    );
    // this.#projectContext.buildingPlan = new BuildingPlan(this.#projectContext)
  }

  destroy() {
    cancelAnimationFrame(this.#animationRequest);
    this.#measurements.forEach((m) => m.destroy());
    this.#pinMarkers.forEach((m) => m.destroy());
    this.#destroyRenderer();
  }

  setItemEventHandler(fn) {
    if (typeof fn === "function") {
      this.#handleItemEvent = fn;
    }
  }

  setModeChangeHandler(fn) {
    if (typeof fn === "function") {
      this.#handleModeChange = fn;
    }
  }

  loadProject(project) {
    this.#init();
    this.project = project;
  }

  loadBuildingPlan(buildingPlan) {
    this.data.buildingPlan = buildingPlan;
    const bg = buildingPlan.buildingGeometry;

    this.#buildingPlan = new BuildingPlan(this.#projectContext);
    this.#buildingPlan.load(bg.outlineUrl, bg.meshUrl, bg.rotation, (b) => {
      this.#setupCameras(b.boundingBox);
    });

    this.#projectContext.buildingPlan = this.#buildingPlan;

    // this.#guiState.modelRot = bg.rotation; //TEMP to be removed
    this.#onStateChanged();
  }

  loadFlightPlan(flightPlan) {
    this.data.flightPlan = flightPlan;
    this.#flightPlan = new FlightPlan(this.#projectContext);
    this.#flightPlan.setData(flightPlan);
    this.selectedElement = this.#flightPlan;
    this.#onStateChanged();
  }

  clearFlightPlan() {
    this.#flightPlan.clear();
  }

  loadMission(mission) {
    this.data.mission = mission;
    const bg = this.data.buildingPlan.buildingGeometry;

    const og = new OccGrid(this.#projectContext);
    og.load(mission.occGrid.url);
    og.setOutline(this.#buildingPlan.outline, bg.rotation);
    og.xOffset = mission.occGrid.xOffset;
    og.yOffset = mission.occGrid.yOffset;
    og.rotation = mission.occGrid.rotation;
    og.visible = mission.occGrid.visible;
    og.colorized = mission.occGrid.colorized;
    this.#occGrid = og;

    this.#onStateChanged();
  }

  loadMarkers(markers) {
    this.removeMarkers();
    markers.forEach((marker) => this.addMarker(marker));
  }

  markerExistsById(id) {
    return (
      this.#pinMarkers.find((m) => m.id === id) ||
      this.#measurements.find((m) => m.id === id)
    );
  }

  addMarker(marker) {
    if (this.markerExistsById(marker.tmpId)) {
      return;
    }

    if (marker.measurementVector) {
      const m = new Measure(
        this.#projectContext,
        marker.color,
        marker.editable
      );
      m.setData({
        id: marker.tmpId,
        coordinates: marker.measurementVector,
      });
      if (marker.label) {
        m.addLabel(marker.label, this.#guiState.labelTextSize);
        m.setLabelZoomMode(this.#guiState.labelZoomMode);
      }
      this.#measurements.push(m);
    } else {
      const m = new PinMarker(
        this.#projectContext,
        marker.color,
        marker.editable
      );
      m.setData({
        id: marker.tmpId,
        coordinates: marker.coordinates,
      });
      if (marker.label) {
        m.addLabel(marker.label, this.#guiState.labelTextSize);
        m.setLabelZoomMode(this.#guiState.labelZoomMode);
      }
      this.#pinMarkers.push(m);
    }
  }

  moveMarker(marker) {
    const pinMarker = this.#pinMarkers.find((m) => m.id === marker.tmpId);
    if (pinMarker) {
      pinMarker.setCoordinates(marker.coordinates);
      return;
    }

    const measurement = this.#measurements.find((m) => m.id === marker.tmpId);
    if (measurement) {
      measurement.setCoordinates(marker.measurementVector);
      return;
    }

    console.warn("Attempted to update position of inexistent marker", marker);
  }

  removeMarker(marker) {
    const id = marker.tmpId;
    if (this.selectedElement?.id === id) {
      this.selectedElement = null;
    }
    this.#pinMarkers = this.#pinMarkers.filter((m) => {
      if (m.id === id) {
        m.destroy();
        return false;
      }
      return true;
    });
    this.#measurements = this.#measurements.filter((m) => {
      if (m.id === id) {
        m.destroy();
        return false;
      }
      return true;
    });
  }

  removeMarkers() {
    this.#pinMarkers.forEach((m) => m.destroy());
    this.#measurements.forEach((m) => m.destroy());
    this.#pinMarkers = [];
    this.#measurements = [];
  }

  setSelectedItem(id) {
    const measure = this.#measurements.find((m) => m.id === id);
    if (measure) {
      this.#clearSelecionExcept(measure);
      this.selectedElement = measure;
      measure.select();
      this.#onStateChanged();
      return;
    }

    const pinMarker = this.#pinMarkers.find((m) => m.id === id);
    if (pinMarker) {
      this.#clearSelecionExcept(pinMarker);
      this.selectedElement = pinMarker;
      pinMarker.select();
      this.#onStateChanged();
      return;
    }
  }

  setUserColor(color) {
    this.userColor = color;
  }

  setDroneVisible(value) {
    this.#guiState.droneVisible = value;
  }

  //
  // Toggle given occupancy grid visibility.
  //
  setOccGridVisible(visible) {
    this.#occGrid.visible = visible;
    this.data.mission.occGrid.visible = visible;
    this.#onStateChanged();
  }

  //
  // Toggle given occupancy grid distance colorization.
  //
  setOccGridColorization(colorized) {
    this.#occGrid.colorized = colorized;
    this.data.mission.occGrid.colorized = colorized;
    this.#onStateChanged();
  }

  createFlightPlan() {
    this.#flightPlan = new FlightPlan(this.#projectContext);
    this.#flightPlan.id = _.uniqueId("flight-plan-" + Date.now());
    this.selectedElement = this.#flightPlan;
    this.data.flightPlan = this.#flightPlan.getData();
    this.#onStateChanged();
  }

  selectFlightPlan(flightPlan) {
    if (flightPlan) {
      this.selectedElement = flightPlan;
      this.#onStateChanged();
    }
  }

  createMeasure() {
    const measure = new Measure(this.#projectContext, this.userColor, true);
    measure.id = _.uniqueId("measure-" + Date.now());
    this.selectedElement = measure;
    this.#measurements.push(measure);
    this.data.measurements.push(measure.getData());
    this.#onStateChanged();
  }

  selectMeasure(measure) {
    if (measure) {
      this.selectedElement = measure;
      this.#onStateChanged();
    }
  }

  createPinMarker() {
    const pinMarker = new PinMarker(this.#projectContext, this.userColor, true);
    pinMarker.id = _.uniqueId("pin-marker-" + Date.now());
    this.selectedElement = pinMarker;
    this.#pinMarkers.push(pinMarker);
    this.data.pinMarkers.push(pinMarker.getData());
    this.#onStateChanged();
  }

  selectPinMarker(pinMarker) {
    if (pinMarker) {
      this.selectedElement = pinMarker;
      this.#onStateChanged();
    }
  }

  setControlMode(mode) {
    this.#guiState.controlMode = mode;
    this.#onStateChanged();
  }

  setLabelZoomMode(mode) {
    this.#guiState.labelZoomMode = mode;
    this.#onStateChanged();
  }

  setLabelTextSize(size) {
    this.#guiState.labelTextSize = size;
    this.#onStateChanged();
  }

  setCameraType(type) {
    this.#guiState.cameraType = type;
    this.#onStateChanged();
  }

  #destroyRenderer() {
    if (this.#projectContext.renderer) {
      this.#projectContext.renderer.renderLists.dispose();
      this.#container.removeChild(this.#projectContext.renderer.domElement);
      this.#projectContext.renderer = null;
    }
  }

  #init() {
    this.#destroyRenderer();

    const clientRect = this.#container.getBoundingClientRect();
    const renderer = new THREE.WebGLRenderer({
      antialias: true,
      preserveDrawingBuffer: true,
    });
    this.#container.appendChild(renderer.domElement);
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(clientRect.width, clientRect.height);
    renderer.outputColorSpace = THREE.SRGBColorSpace;
    renderer.shadowMap.enabled = true;
    this.#projectContext.renderer = renderer;

    const rayCaster = new THREE.Raycaster();
    rayCaster.params.Line.threshold = 0.01;
    this.#projectContext.rayCaster = rayCaster;

    // scene
    this.#initScene(this.#projectContext.scene);

    // event handlers
    renderer.domElement.addEventListener("marker-event", (e) =>
      this.#onMarkerEvent(e.detail)
    );
    renderer.domElement.addEventListener("on-time-changed", (e) =>
      this.#onTimeChanged(e.detail)
    );
    renderer.domElement.addEventListener("pointerdown", (e) =>
      this.#onPointerEvent(e)
    );
    renderer.domElement.addEventListener("pointerup", (e) =>
      this.#onPointerEvent(e)
    );
    renderer.domElement.addEventListener("pointercancel", (e) =>
      this.#onPointerEvent(e)
    );
    renderer.domElement.addEventListener("mousedown", (e) =>
      this.#onMouseDown(e)
    );
    renderer.domElement.addEventListener("mouseup", (e) => this.#onMouseUp(e));
    renderer.domElement.addEventListener("mousemove", (e) =>
      this.#onMouseMove(e)
    );
    renderer.domElement.addEventListener("wheel", (e) => this.#onWheel(e));

    document.removeEventListener("keydown", this.onKeyDown, false);
    document.removeEventListener("keyup", this.onKeyUp, false);
    window.removeEventListener("resize", this.onWindowResize);

    document.addEventListener("keydown", this.onKeyDown, false);
    document.addEventListener("keyup", this.onKeyUp, false);
    window.addEventListener("resize", this.onWindowResize);

    this.#initGui(); //TEMP this should be removed in final product
  }

  #setupCameras(buildingBoundingBox) {
    const r = this.#container.getBoundingClientRect();
    this.#perspCam.aspect = r.width / r.height;
    this.#perspCam.updateProjectionMatrix();

    const w = buildingBoundingBox.maxX - buildingBoundingBox.minX;
    const h = buildingBoundingBox.maxY - buildingBoundingBox.minY;
    if (w > h) {
      const aspect = r.height / r.width;
      this.#orthoCam2D.left = buildingBoundingBox.minX;
      this.#orthoCam2D.right = buildingBoundingBox.maxX;
      this.#orthoCam2D.top = buildingBoundingBox.maxX * aspect;
      this.#orthoCam2D.bottom = buildingBoundingBox.minX * aspect;
    } else {
      const aspect = r.width / r.height;
      this.#orthoCam2D.left = buildingBoundingBox.minY * aspect;
      this.#orthoCam2D.right = buildingBoundingBox.maxY * aspect;
      this.#orthoCam2D.top = buildingBoundingBox.maxY;
      this.#orthoCam2D.bottom = buildingBoundingBox.minY;
    }
    this.#orthoCam2D.updateProjectionMatrix();

    this.#orthoCam.left = this.#orthoCam2D.left;
    this.#orthoCam.right = this.#orthoCam2D.right;
    this.#orthoCam.top = this.#orthoCam2D.top;
    this.#orthoCam.bottom = this.#orthoCam2D.bottom;
    this.#orthoCam.updateProjectionMatrix();
  }

  #enableOrbit3DControls(event) {
    const enabled =
      this.#guiState.cameraType !== CameraType.Orthographic2D &&
      ((event && (event.button !== 0 || event.type === "wheel")) ||
        this.#guiState.controlMode === ControlMode.Orbit);
    this.#orbitControlsPersp.enabled = enabled;
    this.#orbitControlsOrtho.enabled = enabled;
  }

  #onPointerEvent(event) {
    this.#enableOrbit3DControls(event);
  }

  #onMouseDown(event) {
    this.#enableOrbit3DControls(event);
    if (event.button === 0) {
      let hit = false;

      // selecting any selectable item
      // if item is under mouse cursor, and left button
      // is pressed, then edit mode is switched to edit mode
      // related with this item
      if (this.#guiState.availableModes.includes(ControlMode.EditMeasure)) {
        this.#measurements?.forEach((measure) => {
          if (!hit && measure.hitTest(event.clientX, event.clientY)) {
            hit = true;
            this.selectMeasure(measure);
            this.#guiState.controlMode = ControlMode.EditMeasure;
            this.#handleModeChange(ControlMode.EditMeasure);
          }
        });
      }
      if (
        !hit &&
        this.#guiState.availableModes.includes(ControlMode.EditPinMarker)
      ) {
        this.#pinMarkers?.forEach((pin) => {
          if (!hit && pin.hitTest(event.clientX, event.clientY)) {
            hit = true;
            this.selectPinMarker(pin);
            this.#guiState.controlMode = ControlMode.EditPinMarker;
            this.#handleModeChange(ControlMode.EditPinMarker);
          }
        });
      }
      if (
        !hit &&
        this.#guiState.availableModes.includes(ControlMode.EditFlightPlan)
      ) {
        if (!hit && this.#flightPlan?.hitTest(event.clientX, event.clientY)) {
          hit = true;
          this.selectFlightPlan(this.#flightPlan);
          this.#guiState.controlMode = ControlMode.EditFlightPlan;
          this.#handleModeChange(ControlMode.EditFlightPlan);
        }
      }
      // end of selecting any selectable item

      switch (this.#guiState.controlMode) {
        case ControlMode.EditFlightPlan:
          if (!hit && !this.#flightPlan) {
            //TODO decide how to handle drawing multistep flight plans vs creating new fp when clicked on empty space
            this.createFlightPlan();
          }
          if (this.selectedElement instanceof FlightPlan) {
            this.selectedElement.onMouseDown(event.clientX, event.clientY);
          }
          break;
        case ControlMode.EditMeasure:
          if (!hit) {
            this.createMeasure();
          }
          if (this.selectedElement instanceof Measure) {
            this.selectedElement.onMouseDown(event.clientX, event.clientY);
          }
          break;
        case ControlMode.EditPinMarker:
          if (!hit) {
            this.createPinMarker();
          }
          if (this.selectedElement instanceof PinMarker) {
            this.selectedElement.onMouseDown(event.clientX, event.clientY);
          }
          break;
        default:
          break;
      }
    }
  }

  #onMouseUp(event) {
    this.#enableOrbit3DControls(event);
    if (event.button === 0) {
      switch (this.#guiState.controlMode) {
        case ControlMode.EditFlightPlan:
          if (this.selectedElement instanceof FlightPlan) {
            this.#flightPlan?.onMouseUp(event.clientX, event.clientY);
          }
          break;
        case ControlMode.EditMeasure:
          if (this.selectedElement instanceof Measure) {
            this.selectedElement.onMouseUp(event.clientX, event.clientY);
          }
          break;
        case ControlMode.EditPinMarker:
          if (this.selectedElement instanceof PinMarker) {
            this.selectedElement.onMouseUp(event.clientX, event.clientY);
          }
          break;
        default:
          break;
      }
    }
  }

  #onMouseMove(event) {
    if (event.button === 0) {
      // this is only for visual feedback, when user hovers over
      // any selectable item, this item should indicate somehow that
      // it is under the cursor, and it can be slected or moved
      this.#measurements?.forEach((measure) => {
        measure.hitTest(event.clientX, event.clientY);
      });
      this.#pinMarkers?.forEach((pin) => {
        pin.hitTest(event.clientX, event.clientY);
      });
      this.#flightPlan?.hitTest(event.clientX, event.clientY);

      // end of visual feedback

      switch (this.#guiState.controlMode) {
        case ControlMode.EditFlightPlan:
          if (this.selectedElement instanceof FlightPlan) {
            this.#flightPlan.onMouseMove(event.clientX, event.clientY);
          }
          break;
        case ControlMode.EditMeasure:
          if (this.selectedElement instanceof Measure) {
            this.selectedElement.onMouseMove(event.clientX, event.clientY);
          }
          break;
        case ControlMode.EditPinMarker:
          if (this.selectedElement instanceof PinMarker) {
            this.selectedElement.onMouseMove(event.clientX, event.clientY);
          }
          break;
        default:
          break;
      }
    }
  }

  #onMarkerEvent = (detail) => {
    const { item, eventType } = detail;

    if (eventType === PARAMS.MARKER_EVENTS.SELECTED) {
      this.#clearSelecionExcept(item);
    }

    this.#handleItemEvent(detail);
  };

  #clearSelecionExcept(exceptionItem) {
    this.#measurements?.forEach((measure) => {
      if (measure !== exceptionItem) {
        measure.deselect();
      }
    });
    this.#pinMarkers?.forEach((pin) => {
      if (pin !== exceptionItem) {
        pin.deselect();
      }
    });
    if (this.#flightPlan !== exceptionItem) {
      this.#flightPlan.deselect();
    }
  }

  #calcOccGridPointSize() {
    if (this.#occGrids) {
      this.#occGrids.forEach((grid) => {
        if (grid) {
          const d = Math.log10(
            this.#perspCam.position.distanceTo({ x: 0, y: 0, z: 0 })
          );
          if (this.#guiState.cameraType === CameraType.Perspective) {
            grid.setPointSize(d / 4.0);
          } else {
            grid.setPointSize(2.0 / d);
          }
        }
      });
    }
  }

  #onWheel(event) {
    this.#enableOrbit3DControls(event);
    this.#calcOccGridPointSize();
  }

  onKeyDown(event) {
    if (event.altKey) {
      if (this.#guiState.controlMode === ControlMode.EditFlightPlan) {
        this.orbitWhenDraw = true;
        this.#guiState.controlMode = ControlMode.Orbit;
        this.#handleModeChange(ControlMode.Orbit);
        this.#onStateChanged();
      }
    }
  }

  onKeyUp(event) {
    if (!event.altKey && this.#orbitWhenDraw) {
      this.#guiState.controlMode = ControlMode.EditFlightPlan;
      this.#handleModeChange(ControlMode.EditFlightPlan);
      this.orbitWhenDraw = false;
    }

    switch (event.key) {
      case "f":
        // toggle drone
        this.#guiState.droneVisible = !this.#guiState.droneVisible;
        this.#projectContext.drone.visible = this.#guiState.droneVisible;
        break;
      case "h":
        this.#guiState.buildingMeshVisible =
          !this.#guiState.buildingMeshVisible;
        break;
      case "c":
        if (this.#guiState.controlMode === ControlMode.EditFlightPlan) {
          this.#flightPlan.clear();
        }
        break;
      case "x":
        if (this.#guiState.controlMode === ControlMode.EditFlightPlan) {
          this.#flightPlan.deleteSelectedNode();
        }
        break;
      default:
        break;
    }

    if (event.ctrlKey) {
      switch (event.key) {
        case "z":
          if (
            this.#guiState.controlMode === ControlMode.EditFlightPlan &&
            this.selectedElement instanceof FlightPlan
          ) {
            this.selectedElement.undo();
          }
          break;
        case "y":
          if (
            this.#guiState.controlMode === ControlMode.EditFlightPlan &&
            this.selectedElement instanceof FlightPlan
          ) {
            this.selectedElement.redo();
          }
          break;
        default:
          break;
      }
    }

    this.#onStateChanged();
  }

  onWindowResize() {
    if (!this.#projectContext.renderer) return;
    const rect = this.#container.getBoundingClientRect();
    this.#projectContext.renderer.setSize(rect.width, rect.height);
  }

  #initScene() {
    if (this.#projectContext.scene) {
      const scene = this.#projectContext.scene;
      scene.remove.apply(scene, scene.children);
    }

    const scene = new THREE.Scene();
    scene.background = new THREE.Color().setHSL(0.6, 0, 1);
    scene.fog = new THREE.Fog(scene.background, 1, 5000);
    this.#projectContext.scene = scene;

    // cameras
    const camY = 15;
    this.#perspCam = new THREE.PerspectiveCamera(
      25,
      window.innerWidth / window.innerHeight,
      1,
      1000
    );
    this.#perspCam.position.set(0, camY, 5);

    this.#orthoCam = new THREE.OrthographicCamera(-1, 1, 1, -1, 1, 1000);
    this.#orthoCam.position.set(0, camY, 5);

    this.#orthoCam2D = new THREE.OrthographicCamera(-1, 1, 1, -1, 1, 1000);
    this.#orthoCam2D.position.set(0, camY, 0);

    this.#projectContext.camera = this.#orthoCam2D;
    scene.add(this.#projectContext.camera);

    // camera controls
    this.#orbitControlsPersp = new OrbitControls(
      this.#perspCam,
      this.#projectContext.renderer.domElement
    );
    this.#orbitControlsPersp.enableDamping = true;
    this.#orbitControlsPersp.update();

    this.#orbitControlsOrtho = new OrbitControls(
      this.#orthoCam,
      this.#projectContext.renderer.domElement
    );
    this.#orbitControlsOrtho.enableDamping = true;
    this.#orbitControlsOrtho.update();

    this.#orbitControlsOrtho2D = new OrbitControls(
      this.#orthoCam2D,
      this.#projectContext.renderer.domElement
    );
    this.#orbitControlsOrtho2D.enableRotate = false;
    this.#orbitControlsOrtho2D.update();

    // draw grid
    this.#projectContext.drawGrid = new THREE.GridHelper(100, 10000);
    this.#projectContext.drawGrid.visible = false;
    scene.add(this.#projectContext.drawGrid);

    // ground
    const groundGeo = new THREE.PlaneGeometry(10000, 10000);
    const groundMat = new THREE.MeshLambertMaterial({ color: 0xffffff });

    const ground = new THREE.Mesh(groundGeo, groundMat);
    ground.position.y = PARAMS.GROUND_Z_POS;
    ground.rotation.x = -Math.PI / 2;
    scene.add(ground);

    // LIGHTS
    this.#hemiLight = new THREE.HemisphereLight(0xffffff, 0xffffff, 0.55);
    this.#hemiLight.color.setRGB(1, 1, 1);
    this.#hemiLight.groundColor.setHSL(0.095, 0.5, 0.5);
    this.#hemiLight.position.set(0, 50, 0);
    scene.add(this.#hemiLight);

    let dirLight = new THREE.DirectionalLight(0xffffff, 0.33);

    this.#camLight = dirLight.clone();
    this.#camLight.intensity = 0.33;
    this.#camLight.position.set(0, 0, 0);
    this.#projectContext.camera.add(this.#camLight);

    const d = 50;

    this.#hemiLight.color.setRGB(1, 1, 1);
    dirLight.castShadow = true;
    dirLight.shadow.mapSize.width = 2048;
    dirLight.shadow.mapSize.height = 2048;
    dirLight.shadow.camera.left = -d;
    dirLight.shadow.camera.right = d;
    dirLight.shadow.camera.top = d;
    dirLight.shadow.camera.bottom = -d;
    dirLight.shadow.camera.far = 350;
    dirLight.shadow.bias = -0.0001;

    this.#dirLights.push(dirLight);
    this.#dirLights.push(dirLight.clone());
    this.#dirLights.push(dirLight.clone());
    this.#dirLights.push(dirLight.clone());

    this.#updateDirLights();

    scene.add(this.#dirLights[0]);
    scene.add(this.#dirLights[1]);
    scene.add(this.#dirLights[2]);
    scene.add(this.#dirLights[3]);

    // SKYDOME
    const vertexShader = `
                    varying vec3 vWorldPosition;
                    void main() {
                        vec4 worldPosition = modelMatrix * vec4( position, 1.0 );
                        vWorldPosition = worldPosition.xyz;
                        gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
                    }`;
    const fragmentShader = `
                    uniform vec3 topColor;
                    uniform vec3 bottomColor;
                    uniform float offset;
                    uniform float exponent;
                    varying vec3 vWorldPosition;
                    void main() {
                        float h = normalize( vWorldPosition + offset ).y;
                        gl_FragColor = vec4( mix( bottomColor, topColor, max( pow( max( h , 0.0), exponent ), 0.0 ) ), 1.0 );
                    }`;
    const uniforms = {
      topColor: { value: new THREE.Color(0x0077ff) },
      bottomColor: { value: new THREE.Color(0xffffff) },
      offset: { value: 33 },
      exponent: { value: 0.6 },
    };
    uniforms["topColor"].value.copy(this.#hemiLight.color);

    scene.fog.color.copy(uniforms["bottomColor"].value);

    const skyGeo = new THREE.SphereGeometry(4000, 32, 15);
    const skyMat = new THREE.ShaderMaterial({
      uniforms: uniforms,
      vertexShader: vertexShader,
      fragmentShader: fragmentShader,
      side: THREE.BackSide,
    });

    scene.add(new THREE.Mesh(skyGeo, skyMat));

    this.#projectContext.drone = new Drone(this.#projectContext);
  }

  #initGui() {
    //TEMP this is only temporary solution, and should be replaced by proper GUI in final product

    const gui = new GUI({
      container: this.#guiContainer,
      autoPlace: false,
      title: "View Controls",
    });
    this.gui = gui;
    gui.hide();
    gui.domElement.id = "lilgui";
    gui.onChange(() => this.#onStateChanged());

    const guiLights = gui.addFolder("Lights");
    guiLights.close();

    const guiHemiLight = guiLights.addFolder("Hemisphere Light");
    guiHemiLight.add(this.#hemiLight, "visible").name("on");
    guiHemiLight.add(this.#hemiLight, "intensity", 0, 1).step(0.01);

    const guiDirLight = guiLights.addFolder("Directional Lights");
    this.#dirLights.forEach((_, n) => {
      guiDirLight.add(this.#dirLights[n], "visible").name("light " + (n + 1));
      guiDirLight.add(this.#dirLights[n], "intensity", 0, 1).step(0.01);
    });

    guiDirLight
      .add(this.#guiState, "dirLightsPos", 1, 20)
      .step(0.1)
      .name("position");
    guiDirLight.add(this.#guiState, "lightsShadows").name("cast shadows");

    const guiCamLight = guiLights.addFolder("Camera Light");
    guiCamLight.add(this.#camLight, "visible").name("camera light");
    guiCamLight.add(this.#camLight, "intensity", 0, 1).step(0.01);

    const guiCam = gui.addFolder("Camera");
    guiCam.add(this.#guiState, "cameraType", CameraType).name("type").listen();

    // const guiMode = gui.addFolder('Control mode');
    // guiMode.add(this.#guiState, 'controlMode', ControlMode).name('mode').listen();

    const guiPlan = gui.addFolder("Building plan");

    const guiPlanPos = guiPlan.addFolder("Position");
    guiPlanPos.close();
    guiPlanPos.add(this.#guiState, "modelRot", 0, 360).step(1).name("rotation");
    // .onChange(() =>
    //   this.#buildingPlan.rotateZ(
    //     this.#guiState.modelRot + this.#guiState.modelRotPrec
    //   )
    // );
    guiPlanPos
      .add(this.#guiState, "modelRotPrec", -3, 3)
      .step(0.01)
      .name("precise rotation");
    // .onChange(() =>
    //   this.#buildingPlan.rotateZ(
    //     this.#guiState.modelRot + this.#guiState.modelRotPrec
    //   )
    // );
    guiPlanPos
      .add(this.#guiState, "modelOffsetX", -2, 2)
      .step(0.01)
      .name("x offset")
      .onChange(() => this.#buildingPlan.moveX(this.#guiState.modelOffsetX));
    guiPlanPos
      .add(this.#guiState, "modelOffsetY", -2, 2)
      .step(0.01)
      .name("y offset")
      .onChange(() => this.#buildingPlan.moveZ(this.#guiState.modelOffsetY));

    const guiOcc = gui.addFolder("Occupancy grid");
    const guiOccPos = guiOcc.addFolder("Position");
    //guiOccPos.close()
    guiOccPos
      .add(this.#guiState, "occRot", 0, 360)
      .step(1)
      .name("rotation")
      .onChange(() => {
        this.#occGrid.rotateZ(
          this.#guiState.occRot + this.#guiState.occRotPrec
        );
      });
    guiOccPos
      .add(this.#guiState, "occRotPrec", -3, 3)
      .step(0.01)
      .name("precise rotation")
      .onChange(() =>
        this.#occGrid.rotateZ(this.#guiState.occRot + this.#guiState.occRotPrec)
      );
    guiOccPos
      .add(this.#guiState, "occXOffset", -2, 2)
      .step(0.01)
      .name("x offset")
      .onChange(() => this.#occGrid.moveX(this.#guiState.occXOffset));
    guiOccPos
      .add(this.#guiState, "occYOffset", -2, 2)
      .step(0.01)
      .name("y offset")
      .onChange(() => this.#occGrid.moveZ(this.#guiState.occYOffset));

    const guiMaterial = guiPlan.addFolder("Material");
    guiMaterial.close();
    guiMaterial
      .add(this.#guiState, "buildingMeshVisible")
      .name("visible")
      .listen();
    if (this.#buildingPlan) {
      guiMaterial
        .add(this.#buildingPlan.meshMaterial, "transparent")
        .name("transparent");
      guiMaterial
        .add(this.#buildingPlan.meshMaterial, "opacity", 0, 1)
        .step(0.01);
      guiMaterial
        .add(this.#buildingPlan.meshMaterial, "wireframe")
        .name("wireframe");
    }

    const guiDrone = gui.addFolder("Drone");
    guiDrone.add(this.#guiState, "droneVisible").name("visible").listen();
  }

  #updateDirLights() {
    const pos = this.#guiState.dirLightsPos;
    this.#dirLights[0].position.set(-pos, pos, -pos);
    this.#dirLights[1].position.set(-pos, pos, pos);
    this.#dirLights[2].position.set(pos, pos, pos);
    this.#dirLights[3].position.set(pos, pos, -pos);

    this.#dirLights.forEach(
      (l) => (l.castShadow = this.#guiState.lightsShadows)
    );
  }

  #onStateChanged() {
    this.#updateDirLights();
    this.#enableOrbit3DControls();
    this.#calcOccGridPointSize();

    switch (this.#guiState.cameraType) {
      case CameraType.Perspective:
        this.#projectContext.camera = this.#perspCam;
        break;
      case CameraType.Orthographic:
        this.#projectContext.camera = this.#orthoCam;
        break;
      case CameraType.Orthographic2D:
        this.#projectContext.camera = this.#orthoCam2D;
        break;
      default:
        break;
    }

    if (this.#buildingPlan) {
      this.#buildingPlan.meshMaterial.needsUpdate = true;
      if (this.#buildingPlan.mesh)
        this.#buildingPlan.mesh.visible = this.#guiState.buildingMeshVisible;
    }

    if (this.#projectContext.drone)
      this.#projectContext.drone.visible = this.#guiState.droneVisible;
  }

  loadGridSet(ddeUrl) {
    new THREE.FileLoader()
      .loadAsync(ddeUrl + "occ_grids.json")
      .then((data) => {
        const grids = JSON.parse(data);
        grids.forEach((gridInfo, n) => {
          const bg = this.data.buildingPlan.buildingGeometry;
          const o = new OccGrid(this.#projectContext);
          o.load(DDE_PATH + gridInfo.filename, gridInfo.pointCount);
          o.setOutline(this.#buildingPlan.outline, bg.rotation);
          this.#occGrids[n] = o;
        });

        const event = new CustomEvent("grid-set-loaded", {
          detail: { length: this.#occGrids.length },
        });
        document.dispatchEvent(event);
      })
      .catch((err) => {
        console.error(`Error loading file: ${err.message} (${ddeUrl})`);
      });
  }

  loadLocalPosJoinedSet(ddeUrl) {
    new THREE.FileLoader()
      .setResponseType("arraybuffer")
      .loadAsync(ddeUrl + "local_pos.daave")
      .then((data) => {
        this.#projectContext.localPosTrack = new LocalPosTrack(
          this.#projectContext
        );
        this.#projectContext.localPosTrack.loadJoinedSet(data);

        this.#projectContext.drone.setPos(
          this.#projectContext.localPosTrack.getLocalPos(0)
        );
        this.#projectContext.drone.visible = true;
      })
      .catch((err) => {
        console.error(`Error loading file: ${err.message} (${ddeUrl})`);
      });
  }

  loadLocalPosSet(ddeUrl) {
    new THREE.FileLoader()
      .loadAsync(ddeUrl + "local_pos.json")
      .then((data) => {
        const localPosSet = JSON.parse(data);
        this.#projectContext.localPosTrack = new LocalPosTrack(
          this.#projectContext
        );
        this.#projectContext.localPosTrack.loadSet(ddeUrl, localPosSet);
      })
      .catch((err) => {
        console.error(`Error loading file: ${err.message} (${ddeUrl})`);
      });
  }

  feedLaserScanSet(data) {
    if (data !== undefined) {
      const convertedData = JSON.stringify(data);
      const ls = LaserScan.fromJSON(convertedData);
      this.#projectContext.laserScanSet.scans.push(ls);
      this.#projectContext.laserScanSet.setPosByTime(
        ls.timeSec,
        ls.timeNanosec
      );
    }
  }

  feedLocalPosTrack(data) {
    if (data) {
      const convertedData = JSON.stringify(data);
      const lp = LocalPos.fromJSON(convertedData);
      this.setDronePos(lp);
      this.#projectContext.localPosTrack.track.push(lp);
    }
  }

  loadLaserScanJoinedSet(ddeUrl) {
    new THREE.FileLoader()
      .setResponseType("arraybuffer")
      .loadAsync(ddeUrl + "laser_scan.daave")
      .then((data) => {
        // const laserScanSet = new LaserScanSetEx(this.#projectContext);
        const laserScanSet = new LaserScanSet(this.#projectContext);
        const bg = this.data.buildingPlan.buildingGeometry;
        laserScanSet.setOutline(this.#buildingPlan.outline, bg.rotation);
        laserScanSet.localPosTrack = this.#projectContext.localPosTrack;
        laserScanSet.loadJoinedSet(data);
        this.#projectContext.laserScanSet = laserScanSet;
      })
      .catch((err) => {
        console.error(`Error loading file: ${err.message} (${ddeUrl})`);
      });
  }

  initLaserScanSet(data) {
    const laserScanSet = new LaserScanSet(this.#projectContext);
    laserScanSet.localPosTrack = this.#projectContext.localPosTrack;
    laserScanSet.loadJoinedSet(data);

    this.#projectContext.laserScanSet = laserScanSet;
  }

  loadOdometryJoinedSet(ddeUrl) {
    new THREE.FileLoader()
      .setResponseType("arraybuffer")
      .loadAsync(ddeUrl + "odom.daave")
      .then((data) => {
        const odometrySet = new OdometrySet(this.#projectContext);
        odometrySet.loadJoinedSet(data);

        this.#projectContext.odometrySet = odometrySet;
      })
      .catch((err) => {
        console.error(`Error loading file: ${err.message} (${ddeUrl})`);
      });
  }

  #animate() {
    this.#animationRequest = requestAnimationFrame(() => this.#animate());

    this.#orbitControlsPersp?.update();
    this.#orbitControlsOrtho?.update();
    this.#orbitControlsOrtho2D?.update();

    this.#projectContext.renderer?.render(
      this.#projectContext.scene,
      this.#projectContext.camera
    );
  }

  #onTimeChanged(timeInfo) {
    let sender = "";
    let trackPos = -1;
    let scanPos = -1;
    let odomPos = -1;
    if (
      this.#projectContext.localPosTrack &&
      timeInfo.sender !== this.#projectContext.localPosTrack
    ) {
      trackPos = this.#projectContext.localPosTrack.setPosByTime(
        timeInfo.sec,
        timeInfo.nanoSec
      );
    } else {
      trackPos = timeInfo.sender.pos;
      sender = "track";
    }
    if (
      this.#projectContext.odometrySet &&
      timeInfo.sender !== this.#projectContext.odometrySet
    ) {
      odomPos = this.#projectContext.odometrySet.setPosByTime(
        timeInfo.sec,
        timeInfo.nanoSec
      );
    } else {
      odomPos = timeInfo.sender.pos;
      sender = "odom";
    }
    if (
      this.#projectContext.laserScanSet &&
      timeInfo.sender !== this.#projectContext.laserScanSet
    ) {
      scanPos = this.#projectContext.laserScanSet.setPosByTime(
        timeInfo.sec,
        timeInfo.nanoSec
      );
    } else {
      scanPos = timeInfo.sender.pos;
      sender = "lidar";
    }

    if (this.syncLists) {
      const event = new CustomEvent("on-pos-after-time-changed", {
        detail: {
          sender: sender,
          trackPos: trackPos,
          scanPos: scanPos,
          odomPos: odomPos,
        },
      });
      document.dispatchEvent(event);
    }
  }

  onGridIndexChanged(idx) {
    if (this.#occGrids) {
      this.#hideGrids();
      this.#gridIdx = -1;
      if (this.#occGrids.length > idx) {
        const o = this.#occGrids[idx];
        if (o) {
          this.#gridIdx = idx;
          this.#calcOccGridPointSize();
          o.setPos(this.#gridXOffset, this.#gridYOffset, this.#gridRotation);
          o.visible = true;
        }
      }
    }
  }

  onGridXOffsetChanged(xOffset) {
    this.#gridXOffset = xOffset;
    this.#updateGridPos();
  }

  onGridYOffsetChanged(yOffset) {
    this.#gridYOffset = yOffset;
    this.#updateGridPos();
  }

  onGridRotChanged(rot) {
    this.#gridRotation = rot;
    this.#updateGridPos();
    this.#projectContext.laserScanSet.globalRotation = rot; //TEMP
  }

  onLidarVisibleSpanChanged(visibleSpan) {
    this.#projectContext.laserScanSet.visibleSpan = visibleSpan;
  }

  buildLidarGrid() {
    this.#projectContext.laserScanSet.buildGrid();
  }

  smoothenLidarGrid(checked) {
    this.#projectContext.laserScanSet.smoothenLidarGrid = checked;
  }

  smoothenLidarPoints(checked) {
    this.#projectContext.laserScanSet.smoothening = checked;
  }

  onTrackIndexChanged(idx) {
    this.#projectContext.localPosTrack.setPos(idx);
    this.#updateDronePos();
  }

  onLidarIndexChanged(idx) {
    this.#projectContext.laserScanSet.setPos(idx);
    this.#updateDronePos();
  }

  onOdometryIndexChanged(idx) {
    this.#projectContext.odometrySet.setPos(idx);
    this.#updateDronePos();
  }

  setDronePos(pos) {
    this.#projectContext.drone.setPos(pos);
  }

  #updateDronePos() {
    const localPos = this.#projectContext.localPosTrack.getLocalPos(
      this.#projectContext.localPosTrack.pos
    );
    if (localPos) this.setDronePos(localPos);
  }

  colorizeGrid(colorized) {
    if (this.#occGrids) for (let g of this.#occGrids) g.colorized = colorized;
  }

  #updateGridPos() {
    if (this.#occGrids && this.#gridIdx > -1 && this.#occGrids[this.#gridIdx])
      this.#occGrids[this.#gridIdx].setPos(
        this.#gridXOffset,
        this.#gridYOffset,
        this.#gridRotation
      );
  }

  #hideGrids() {
    if (this.#occGrids) for (let g of this.#occGrids) g.visible = false;
  }

  setProject(project, mission_id) {
    this.#init();
    this.project = project;
    this.data.buildingPlanIdx = -1;
    if (this.project.state?.selectedBuildingPlan !== undefined) {
      this.selectBuildingPlan(this.project.state.selectedBuildingPlan);
    }

    this.#projectContext.drone.visible = false;

    // DDE_PATH = DDE_ROOT + mission_id + '/'; //TEMP
    // this.loadOdometryJoinedSet(DDE_PATH); //TEMP
    // this.loadLocalPosJoinedSet(DDE_PATH); //TEMP
    // this.loadLaserScanJoinedSet(DDE_PATH); //TEMP
    // this.loadGridSet(DDE_PATH); //TEMP
  }
}
