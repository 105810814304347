const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "tim-icons icon-chart-pie-36",
    layout: "",
  },
  {
    path: "/map",
    name: "Map",
    icon: "tim-icons icon-map-big",
    layout: "",
  },
];

export default dashboardRoutes;
