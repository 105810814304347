import React, { useCallback, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { Button, Container, Row, Table } from "reactstrap";
import { useParams } from "react-router-dom";

import AddNewUserModal from "../../components/Modals/AddNewUserModal/AddNewUserModal";
import CustomSidebar from "../../components/CustomSidebar/CustomSidebar";
import { securedApi } from "../../api";
import { User } from "../../types/user";
import { userInfoAtom } from "../../recoil/atoms/userInfo.atom";
import ConfirmationModal from "../../components/Modals/ConfirmationModel/ConfirmationModal";
import MagicLinkModal from "../../components/Modals/MagicLinkModal/MagicLinkModal";
import Swal from "sweetalert2";
import "./styles.scss";

type VisibleModal =
  | "AddNewUser"
  | "DeleteUser"
  | "EditUser"
  | "MagicLink"
  | undefined;

const UsersManager: React.FC = () => {
  const { projectId } = useParams();
  const userInfo = useRecoilValue(userInfoAtom);

  const [visibleModal, setVisibleModal] = useState<VisibleModal>();
  const [users, setUsers] = useState<User[]>([]);
  const [deletedUser, setDeletedUser] = useState<User | null>(null);
  const [selectedUser, setSelectedUser] = useState<User | undefined>(undefined);

  const fetchUsers = useCallback(() => {
    securedApi
      .get(`/projects/${projectId}/users`)
      .then((response) => {
        setUsers(response.data.users);
      })
      .catch((error) => console.error(error));
  }, [projectId]);

  useEffect(() => {
    if (projectId && userInfo.tenantName) {
      fetchUsers();
    }
  }, [fetchUsers, projectId, userInfo.tenantName]);

  const handleModalClose = useCallback(() => {
    setVisibleModal(undefined);
    setSelectedUser(undefined);
  }, []);

  const handleModalOpen = useCallback((modalType: VisibleModal) => {
    setVisibleModal(modalType);
  }, []);

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    customClass: "toast-custom-class",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const handleUserSave = useCallback(
    (name: string, email: string, role: string, userId?: string) => {
      if (projectId) {
        const user = {
          projectId,
          name,
          email,
          role,
          userColor: "",
        };

        if (userId) {
          securedApi
            .put(`/projects/${projectId}/users/${userId}`, user)
            .then(() => {
              Toast.fire({
                icon: "success",
                title: "User data edited!",
              });
              fetchUsers();
            })
            .catch((error) => console.error(error));
        } else {
          securedApi
            .post(`/projects/${projectId}/users`, user)
            .then(() => {
              Toast.fire({
                icon: "success",
                title: "New user created!",
              });
              fetchUsers();
            })
            .catch((error) => console.error(error));
        }
        setVisibleModal(undefined);
      }

      setSelectedUser(undefined);
    },
    [projectId, Toast, fetchUsers]
  );

  const requestDelete = useCallback(
    (userId: string) => {
      securedApi
        .delete(`/projects/${projectId}/users/${userId}`)
        .then(() => {
          Toast.fire({
            icon: "success",
            title: "User deleted!",
          });
          fetchUsers();
        })
        .catch((error) => console.error(error));

      setDeletedUser(null);
      handleModalClose();
    },
    [Toast, fetchUsers, handleModalClose, projectId]
  );

  const handleDelete = useCallback(
    (user: User) => {
      setDeletedUser(user);
      handleModalOpen("DeleteUser");
    },
    [handleModalOpen]
  );

  const getMagicLink = useCallback(
    (user: User) => {
      setSelectedUser(user);
      handleModalOpen("MagicLink");
    },
    [handleModalOpen]
  );

  const handleUserEdition = useCallback(
    (user: User) => {
      setSelectedUser(user);
      handleModalOpen("EditUser");
    },
    [handleModalOpen]
  );

  const renderModals = useCallback(() => {
    if (visibleModal !== undefined) {
      switch (visibleModal) {
        case "AddNewUser":
          return (
            <AddNewUserModal
              onClose={handleModalClose}
              onSave={handleUserSave}
              selectedUser={selectedUser}
            />
          );
        case "DeleteUser":
          return (
            <ConfirmationModal
              title="Delete User"
              text={`Are you sure you want to delete user ${deletedUser?.name}?`}
              onConfirm={() => deletedUser && requestDelete(deletedUser.userId)}
              onCancel={handleModalClose}
            />
          );

        case "EditUser":
          return (
            <AddNewUserModal
              onClose={handleModalClose}
              onSave={handleUserSave}
              selectedUser={selectedUser}
            />
          );
        case "MagicLink":
          return (
            projectId &&
            selectedUser && (
              <MagicLinkModal
                projectId={projectId}
                user={selectedUser}
                onCancel={handleModalClose}
              />
            )
          );
      }
    }
  }, [
    visibleModal,
    handleModalClose,
    handleUserSave,
    deletedUser,
    selectedUser,
    projectId,
    requestDelete,
  ]);

  return (
    <Container className="usersManagerContainer pt-5">
      <CustomSidebar />
      <h2>Project Contributors</h2>
      <Table className="customTable">
        <thead>
          <tr>
            <th className="name">Name</th>
            <th className="email">Email</th>
            <th className="text-center role">Role</th>
            <th className="text-right actions">Actions</th>
          </tr>
        </thead>
        <tbody>
          {users?.map((user) => (
            <tr key={user.userId}>
              <td title={user.name}>{user.name}</td>
              <td>{user.email}</td>
              <td className="text-center">{user.role}</td>
              <td className="text-right action-icons">
                <Button
                  className="btn-icon btn-simple "
                  color="primary"
                  size="sm"
                  onClick={() => getMagicLink(user)}
                >
                  <i className="fa fa-key"></i>
                </Button>
                {` `}
                <Button
                  className="btn-icon btn-simple"
                  size="sm"
                  color="success"
                  onClick={() => handleUserEdition(user)}
                >
                  <i className="fa fa-edit"></i>
                </Button>
                {` `}
                <Button
                  className="btn-icon btn-simple"
                  color="danger"
                  size="sm"
                  onClick={() => handleDelete(user)}
                >
                  <i className="fa fa-times" />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Row className="justify-content-center">
        <Button
          className="btn-facebook"
          onClick={() => handleModalOpen("AddNewUser")}
        >
          <div>
            Add new user
            <i className="tim-icons icon-simple-add pl-2 font-weight-bold" />
          </div>
        </Button>
      </Row>
      {renderModals()}
    </Container>
  );
};

export default UsersManager;
