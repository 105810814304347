import { FontLoader } from "three/addons/loaders/FontLoader.js";
import { TextGeometry } from "three/addons/geometries/TextGeometry.js";

export let labelFont;
loadFont((f) => (labelFont = f));

export function loadFont(onFontLoaded) {
  const loader = new FontLoader();
  loader.load("/assets/fonts/helvetiker_regular.typeface.json", onFontLoaded);
}

export function createTextGeometry(text, font, size) {
  const geometry = new TextGeometry(text, {
    font: font,
    size: size,
    height: 0.0001,
  });
  geometry.computeBoundingBox();
  return geometry;
}
