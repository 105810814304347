import axios from "axios";
import { useLayoutEffect } from "react";
import { Auth } from "aws-amplify";

const API_URL = "/api";

export const securedApi = axios.create({ baseURL: API_URL });

export function useApiAuthInterceptor() {

  useLayoutEffect(() => {
    const interceptor = securedApi.interceptors.request.use(
      async (config) => {
        const currentTenant = localStorage.getItem("tenantName");
        const magicLinkToken = localStorage.getItem("magicLinkToken");
        const magicLinkId = localStorage.getItem("magicLinkId");
      
        // if (currentTenant) {
          config.headers["x-tenant-id"] = currentTenant || 'Daave';  // TODO: remove default once backend tenant validation is fixed
        // }
        if (magicLinkId) {
          config.headers["x-magic-link-id"] = magicLinkId;
        }
          

        try {
          if (magicLinkToken) {
            config.headers.Authorization = `Bearer ${magicLinkToken}`;
          } else {
            const user = await Auth.currentAuthenticatedUser();
            config.headers.Authorization =
              user.signInUserSession.idToken.jwtToken;
          }
        } catch (error) {
          console.error("Error retrieving authenticated user:", error);
        }
        return config;
      },
      (err) => Promise.reject(err)
    );

    return () => {
      securedApi.interceptors.request.eject(interceptor);
    };
  }, []);
}
