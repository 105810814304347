import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "reactstrap";
import CustomSidebar from "../../components/CustomSidebar/CustomSidebar";
import SLAM from "../../components/SLAM/SLAM";
import "./styles.scss";
import AddUserToMeetingModal from "../../components/Modals/AddUserToMeetingModal/AddUserToMeetingModal";
import { ControlMode } from "../../components/SLAM/slam-lib/project-editor";
import { IconContext } from "react-icons";
import { RiRouteFill } from "react-icons/ri";
import { useRecoilValue } from "recoil";

import {
  BuildingPlan,
  FlightPlanType,
  Mission,
  Project,
} from "../../types/project";
import { userInfoAtom } from "../../recoil/atoms/userInfo.atom";
import { FiSave } from "react-icons/fi";
import UniversalModal from "../../components/Modals/UniversalModal/UniversalModal";
import Swal from "sweetalert2";
import { securedApi } from "../../api";
import { MdOutlineHdrAuto } from "react-icons/md";
import { GiBroom } from "react-icons/gi";

const MODELS_PATH = "/assets/models";

export type FlightPlanParams = {
  projectId: string;
  buildingPlanId: string;
};

interface InviteUsersModal {
  name: "InviteUsers";
}

interface SaveFlightPathModal {
  name: "SaveFlightPlan";
}

type VisibleModal = InviteUsersModal | SaveFlightPathModal | undefined;

interface Props {
  user?: any;
}

interface States {
  editable?: boolean;
  startedFlight: boolean;
  duringFlight: boolean;
  afterFlight: boolean;
}

const FlightPlan: React.FC<Props> = () => {
  const [project, setProject] = useState<Project>();
  const userInfo = useRecoilValue(userInfoAtom);
  const [buildingPlan, setBuildingPlan] = useState<BuildingPlan>();
  const [coordinates, setCoordinates] = useState<
    { x: number; y: number }[] | null
  >([]);

  const navigate = useNavigate();

  const { projectId, buildingPlanId } = useParams<FlightPlanParams>();

  const [controlMode, setControlMode] = useState<symbol>(ControlMode.Orbit);
  const [visibleModal, setVisibleModal] = useState<VisibleModal>();
  const [, setErrorMessage] = useState<string>("");
  const [flightPlan, setFlightPlan] = useState<FlightPlanType>();
  const [autoDrawPathMode, setAutoDrawPathMode] = useState<boolean>(false);
  const [clearFlightPath, setClearFlightPath] = useState<boolean>(false);
  const [mission, setMission] = useState<Mission[]>([]);

  const [state, setState] = useState<States>();

  useEffect(() => {
    const currentState: States = {
      startedFlight:
        mission !== undefined && mission.length !== 0
          ? !!mission[0].missionStartTime
          : false,
      duringFlight:
        mission !== undefined && mission.length !== 0
          ? !!mission[0].missionStartTime && !mission[0].missionEndTime
          : false,
      afterFlight:
        mission !== undefined && mission.length !== 0
          ? !!mission[0].missionEndTime
          : false,
    };

    setState(currentState);
  }, [mission]);

  useEffect(() => {
    if (flightPlan) {
      securedApi
        .get(
          `/projects/${projectId}/buildingPlans/${buildingPlanId}/flightPlans/${flightPlan.id}/missions`
        )
        .then((res) => {
          setMission(res.data.missions);
        });
    }
  }, [flightPlan, buildingPlanId, projectId]);

  useEffect(() => {
    const buildingPlanFlightObj = {
      projectId: projectId,
      buildingPlanId: buildingPlanId,
      name: "Flight Plan created with Daave web app",
      description: "some descriptions",
    };

    const initFlightPlan = () => {
      securedApi
        .post(
          `/projects/${projectId}/buildingPlans/${buildingPlanId}/flightPlans`,
          buildingPlanFlightObj
        )
        .then((response) => {
          setFlightPlan({
            id: response.data.flightPlanId,
            coordinates: [],
          });
        })
        .catch((e) => console.error(e));
    };

    const fetchBuildingPlan = () => {
      securedApi
        .get(`/projects/${projectId}/buildingPlans/${buildingPlanId}`)
        .then((res) => {
          setBuildingPlan({
            name: res.data.name,
            buildingPlanId: res.data.buildingPlanId,
            projectId: res.data.projectId,
            created: res.data.creationTime,
            mapFileLocation: res.data.mapFileLocation,
            scanPreviewLocation: res.data.scanPreviewLocation,
            description: res.data.description,
            buildingGeometry: {
              meshUrl: `${MODELS_PATH}/biuro_base1.obj`,
              outlineUrl: res.data.mapFileLocation,
              xOffset: 0,
              yOffset: 0,
              rotation: 0,
            },
          });
        })
        .catch((error) => console.error(error));
    };

    const fetchFlightPlans = () => {
      securedApi
        .get(
          `/projects/${projectId}/buildingPlans/${buildingPlanId}/flightPlans`
        )
        .then((res) => {
          if (res.data.flightPlans?.length === 0) {
            initFlightPlan();
          } else {
            setFlightPlan({
              id: res.data.flightPlans[0]?.flightPlanId,
              coordinates: res.data.flightPlans[0]?.data || [],
            });
            setCoordinates(res.data.flightPlans[0].data);
          }
        })
        .catch((error) => console.error(error));
    };

    const fetchProject = () => {
      securedApi
        .get(`/projects/${projectId}`)
        .then((res) => {
          setProject(res.data);
        })
        .catch((error) => console.error(error));
    };

    if (userInfo.tenantName) {
      fetchBuildingPlan();
      fetchFlightPlans();
      fetchProject();
    }
  }, [buildingPlanId, projectId, setBuildingPlan, userInfo.tenantName]);

  const handleSend = useCallback(async () => {
    const createMission = (flightPlan: any) =>
      new Promise<Mission>((resolve, reject) => {
        const missionObj = {
          projectId: projectId,
          buildingPlanId: buildingPlanId,
          flightPlanId: flightPlan.id,
          name: "mission Name",
        };

        securedApi
          .get(
            `/projects/${projectId}/buildingPlans/${buildingPlanId}/flightPlans/${flightPlan.id}/missions`
          )
          .then((res) => {
            if (res.data.missions.length === 0) {
              securedApi
                .post(
                  `/projects/${projectId}/buildingPlans/${buildingPlanId}/flightPlans/${flightPlan.id}/missions`,
                  missionObj
                )
                .then((res) => {
                  resolve(res.data);
                })
                .catch((e) => {
                  console.error(e);
                  reject(e);
                });
            } else {
              resolve(res.data);
            }
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });

    if (flightPlan) {
      const mission = await createMission(flightPlan).then((res) => {
        navigate(
          `/projects/${projectId}/flight/${buildingPlanId}/flightPlan/${flightPlan.id}/mission/${res.missionId}`
        );
      });

      // if (mission) {
      //   navigate(
      //     `/projects/${projectId}/flight/${buildingPlanId}/flightPlan/${flightPlan.id}/mission/${mission.missionId}`
      //   );
      // }
    }
  }, [flightPlan, projectId, buildingPlanId, navigate]);

  const handleModalClose = useCallback(() => {
    setVisibleModal(undefined);
  }, []);

  const handleModalOpen = useCallback((modalType: any) => {
    setVisibleModal({ name: modalType });
  }, []);

  const saveFlightPath = useCallback(() => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      customClass: "toast-custom-class",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    securedApi
      .put(
        `/projects/${projectId}/buildingPlans/${buildingPlanId}/flightPlans/${
          flightPlan!.id
        }`,
        { data: flightPlan?.coordinates }
      )
      .then(() => {
        if (visibleModal) {
          Toast.fire({
            icon: "success",
            title: "Flight plan saved!",
          });
        }
      })
      .catch(() => {
        if (visibleModal) {
          Toast.fire({
            icon: "error",
            title: "Something went wrong - flight path not saved!",
          });
        }
      });
    if (visibleModal) {
      handleModalClose();
    }
  }, [buildingPlanId, flightPlan, handleModalClose, projectId, visibleModal]);

  const renderModals = useCallback(() => {
    if (visibleModal !== undefined) {
      switch (visibleModal.name) {
        case "InviteUsers":
          return (
            <AddUserToMeetingModal
              onClose={handleModalClose}
              setErrorMessage={setErrorMessage}
              onSend={handleSend}
            />
          );
        case "SaveFlightPlan":
          return (
            <UniversalModal
              onClose={handleModalClose}
              setErrorMessage={setErrorMessage}
              handleAction={saveFlightPath}
              title={"Save flight plan"}
              text={"By clicking save you will override previous flight plan."}
              buttonName={"Save"}
            />
          );
      }
    }
  }, [handleModalClose, handleSend, visibleModal, saveFlightPath]);

  const onMarkerEvent = (e: any) => {
    setCoordinates(e.coordinates);
  };

  const title = `${project?.name || ""} - ${buildingPlan?.name || ""}`;

  const clearPath = () => {
    if (flightPlan) {
      setClearFlightPath(true);
      setFlightPlan({ ...flightPlan, coordinates: [] });
      setCoordinates([]);
      setAutoDrawPathMode(false);
    }
  };

  const drawAutoPath = () => {
    if (flightPlan) {
      setClearFlightPath(true);
      const newFlightPlan: FlightPlanType = {
        id: flightPlan.id,
        coordinates: [
          {
            x: 8.235055587104098,
            y: -0.7599999904632568,
          },
          {
            x: -7.155619125369519,
            y: -0.6899999976158142,
          },
          {
            x: -7.9042605423789825,
            y: 1.0700000524520874,
          },
        ],
      };

      setFlightPlan(newFlightPlan);
      setCoordinates([
        {
          x: 8.235055587104098,
          y: -0.7599999904632568,
        },
        {
          x: -7.155619125369519,
          y: -0.6899999976158142,
        },
        {
          x: -7.9042605423789825,
          y: 1.0700000524520874,
        },
      ]);
    }
  };

  if (buildingPlan !== undefined && mission !== undefined) {
    return (
      <div className="flightPlanView">
        <CustomSidebar isExpanded={false} />
        <div className="flightPlanContainer">
          {flightPlan !== undefined ? (
            <div className="title-bar">
              <h4 className="text-truncate" title={title}>
                {title}
              </h4>
              <div className="buttons">
                <Button
                  size="sm"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Draw flight plan route"
                  disabled={state?.startedFlight}
                  className={
                    controlMode === ControlMode.EditFlightPlan
                      ? "btn-success drawPathButton"
                      : "drawPathButton"
                  }
                  onClick={() =>
                    setControlMode(
                      controlMode === ControlMode.Orbit
                        ? ControlMode.EditFlightPlan
                        : ControlMode.Orbit
                    )
                  }
                >
                  <IconContext.Provider value={{ size: "1.5rem" }}>
                    <RiRouteFill />
                  </IconContext.Provider>
                </Button>

                <Button
                  size="sm"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Draw automatically flight plan route"
                  className="drawAutoPathButton"
                  disabled={state?.startedFlight || autoDrawPathMode}
                  onClick={() => {
                    drawAutoPath();
                    setAutoDrawPathMode(true);
                  }}
                >
                  <IconContext.Provider value={{ size: "1.3rem" }}>
                    <MdOutlineHdrAuto />
                  </IconContext.Provider>
                  <IconContext.Provider value={{ size: "1.5rem" }}>
                    <RiRouteFill />
                  </IconContext.Provider>
                </Button>

                <Button
                  size="sm"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Clear path route"
                  className="clearPathButton"
                  onClick={clearPath}
                  disabled={state?.startedFlight}
                >
                  <IconContext.Provider value={{ size: "1.5rem" }}>
                    <GiBroom />
                  </IconContext.Provider>
                </Button>

                <Button
                  onClick={() => handleModalOpen("SaveFlightPlan")}
                  size="sm"
                  color="secondary"
                  disabled={state?.startedFlight}
                >
                  <IconContext.Provider value={{ size: "1rem" }}>
                    <FiSave style={{ marginRight: "8px" }} />
                  </IconContext.Provider>
                  Save
                </Button>
                {!state?.afterFlight ? (
                  <Button
                    color="primary"
                    size="sm"
                    className={state?.duringFlight ? "flashingButton" : ""}
                    onClick={() => {
                      if (state?.duringFlight) {
                        navigate(
                          `/projects/${projectId}/flight/${buildingPlanId}/flightPlan/${flightPlan?.id}/mission/${mission[0].missionId}`
                        );
                      } else {
                        saveFlightPath();
                        handleModalOpen("InviteUsers");
                      }
                    }}
                    disabled={
                      state?.afterFlight ||
                      coordinates === null ||
                      coordinates.length === 0
                    }
                  >
                    Fly
                    <i className="tim-icons icon-double-right pl-1" />
                  </Button>
                ) : (
                  <Button
                    color="primary"
                    size="sm"
                    onClick={() => {
                      if (mission !== undefined) {
                        if (mission[0].gridThumbnailUrl !== undefined) {
                          navigate(
                            `/projects/${projectId}/analysis/${buildingPlanId}/flightPlan/${flightPlan?.id}/mission/${mission[0].missionId}`
                          );
                        }
                      }
                    }}
                  >
                    Analyze
                  </Button>
                )}
              </div>
            </div>
          ) : null}

          {flightPlan && buildingPlan ? (
            <SLAM
              controlMode={controlMode}
              project={project}
              buildingPlan={buildingPlan}
              flightPlan={flightPlan}
              clearFlightPath={clearFlightPath}
              setClearFlightPath={setClearFlightPath}
              onMarkerEvent={onMarkerEvent}
              droneVisible={false}
            />
          ) : null}
        </div>

        {renderModals()}
      </div>
    );
  } else {
    return null;
  }
};

export default FlightPlan;
