import React, { useEffect, useState } from "react";
import { Button, Form, Modal, ModalBody, ModalFooter } from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import { IconContext } from "react-icons";

import { AiOutlineFileSearch } from "react-icons/ai";
import CustomSpinner from "../../../components/CustomSpinner/CustomSpinner";

interface Props {
  onClose: () => void;
}

const AnalyzeReadyModal: React.FC<Props> = ({ onClose }) => {
  const [loading, setLoading] = useState(true);
  const { projectId, buildingPlanId, flightPlanId, missionId } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, [projectId]);

  return (
    <Modal isOpen={true} toggle={onClose} modalClassName="modal-black">
      <div className="modal-header">
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={onClose}
        >
          <i className="tim-icons icon-simple-remove"></i>
        </button>
        <h5 className="modal-title">
          {loading ? "Prepare analyze mode" : "Analyze ready"}
        </h5>
      </div>
      <ModalBody>
        {loading ? (
          <CustomSpinner />
        ) : (
          <Form action="" className="form" method="">
            <div className="card-content">
              Building Plan is ready to see in analyze mode.
            </div>
          </Form>
        )}
      </ModalBody>
      <ModalFooter className="d-flex justify-content-center">
        {!loading ? (
          <Button
            color="primary"
            onClick={() => {
              navigate(
                `/projects/${projectId}/analysis/${buildingPlanId}/flightPlan/${flightPlanId}/mission/${missionId}`
              );
            }}
          >
            <IconContext.Provider value={{ size: "1.5rem" }}>
              <AiOutlineFileSearch />
            </IconContext.Provider>
            Go to Analyze
          </Button>
        ) : null}
      </ModalFooter>
    </Modal>
  );
};

export default AnalyzeReadyModal;
