export class TimeSeeker {
    #lastSec = -1;
    #lastSecPos = 0;
    #tsoList = []
    #listName = '';

    constructor(timeStampedObjectList, listName) {
        this.#tsoList = timeStampedObjectList;
        this.#listName = listName;
    }

    sort() {
        this.#tsoList.sort((a, b) => {
            if (a.timeSec < b.timeSec) return -1;
            else if (a.timeSec > b.timeSec) return 1;
            else { // equal timeSec
                if (a.timeNanosec < b.timeNanosec) return -1;
                else if (a.timeNanosec > b.timeNanosec) return 1;
            }
            return 0; // both timeSec and timeNanosec are equal
        });
    }

    getIndexAt(sec, nanosec) {
        let r = -1;
        let m = this.#lastSec < sec ? this.#lastSecPos : 0;
        let n = this.#lastSec > sec ? this.#lastSecPos : this.#tsoList.length - 1;
        while (m <= n) {
            const k = (m + n) >> 1;
            const d = sec - this.#tsoList[k].timeSec;
            if (d > 0) m = k + 1;
            else if (d < 0) n = k - 1;
            else { n = k; break; }
        }
        if (n < 0) n = 0;
        while (n > 1 && this.#tsoList[n].timeSec === sec && this.#tsoList[n].timeNanosec > nanosec)
            --n;

        for (; n < this.#tsoList.length; ++n) {
            const tso = this.#tsoList[n];
            if (tso.timeSec === sec) {
                // this is the first item with timeSec === sec
                // now find the item which is closest to sec and nanosec combo,
                // sometimes it could be an item with timeSec lower than sec, see (2)
                let m = n;
                while (m < this.#tsoList.length - 1) {
                    const t = this.#tsoList[m];
                    if (t.timeSec > sec || t.timeNanosec >= nanosec) {
                        if (m > 0) {
                            const prevT = this.#tsoList[m - 1];
                            if (t.timeSec === prevT.timeSec) {
                                // (1) the same second, just find which timeNanosec is closer to nanosec
                                if (Math.abs(t.timeNanosec - nanosec) > Math.abs(prevT.timeNanosec - nanosec))
                                    --m;
                            } else {
                                // (2) previous time stamp second is lower than current time stamp second
                                //     check if previous item is closer to the time specified by sec + nanosec
                                const secDiff = t.timeSec - prevT.timeSec;
                                const nsToFullSec = (1000000000 * secDiff) - prevT.timeNanosec;
                                const prevSpan = Math.abs(nsToFullSec + nanosec);
                                const currSpan = Math.abs(t.timeNanosec - nanosec);
                                if (currSpan > prevSpan) {
                                    // item with timeSec lower than sec is closer in time to sec + nanosec
                                    --m;
                                }
                            }
                        }
                        break;
                    } else {
                        ++m;
                    }
                }
                r = m;
                break;
            } else if (tso.timeSec > sec) {
                // this should never happend if items list is correct,
                // but in case of lack of position at the specified time
                // return first position which has time right after desired time
                //console.warn(`(${this.#listName}) Error: No position with time ${sec}.${nanosec} - next time ${tso.timeSec}.${tso.timeNanosec}`)
                r = n;
                break;
            }
        }
        if (r > -1) {
            this.#lastSec = sec;
            this.#lastSecPos = n;
        } else {
            this.#lastSec = -1;
            this.#lastSecPos = 0;
        }
        return r;
    }
}

export const dispatchOnTimeChangedEvent = (sender, timeStampedObject, eventTarget) => {
    const event = new CustomEvent('on-time-changed', { detail: {
        sender: sender,
        sec: timeStampedObject.timeSec,
        nanoSec: timeStampedObject.timeNanosec,
    }});
    eventTarget.dispatchEvent(event);
}