import { useCallback, useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { User } from "../types/user";
import { WEBSOCKET_URL } from "../config";

type WsConfig = {
  userId: string | undefined;
  missionId: string | undefined;
  connectionType: "mission" | "analysis";
  onMessage?: (message: MessageEvent["data"]) => void;
  onError?: (error: unknown) => void;
};

type ReturnType = {
  connected: boolean;
  socket: WebSocket | undefined;
  users: User[];
};

function useWebsockets({
  userId,
  missionId,
  connectionType,
  onMessage,
  onError,
}: WsConfig): ReturnType {
  const [connected, setConnected] = useState(false);
  const [socket, setSocket] = useState<WebSocket>();
  const [users, setUsers] = useState<User[]>([]);

  const handleMessage = useCallback((message: MessageEvent["data"]) => {
    if (message.type === "UserList") {
      setUsers(message.userList);
    }

    if (message.type === "UserConnected") {
      setUsers((users) => [...users, message]);
    }

    if (message.type === "UserDisconnected") {
      setUsers((users) => users.filter((u) => u.userId !== message.userId));
    }
  }, []);

  const connectWs = useCallback(async () => {
    if (!missionId || !userId) return;

    console.log("attempting WS connection");

    const currentTenant = localStorage.getItem("tenantName") || "Daave";
    const magicLinkToken = localStorage.getItem("magicLinkToken");
    const magicLinkId = localStorage.getItem("magicLinkId");

    let authKey = "";
    if (magicLinkToken) {
      authKey = magicLinkToken;
    } else {
      const user = await Auth.currentAuthenticatedUser();
      authKey = user.signInUserSession.idToken.jwtToken;
    }

    const connectionQuery =
      `?tenant=${currentTenant}&connectionType=${connectionType}&relatedEntityId=${missionId}&authKey=${authKey}` +
      (magicLinkId ? `&magicLinkId=${magicLinkId}` : "");

    const socket = new WebSocket(WEBSOCKET_URL + connectionQuery);
    setSocket(socket);

    socket.addEventListener("message", (event) => {
      const message = JSON.parse(event.data);
      handleMessage(message);

      if (onMessage) {
        onMessage(message);
      }
    });

    socket.addEventListener("error", (event) => {
      console.log("Error: ", event);
      if (onError) {
        onError(event);
      }
    });

    socket.addEventListener("open", (event) => {
      console.log("WS connection opened");
      setConnected(true);
      socket.send(JSON.stringify({ type: "GetUserList" }));
    });

    socket.addEventListener("close", (event) => {
      console.log("WS connection closed");
      setConnected(false);
    });

    return socket;
  }, [missionId, connectionType, onMessage, onError, handleMessage, userId]);

  useEffect(() => {
    const socketReq = connectWs();

    return () => {
      socketReq.then((socket) => {
        if (socket) {
          console.log("disconnecting WS", socket);
          socket?.close();
        }
      });
    };
  }, [connectWs]);

  return { connected, socket, users };
}

export default useWebsockets;
