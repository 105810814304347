import * as THREE from "three";
import { SVGLoader } from "three/addons/loaders/SVGLoader.js";

import * as PARAMS from "../params.js";
import { Label, LabelZoomMode, DEFAULT_LABEL_FONT_SIZE,
         LabelHorizAlignMode, LabelVertAlignMode } from "./label.js"
import { saveBlob, loadLocalJson } from "../io.js";
import { MARKER_EVENTS, MARKER_TYPES } from "../params.js";

const ROT90 = THREE.MathUtils.degToRad(90);
const ROT180 = ROT90 * 2;

const Z_POS_DELTA = 0.0001;
const ARROW_Z_POS = PARAMS.MEASURINGS_Z_POS;
const ARROW_SEL_Z_POS = ARROW_Z_POS - Z_POS_DELTA;
const TEXT_Z_POS = ARROW_SEL_Z_POS - Z_POS_DELTA;
const TEXT_BACK_Z_POS = TEXT_Z_POS - Z_POS_DELTA;
const LINE_Z_POS = TEXT_BACK_Z_POS - Z_POS_DELTA;

const ARROW_SCALE = 0.02;

const arrowShape = new THREE.Shape()
  .moveTo(0, 0)
  .lineTo(1, 3)
  .lineTo(-1, 3)
  .lineTo(0, 0);

const outlineStrokeStyle = {
  strokeWidth: 1,
};

const hoveredColor = new THREE.Color(0xff0000);
const selectedColor = new THREE.Color(0x0000ff);

const EditState = Object.freeze({
  None: Symbol("None"),
  DrawStart: Symbol("DrawStart"),
  Draw: Symbol("Draw"),
  DrawEnd: Symbol("DrawEnd"),
  MoveStart: Symbol("MoveStart"),
  Move: Symbol("Move"),
});

export class Measure {
  #projectContext;
  #lengthLabel;
  #label;
  #labelText;
  #labelZoomMode = LabelZoomMode.Fixed;
  #labelFontSize = DEFAULT_LABEL_FONT_SIZE;
  #line;
  #lineMaterial;
  #lineGeometry;
  #lineHit = false;
  #arrows = [];
  #arrowsOutline = [];
  #hitDx = 0;
  #hitDy = 0;
  #hoveredArrow;
  #isSelected = false;
  #movedArrow;
  #editState = EditState.None;
  #undoStack = [];
  #redoStack = [];

  id = null;
  coordinates = [];
  color = null;
  editable = false;

  constructor(projectContext, color, editable) {
    this.#projectContext = projectContext;
    if (color) {
      this.color = color;
    }
    if (editable !== undefined) {
      this.editable = editable;
    }

    const arrowGeometry = new THREE.ShapeGeometry(arrowShape);
    const arrowMaterial = new THREE.MeshBasicMaterial({
      color: PARAMS.MEASURE_ARROW_COLOR,
      side: THREE.DoubleSide,
    });
    this.#arrows[0] = new THREE.Mesh(arrowGeometry.clone(), arrowMaterial.clone());
    this.#arrows[1] = new THREE.Mesh(arrowGeometry.clone(), arrowMaterial.clone());
    this.#projectContext.scene.add(this.#arrows[0]);
    this.#projectContext.scene.add(this.#arrows[1]);
    arrowGeometry.dispose();
    arrowMaterial.dispose();

    const outlineGeometry = SVGLoader.pointsToStroke(
      arrowShape.getPoints(),
      outlineStrokeStyle
    );
    const outlineMaterial = new THREE.MeshBasicMaterial({
      side: THREE.DoubleSide,
      transparent: true,
      opacity: 0.5,
    });
    this.#arrowsOutline[0] = new THREE.Mesh(
      outlineGeometry.clone(),
      outlineMaterial.clone()
    );
    this.#arrowsOutline[1] = new THREE.Mesh(
      outlineGeometry.clone(),
      outlineMaterial.clone()
    );
    outlineGeometry.dispose();
    outlineMaterial.dispose();

    this.#arrowsOutline[0].visible = false;
    this.#arrowsOutline[1].visible = false;
    this.#projectContext.scene.add(this.#arrowsOutline[0]);
    this.#projectContext.scene.add(this.#arrowsOutline[1]);

    this.#undoPush();
  }

  setCoordinates(coordinates) {
    this.coordinates = coordinates;
    this.#update();
  }

  setData(measureData) {
    this.id = measureData.id;
    this.coordinates = measureData.coordinates;
    this.#update();
  }

  getData() {
    return {
      id: this.id,
      coordinates: this.coordinates,
    };
  }

  select() {
    this.#isSelected = true;
    this.#update();
  }

  deselect() {
    this.#isSelected = false;
    this.#hoveredArrow = null;
    this.#update();
  }

  clear() {
    if (this.coordinates.length) {
      this.#undoPush();
      this.coordinates = [];
      this.#update();
    }
  }

  onMouseDown(xPos, yPos) {
    if (this.#hoveredArrow) {
      this.#editState = EditState.MoveStart;
      this.#hitDx = this.#hoveredArrow.point.x - this.#hoveredArrow.measurePoint.x;
      this.#hitDy = this.#hoveredArrow.point.z - this.#hoveredArrow.measurePoint.y;
    } else if (this.#lineHit) {
      this.#isSelected = !this.#isSelected;
      if (this.#isSelected) {
        this.dispatchMarkerEvent(MARKER_EVENTS.SELECTED);
      }
    } else {
      if (this.#isSelected) {
        this.#isSelected = false;
      } else {
        this.#editState = EditState.DrawStart;
        this.#mouseHandler(xPos, yPos);
      }
    }
    this.#update();
  }

  onMouseMove(xPos, yPos) {
    if (!this.editable) return;

    switch (this.#editState) {
      case EditState.DrawStart:
        this.#editState = EditState.Draw;
        break;
      case EditState.MoveStart:
        if (this.#hoveredArrow) {
          this.#editState = EditState.Move;
          this.#movedArrow = this.#hoveredArrow;
          this.#hoveredArrow = null;
        }
        break;
      default:
        break;
    }
    this.#mouseHandler(xPos, yPos);
  }

  onMouseUp(xPos, yPos) {
    switch (this.#editState) {
      case EditState.MoveStart:
        if (this.#hoveredArrow) this.#isSelected = !this.#isSelected;
        this.dispatchMarkerEvent(MARKER_EVENTS.SELECTED);
        break;
      case EditState.Move:
        this.#undoPush();
        if (this.coordinates.length > 1) {
          this.dispatchMarkerEvent(MARKER_EVENTS.UPDATED);
        }
        break;
      case EditState.DrawStart:
      case EditState.Draw:
        this.#editState = EditState.DrawEnd;
        this.#mouseHandler(xPos, yPos);
        if (this.coordinates.length < 2) {
          // user released left button without moving a mouse, there
          // is only a starting point of a measure in this.coordinates
          // this is incorrect, second point must be added,
          // duplicate starting point and create slightly offseted
          // ending point, make 1 cm vertical measure
          const sp = this.coordinates[0];
          this.coordinates.push({ x: sp.x, y: sp.y + 0.01 });
        }
        this.#undoPush();
        if (this.coordinates.length > 1) {
          this.dispatchMarkerEvent(MARKER_EVENTS.CREATED);
        }
        break;
      default:
        break;
    }

    this.#editState = EditState.None;
    this.#hoveredArrow = null;
    this.#movedArrow = null;
    this.#update();
  }

  hitTest(xPos, yPos) {
    const rect = this.#projectContext.renderer.domElement.getBoundingClientRect();
    const x = ((xPos - rect.left) / rect.width) * 2 - 1;
    const y = -((yPos - rect.top) / rect.height) * 2 + 1;
    this.#projectContext.rayCaster.setFromCamera({ x, y }, this.#projectContext.camera);

    const prevHovered = this.#hoveredArrow;
    this.#hoveredArrow = null;

    let hit = false;
    let intersections = this.#projectContext.rayCaster.intersectObject(this.#arrows[0]);
    if (intersections.length) {
      this.#hoveredArrow = this.#arrows[0];
      this.#hoveredArrow.point = intersections[0].point;
      this.#hoveredArrow.measurePoint = this.coordinates[0];
      hit = true;
    } else {
      intersections = this.#projectContext.rayCaster.intersectObject(this.#arrows[1]);
      if (intersections.length) {
        this.#hoveredArrow = this.#arrows[1];
        this.#hoveredArrow.point = intersections[0].point;
        this.#hoveredArrow.measurePoint = this.coordinates[1];
        hit = true;
      }
    }

    let update = prevHovered !== this.#hoveredArrow;

    if (!hit && this.#line) {
      const intersections = this.#projectContext.rayCaster.intersectObject(this.#line);
      const prevLineHit = this.#lineHit;
      this.#lineHit = false;
      if (intersections.length > 0) {
        this.#lineHit = true;
        hit = true;
      }
      update = update || prevLineHit !== this.#lineHit;
    }

    if (update) this.#update();

    return hit;
  }

  #mouseHandler(xPos, yPos) {
    this.hitTest(xPos, yPos);

    if (this.#editState !== EditState.None) {
      const intersections = this.#projectContext.rayCaster.intersectObject(this.#projectContext.drawGrid);
      if (intersections.length) {
        const p = intersections[0].point;
        if (this.#editState === EditState.Move) {
          const n = this.#movedArrow === this.#arrows[0] ? 0 : 1;
          this.coordinates[n] = { x: p.x - this.#hitDx, y: p.z - this.#hitDy };
          this.#update();
        } else if (this.#editState !== EditState.DrawEnd) {
          if (this.coordinates.length > 1) {
            this.coordinates.pop();
          }
          this.coordinates.push({ x: p.x, y: p.z });
          this.#update();
        }
      }
    }
  }

  destroy() {
    this.dispose();
  }

  dispose() {
    this.#cleanup();

    if (this.#arrows[0]) {
      this.#projectContext.scene.remove(this.#arrows[0]);
      this.#arrows[0].material.dispose();
      this.#arrows[0].geometry.dispose();
      this.#arrows[0] = null;
    }
    if (this.#arrows[1]) {
      this.#projectContext.scene.remove(this.#arrows[1]);
      this.#arrows[1].material.dispose();
      this.#arrows[1].geometry.dispose();
      this.#arrows[1] = null;
    }
    if (this.#arrowsOutline[0]) {
      this.#projectContext.scene.remove(this.#arrowsOutline[0]);
      this.#arrowsOutline[0].material.dispose();
      this.#arrowsOutline[0].geometry.dispose();
      this.#arrowsOutline[0] = null;
    }
    if (this.#arrowsOutline[1]) {
      this.#projectContext.scene.remove(this.#arrowsOutline[1]);
      this.#arrowsOutline[0].material.dispose();
      this.#arrowsOutline[0].geometry.dispose();
      this.#arrowsOutline[1] = null;
    }
  }

  #cleanup() {
    if (this.#lineMaterial) {
      this.#lineMaterial.dispose();
      this.#lineMaterial = null;
    }
    if (this.#lineGeometry) {
      this.#lineGeometry.dispose();
      this.#lineGeometry = null;
    }
    if (this.#line) {
      this.#projectContext.scene.remove(this.#line);
      this.#line = null;
    }
    if (this.#label) {
      this.#label.dispose();
      this.#label = null;
    }
    if (this.#lengthLabel) {
      this.#lengthLabel.dispose();
      this.#lengthLabel = null;
    }
  }

  #update() {
    this.#cleanup();

    for (let a of this.#arrows)
      a.visible = false;

    if (this.coordinates.length > 0) {
      if (this.coordinates.length === 1) {
        // only start point exists, add second fake point to make this path visible
        const p = this.coordinates[0];
        this.coordinates.push({ x: p.x, y: p.y + 0.01 });
      }

      const points = [
        new THREE.Vector3(this.coordinates[0].x, LINE_Z_POS, this.coordinates[0].y),
        new THREE.Vector3(this.coordinates[1].x, LINE_Z_POS, this.coordinates[1].y),
      ];

      const lineHit = this.#lineHit && this.#editState === EditState.None;
      this.#lineMaterial = new THREE.LineBasicMaterial({
        color: lineHit ? hoveredColor : (this.#isSelected ? selectedColor : PARAMS.MEASURE_ARROW_COLOR),
      });
      this.#lineGeometry = new THREE.BufferGeometry().setFromPoints(points);
      this.#line = new THREE.Line(this.#lineGeometry, this.#lineMaterial);
      this.#projectContext.scene.add(this.#line);

      const midPoint = new THREE.Vector3()
        .subVectors(points[1], points[0])
        .multiplyScalar(0.5)
        .add(points[0]);

      const length = points[1].distanceTo(points[0]);
      this.#lengthLabel = new Label(
        this.#projectContext, length.toFixed(2),
        DEFAULT_LABEL_FONT_SIZE, midPoint
      );

      if (this.#labelText) {
        this.#line.geometry.computeBoundingBox();
        const bbox = this.#line.geometry.boundingBox;
        const dz = (bbox.max.z - bbox.min.z) / 2.0;
        const p = new THREE.Vector3(midPoint)
        p.x = midPoint.x;
        p.y = midPoint.y;
        p.z = midPoint.z + dz + 0.1;

        this.#label = new Label(
          this.#projectContext, this.#labelText,
          this.#labelFontSize, p, this.#labelZoomMode,
          LabelHorizAlignMode.Center,
          LabelVertAlignMode.Top
        );
      }

      const calcArrowMatrix = (n, points, reversed) => {
        let r = 0, p1, p2;
        if (n > 0) {
          p1 = points[n];
          p2 = points[n - 1];
          r =  Math.atan2(p1.y - p2.y, p1.x - p2.x) + ROT90 + (reversed ? ROT180 : 0);
        } else {
          p1 = points[1];
          p2 = points[0];
          r =  Math.atan2(p1.y - p2.y, p1.x - p2.x) + ROT90 + (reversed ? 0 : ROT180);
          p1 = p2;
        }

        [this.#arrows[n], this.#arrowsOutline[n]].forEach((mesh, index) => {
          const zPos = index === 0 ? ARROW_Z_POS : ARROW_SEL_Z_POS;
          mesh.position.set(p1.x, zPos, p1.y);
          mesh.rotation.set(ROT90, 0.0, r);
          mesh.scale.setScalar(ARROW_SCALE);
          mesh.updateMatrix();
        });
      };

      let arrowTouchesLabel = false;
      const labelBox = this.#lengthLabel.calcBox3();
      for (let n = 0; n < this.#arrows.length; ++n) {
        calcArrowMatrix(n, this.coordinates, false);
        if (!arrowTouchesLabel) {
          const bb = new THREE.Box3().setFromObject(this.#arrows[n]);
          bb.min.y = labelBox.min.y;
          bb.max.y = labelBox.max.y;
          arrowTouchesLabel = labelBox.intersectsBox(bb);
        }
        this.#arrows[n].visible = true;
      }

      if (arrowTouchesLabel) {
        const labelPos = new THREE.Vector3()
          .subVectors(
            points[1],
            length > 0
              ? points[0]
              : new THREE.Vector3(points[0].x + 1, TEXT_Z_POS, points[0].y)
          )
          .applyAxisAngle(new THREE.Vector3(0, 1), Math.PI * 0.5)
          .normalize()
          .multiplyScalar(0.1)
          .add(midPoint);
        this.#lengthLabel.setPos(labelPos);

        for (let n = 0; n < this.#arrows.length; ++n) {
          calcArrowMatrix(n, this.coordinates, true);
        }
      }

      if (this.#isSelected) {
        this.#arrowsOutline[0].visible = true;
        this.#arrowsOutline[0].material.color = selectedColor;
        this.#arrowsOutline[1].visible = true;
        this.#arrowsOutline[1].material.color = selectedColor;
      }

      let hoveredIdx = -1;
      if (this.#hoveredArrow && this.#editState === EditState.None) {
        hoveredIdx = this.#hoveredArrow === this.#arrows[0] ? 0 : 1;
        this.#arrowsOutline[hoveredIdx].visible = true;
        this.#arrowsOutline[hoveredIdx].material.color = hoveredColor;
      }

      if (!this.#isSelected) {
        if (hoveredIdx !== 0) this.#arrowsOutline[0].visible = false;
        if (hoveredIdx !== 1) this.#arrowsOutline[1].visible = false;
      }
    }
  }

  addLabel(text, size) {
    this.#labelText = text;
    this.#labelFontSize = size;
    this.#update();
  }

  setLabelFontSize(size) {
    this.#labelFontSize = size;
    if (this.#label) this.#label.setFontSize(size);
  }

  setLabelZoomMode(labelZoomMode) {
    this.#labelZoomMode = labelZoomMode;
    if (this.#label) this.#label.setZoomMode(labelZoomMode);
  }

  save() {
    if (this.coordinates.length > 0) {
      this.#saveJsonMeasure(this.coordinates, "coordinates.json");
    }
  }

  #saveJsonMeasure(coordinates, fileName) {
    saveBlob(JSON.stringify(coordinates), fileName);
  }

  load() {
    loadLocalJson((coordinates) => {
      this.coordinates = coordinates;
      this.#update();
    });
    this.#update();
  }

  #compareMeasurements(a, b) {
    if (a.length !== b.length) {
      return false;
    }
    for (let i = 0; i < a.length; i++) {
      if (a[i].x !== b[i].x || a[i].y !== b[i].y) {
        return false;
      }
    }
    return true;
  }

  #undoPush() {
    this.#undoStack.push(structuredClone(this.coordinates));
  }

  #redoPush() {
    this.#redoStack.push(structuredClone(this.coordinates));
  }

  undo() {
    if (this.#undoStack.length) {
      if (this.#compareMeasurements(this.#undoStack.at(-1), this.coordinates)) {
        this.#undoStack.pop();
        this.#redoPush();
      }
      if (this.#undoStack.length) {
        this.coordinates = this.#undoStack.pop();
        this.#redoPush();
      }
      this.#update();
    }
  }

  redo() {
    if (this.#redoStack.length) {
      if (this.#compareMeasurements(this.#redoStack.at(-1), this.coordinates)) {
        this.#redoStack.pop();
        this.#undoPush();
      }
      if (this.#redoStack.length) {
        this.coordinates = this.#redoStack.pop();
        this.#undoPush();
      }
      this.#update();
    }
  }

  dispatchMarkerEvent(eventType) {
    const event = new CustomEvent("marker-event", {
      detail: {
        eventType,
        item: this,
        itemId: this.id,
        itemType: MARKER_TYPES.MEASUREMENT,
        coordinates: this.coordinates,
      },
    })
    this.#projectContext.renderer.domElement.dispatchEvent(event);
  }
}
