import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import { usePDF } from "@react-pdf/renderer";

import {
  BuildingPlan,
  FlightPlanType,
  Mission,
  Project,
} from "../../types/project";
import { Participant, User } from "../../types/user";
import { Marker } from "../../types/marker";
import SLAM from "../../components/SLAM/SLAM";
import { UserInfoInterface } from "../../recoil/atoms/userInfo.atom";
import { securedApi } from "../../api";
import ReportPdf from "../../components/ReportPdf/ReportPdf";
import "./styles.scss";
import { orderBy } from "lodash";

type ReportPreviewProps = {
  project: Project | undefined;
  buildingPlan: BuildingPlan | undefined;
  flightPlan: FlightPlanType | undefined;
  mission: Mission | undefined;
  markers: Marker[];
  userInfo: UserInfoInterface;
  allUsers: User[];
  onClose: () => void;
};

const availableModes: symbol[] = [];

const ReportPreview: React.FC<ReportPreviewProps> = ({
  project,
  buildingPlan,
  flightPlan,
  mission,
  markers,
  userInfo,
  allUsers,
  onClose,
}) => {
  const date = new Date().toDateString();
  const [participants, setParticipants] = useState<User[]>([]);
  const [canvasReady, setCanvasReady] = useState(false);

  const thisUser = useMemo(
    () => allUsers.find((u) => u.userId === userInfo.userId),
    [allUsers, userInfo.userId]
  );
  const validMarkers = useMemo(
    () =>
      orderBy(
        markers.filter((marker) => marker.isAccepted),
        "creationTimestamp"
      ).map((marker, index) => ({ ...marker, label: String(index + 1) })),
    [markers]
  );

  const filename =
    (buildingPlan?.name || "building plan").replace(/[^a-z0-9]/gi, "_").trim() +
    ".pdf";

  const renderPdf = useCallback(() => {
    return (
      <ReportPdf
        project={project}
        buildingPlan={buildingPlan}
        flightPlan={flightPlan}
        mission={mission}
        markers={validMarkers}
        userInfo={userInfo}
        participants={participants}
        allUsers={allUsers}
      />
    );
  }, [
    project,
    buildingPlan,
    flightPlan,
    mission,
    validMarkers,
    userInfo,
    allUsers,
    participants,
  ]);

  const [instance, updateInstance] = usePDF({ document: renderPdf() });

  useEffect(() => {
    if (
      !project?.id ||
      !buildingPlan?.buildingPlanId ||
      !flightPlan?.id ||
      !mission?.missionId ||
      !allUsers.length
    )
      return;

    securedApi
      .get(
        `/projects/${project.id}/buildingPlans/${buildingPlan?.buildingPlanId}/flightPlans/${flightPlan?.id}/missions/${mission?.missionId}/participants`
      )
      .then((res) => {
        const participantIds: string[] = res.data.participants.map(
          (p: Participant) => p.userId
        );
        const participants = allUsers.filter((u) => {
          return participantIds.includes(u.userId) && u.role !== "admin";
        });
        if (thisUser) {
          participants.push(thisUser);
        }
        setParticipants(participants);
      })
      .catch((error) => console.error(error));
  }, [
    project?.id,
    buildingPlan?.buildingPlanId,
    flightPlan?.id,
    mission?.missionId,
    allUsers,
    thisUser,
  ]);

  useEffect(() => {
    if (!project || !participants.length) return;

    setTimeout(() => {
      updateInstance(renderPdf());
      setCanvasReady(true);
    }, 2000);
  }, [project, participants, renderPdf, updateInstance]);

  const allDataReady = canvasReady && participants.length;

  return (
    <Modal
      isOpen
      className="report-modal"
      modalClassName="modal-black"
      size="lg"
    >
      <div className="modal-header">
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={onClose}
        >
          <i className="tim-icons icon-simple-remove"></i>
        </button>
        <h5 className="modal-title">Analysis Report Preview</h5>
      </div>
      <ModalBody>
        <div className="report-preview">
          <title>Analysis Report generated {date}</title>
          <div className="subtitle">
            <div>
              Project: <span className="emphasis">{project?.name}</span>
            </div>
            <div>
              Building Plan:{" "}
              <span className="emphasis">{buildingPlan?.name}</span>
            </div>
          </div>

          <div className="building-plan-container">
            <div className="building-plan">
              <SLAM
                showGui={false}
                availableModes={availableModes}
                project={project}
                buildingPlan={buildingPlan}
                flightPlan={flightPlan}
                mission={mission}
                markers={validMarkers}
                droneVisible={false}
              />
            </div>
          </div>

          <div className="user-list section">
            <div className="subtitle">Participants:</div>
            {participants.map((user) => (
              <div className="user-line" key={user.userId}>
                <span className="emphasis">{user.name}</span>
                <span> ({user.email}) </span>
                <span>{user.role}</span>
              </div>
            ))}
          </div>

          <div className="comment-list section">
            <div className="subtitle">Comments:</div>
            {validMarkers.map((marker) => {
              const user = allUsers.find((u) => u.userId === marker.userId);

              return (
                <div className="comment-line" key={marker.markerId}>
                  <span>{marker.label}</span>
                  <span style={{ color: marker.color }}> {user?.name}: </span>
                  <span className="comment-content">{marker.comment}</span>
                </div>
              );
            })}
          </div>

          <div className="signature section">
            <div>Accepted by</div>
            <div>
              {thisUser?.name} ({thisUser?.email}) {thisUser?.role}
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onClose}>
          Close
        </Button>
        <a
          href={instance.url || ""}
          download={filename}
          className={allDataReady ? "" : "disabled"}
        >
          <Button color="primary" disabled={!allDataReady}>
            Get PDF
          </Button>
        </a>
      </ModalFooter>
    </Modal>
  );
};

export default ReportPreview;
