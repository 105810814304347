import * as THREE from 'three';
import { isStrAt, strFromArrayBuffer } from '../utils/binary-utils.js'

export class LocalPos {
  timeSec = 0;
  timeNanosec = 0;
  position = {
    x: 0.0,
    y: 0.0,
    z: 0.0
  };
  orientation = {
    x: 0.0,
    y: 0.0,
    z: 0.0,
    w: 0.0
  };

  load(url) {
    const loader = new THREE.FileLoader();
    loader.setResponseType('arraybuffer');
    loader.loadAsync(url)
      .then(data => {
        this.parseDaaveBinary(data);
        document.dispatchEvent(new Event('local-pos-loaded'));
      })
      .catch(err => {
        console.error('Error loading file:', err);
      });
  }

  parseDaaveBinary(buffer, byteOffset = 0) {
    const dv = new DataView(buffer, byteOffset);

    // 0 - 7: header id string
    const DAAVE_LP = 'DAAVE_LP';
    if (!isStrAt(dv, DAAVE_LP, 0)) {
      const headerId = strFromArrayBuffer(buffer, byteOffset, DAAVE_LP.length);
      console.error(`Invalid file format. Unknown header ID: '${headerId}'.`);
      return 0;
    }

    // 8: version - uint8
    const ver = dv.getUint8(8);
    if (ver !== 1) {
      console.error(`DAAVE_LP: Invalid file version. Should be 1, but is ${ver}.`);
      return 0;
    }

    // 9 - 12: timestamp - sec - int32
    this.timeSec = dv.getInt32(9, true);

    // 13 - 16: timestamp - nanosec - uint32
    this.timeNanosec = dv.getUint32(13, true);

    // 17 - 40: position - float64 * 3
    this.position.x = dv.getFloat64(17, true);
    this.position.y = dv.getFloat64(25, true);
    this.position.z = dv.getFloat64(33, true);

    // 41 - 72: orientation - float64 * 4
    this.orientation.x = dv.getFloat64(41, true);
    this.orientation.y = dv.getFloat64(49, true);
    this.orientation.z = dv.getFloat64(57, true);
    this.orientation.w = dv.getFloat64(65, true);

    return 73; // constant size of a single record
  }

  static fromJSON(json) {
    const lp = new LocalPos();
    Object.assign(lp, JSON.parse(json));
    lp.timeNanosec = lp.timeNanoSec
    return lp;
  }
}
