import React from "react";
import { Button, Form, Modal, ModalBody, ModalFooter } from "reactstrap";

interface Props {
  onClose: () => void;
  setErrorMessage: (val: string) => void;
  handleAction: () => void;
  title: string;
  text: string;
  buttonName: string;
}

const UniversalModal: React.FC<Props> = ({
  onClose,
  setErrorMessage,
  handleAction,
  title,
  text,
  buttonName,
}) => {
  return (
    <Modal isOpen={true} modalClassName="modal-black">
      <div className="modal-header">
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={onClose}
        >
          <i className="tim-icons icon-simple-remove"></i>
        </button>
        <h4 className="modal-title">{title}</h4>
      </div>
      <ModalBody>
        <Form action="" className="form" method="">
          <div className="card-content">{text}</div>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onClose}>
          Close
        </Button>
        <Button color="primary" onClick={() => handleAction()}>
          {buttonName}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default UniversalModal;
