import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

interface SidebarInterface {
  miniSidebar: boolean;
}

export const sidebarAtom = atom<SidebarInterface>({
  key: "sidebar",
  default: {
    miniSidebar: false,
  },
  effects_UNSTABLE: [persistAtom],
});
