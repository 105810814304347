import * as THREE from "three";
import { OBJLoader } from "three/addons/loaders/OBJLoader.js";
import { SVGLoader } from "three/addons/loaders/SVGLoader.js";

import * as PARAMS from "../params.js";
import { loadTextFile } from "../io.js";
import { lineSegmentIntersection, pointToPointDistance } from "./utils/geom-utils.js";

const buildingMaterialParams = {
  color: new THREE.Color(PARAMS.BUILDING_PLAN_WALLS),
  opacity: 0.1,
  transparent: true,
};

const outlineStrokeStyle = {
  color: buildingMaterialParams.color,
  strokeWidth: 0.02,
};

const ROT90 = THREE.MathUtils.degToRad(90);

export class BuildingPlan {
  #projectContext;
  #visible = false;
  #rotation = 0.0;

  mesh;
  meshMaterial;

  outline = [];
  outlineMeshGroup;
  #outlineMaterial;

  boundingBox = { minX: 0, maxX: 0, minY: 0, maxY: 0 };

  constructor(projectContext) {
    this.#projectContext = projectContext;

    this.meshMaterial = new THREE.MeshStandardMaterial(buildingMaterialParams);
    this.#outlineMaterial = new THREE.MeshBasicMaterial({
      color: outlineStrokeStyle.color,
      side: THREE.DoubleSide,
    });
  }

  load(outlineUrl, meshUrl, rotation, onLoaded) {
    let outlineLoaded = false;
    let meshLoaded = false;
    this.loadLines(outlineUrl, () => {
      outlineLoaded = true;
      if (meshLoaded) {
        this.rotateZ(rotation);
        this.visible = true;
        if (onLoaded) onLoaded(this);
      }
    });
    this.loadMesh(meshUrl, () => {
      meshLoaded = true;
      if (outlineLoaded) {
        this.rotateZ(rotation);
        this.visible = true;
        if (onLoaded) onLoaded(this);
      }
    });
  }

  loadLines(url, onLoaded) {
    this.#cleanupOutline();

    this.outlineMeshGroup = new THREE.Group();
    this.#projectContext.scene.add(this.outlineMeshGroup);
    this.outlineMeshGroup.visible = false;
    this.outlineMeshGroup.depthTest = false;

    loadTextFile(url, (text) => {
      let bboxCalcInit = true;
      this.boundingBox = { minX: 0, maxX: 0, minY: 0, maxY: 0 };
      const bboxExpand = 0.5;
      const rotX90 = new THREE.Euler(-ROT90, 0.0, 0.0, "XYZ");
      const lines = JSON.parse(text);
      for (const l of lines) {
        const p1 = new THREE.Vector3(l.x1, l.y1, PARAMS.BUILDING_OUTLINE_Z_POS);
        const p2 = new THREE.Vector3(l.x2, l.y2, PARAMS.BUILDING_OUTLINE_Z_POS);
        const geometry = SVGLoader.pointsToStroke([p1, p2], outlineStrokeStyle);
        const mesh = new THREE.Mesh(geometry, this.#outlineMaterial);
        mesh.depthTest = false;
        this.outlineMeshGroup.add(mesh);

        p1.applyEuler(rotX90);
        p2.applyEuler(rotX90);

        let x1 = p1.x;
        let x2 = p2.x;
        let y1 = p1.z;
        let y2 = p2.z;
        if (x1 > x2) [x1, x2] = [x2, x1];
        if (y1 > y2) [y1, y2] = [y2, y1];
        x1 -= bboxExpand;
        x2 += bboxExpand;
        y1 -= bboxExpand;
        y2 += bboxExpand;

        const line = new THREE.Line3(p1, p2);
        line.bbox = [x1, x2, y1, y2];
        this.outline.push(line);

        if (bboxCalcInit) {
          bboxCalcInit = false;
          this.boundingBox.minX = x1;
          this.boundingBox.maxX = x2;
          this.boundingBox.minY = y1;
          this.boundingBox.maxY = y2;
        } else {
          if (this.boundingBox.minX > x1) this.boundingBox.minX = x1;
          if (this.boundingBox.maxX < x2) this.boundingBox.maxX = x2;
          if (this.boundingBox.minY > y1) this.boundingBox.minY = y1;
          if (this.boundingBox.maxY < y2) this.boundingBox.maxY = y2;
        }
      }

      this.outlineMeshGroup.rotateX(-ROT90);
      this.outlineMeshGroup.updateMatrix();

      if (onLoaded) onLoaded(this);
    });
  }

  loadMesh(url, onLoaded) {
    this.#cleanupMesh();

    new OBJLoader().load(url, (objMesh) => {
      this.mesh = objMesh;
      this.mesh.traverse((child) => {
        if (child instanceof THREE.Mesh) {
          child.material = this.meshMaterial;
          child.castShadow = true;
          child.receiveShadow = true;
          child.depthTest = false;
        }
      });
      this.mesh.castShadow = true;
      this.mesh.receiveShadow = true;
      this.mesh.depthTest = false;
      this.mesh.visible = false;
      this.mesh.rotateX(-ROT90);
      this.mesh.updateMatrix();
      this.#projectContext.scene.add(this.mesh);

      const bbox = new THREE.Box3().setFromObject(this.mesh);
      let divs = Math.floor(bbox.min.distanceTo(bbox.max));
      divs = (divs % 2) ? divs + 1 : divs;
      const grid = new THREE.GridHelper(
        divs,
        divs,
        PARAMS.GRID_HELPER_COLOR_CENTER_LINE,
        PARAMS.GRID_HELPER_COLOR_GRID
      );
      grid.position.set(0, PARAMS.GRID_HELPER_Z_POS, 0);
      grid.material.opacity = 1;
      grid.material.depthWrite = true;
      grid.material.transparent = false;
      this.#projectContext.scene.add(grid);

      if (onLoaded) onLoaded(this);
    });
  }

  get visible() {
    return this.#visible;
  }

  set visible(visible) {
    this.#visible = visible;
    if (this.mesh) this.mesh.visible = visible;
    if (this.outlineMeshGroup) this.outlineMeshGroup.visible = visible;
  }

  get rotation() {
    return this.#rotation;
  }

  set rotation(rot) {
    this.rotateZ(rot);
  }

  rotateZ(deg) {
    this.#rotation = deg;
    const r = THREE.MathUtils.degToRad(deg);
    this.#transform((m) => m.rotation.set(m.rotation.x, m.rotation.y, r));
  }

  moveX(xOffset) {
    this.#transform((m) => m.position.set(xOffset, m.position.y, m.position.z));
  }

  moveZ(zOffset) {
    this.#transform((m) => m.position.set(m.position.x, m.position.y, zOffset));
  }

  #transform(callback) {
    [this.mesh, this.outlineMeshGroup].forEach((m) => {
      callback(m);
    });
  }

  calcCollisionPathGeometry(coords, rounded=false) {
    const points = [];
    for (let n = 0; n < coords.length; ++n) {
      points.push(new THREE.Vector2(coords[n].x, coords[n].y))
    }
    const pathStrokeStyle = {
      strokeWidth: 0.6,
      strokeLineCap: rounded ? "round" : "butt",
      strokeLineJoin: rounded ? "round" :"bevel"
    };
    return SVGLoader.pointsToStroke(points, pathStrokeStyle);
  }

  #widenPath(coords, pathLines) {
    const p = this.calcCollisionPathGeometry(coords).attributes.position.array;
    pathLines.push({ x1: p[ 3], y1: p[ 4], x2: p[ 6], y2: p[ 7] });
    pathLines.push({ x1: p[ 9], y1: p[10], x2: p[15], y2: p[16] });
  }

  findWallIntersection(p1, p2, p3, widen=true) {
    const rotY = new THREE.Matrix4().makeRotationY(THREE.MathUtils.degToRad(-this.#rotation));
    p1?.applyMatrix4(rotY);
    p2.applyMatrix4(rotY);
    p3?.applyMatrix4(rotY);

    const pathLines = [];
    if (widen) {
      if (p1) this.#widenPath([{ x: p1.x, y: p1.z }, { x: p2.x, y: p2.z }], pathLines);
      if (p3) this.#widenPath([{ x: p2.x, y: p2.z }, { x: p3.x, y: p3.z }], pathLines);
    } else {
      if (p1) pathLines.push({ x1: p1.x, y1: p1.z, x2: p2.x, y2: p2.z });
      if (p3) pathLines.push({ x1: p2.x, y1: p2.z, x2: p3.x, y2: p3.z });
    }

    let r = null;
    for (const line of this.outline) {
      for (let pl of pathLines) {
        const s = lineSegmentIntersection(
          { x: pl.x1, y: pl.y1 },
          { x: pl.x2, y: pl.y2 },
          { x: line.start.x, y: line.start.z },
          { x: line.end.x, y: line.end.z }
        );
        if (s.intersects) {
          s.distance = pointToPointDistance(s.i2.x, s.i2.y, pl.x1, pl.y1);
          if (!r || r.distance > s.distance)
            r = structuredClone(s);
        }
      }
    }
    return r;
  }

  distToWall(x, y, minDist=0.3) {
    const rotY = new THREE.Matrix4().makeRotationY(THREE.MathUtils.degToRad(-this.#rotation));
    const pt = new THREE.Vector3(x, 0.0, y).applyMatrix4(rotY);
    const target = new THREE.Vector3();
    let dist = Infinity;
    for (const line of this.outline) {
      if (pt.x < line.bbox[0] || pt.x > line.bbox[1]) continue;
      if (pt.z < line.bbox[2] || pt.z > line.bbox[3]) continue;

      const d = line.closestPointToPoint(pt, true, target).distanceTo(pt);
      if (d < dist) dist = d;
      if (dist <= minDist) break;
    }
    return dist;
  }

  #cleanupOutline() {
    if (this.outlineMeshGroup) {
      this.outlineMeshGroup.traverse(obj3d => {
        if (obj3d.material) obj3d.material.dispose();
        if (obj3d.geometry) obj3d.geometry.dispose();
      });
      this.#projectContext.scene.remove(this.outlineMeshGroup);
      this.outlineMeshGroup = null;
    }
  }

  #cleanupMesh() {
    if (this.mesh) {
      this.mesh.traverse(obj3d => {
        if (obj3d.material) obj3d.material.dispose();
        if (obj3d.geometry) obj3d.geometry.dispose();
      });
      this.#projectContext.scene.remove(this.mesh);
      this.mesh = null;
    }
  }

  dispose() {
    this.#cleanupOutline();
    this.#cleanupMesh();

    if (this.#outlineMaterial) {
      this.#outlineMaterial.dispose();
      this.#outlineMaterial = null;
    }
  }
}
