import React from "react";
import {
  Button,
  CardBody,
  CardTitle,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { Meeting } from "../../../types/meeting";

interface Props {
  onClose: () => void;
  setErrorMessage: (val: string) => void;
  meeting: Meeting | undefined;
  setMeeting: (val: Meeting) => void;
}

const CreateMeetingModal: React.FC<Props> = ({
  onClose,
  setErrorMessage,
  meeting,
  setMeeting,
}) => {
  return (
    <Modal isOpen={true} modalClassName="modal-black">
      <div className="modal-header">
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={onClose}
        >
          <i className="tim-icons icon-simple-remove"></i>
        </button>
      </div>
      <ModalBody>
        <Form action="" className="form" method="">
          <div className="card-content">
            <CardTitle tag="h4">Edit meeting</CardTitle>
            <CardBody>
              <Form action="#">
                <label>Meeting name</label>
                <FormGroup>
                  <Input type="text" value={meeting?.name} />
                </FormGroup>
                <FormGroup>
                  <Label for="exampleDate">Date</Label>
                  <Input
                    type="date"
                    name="date"
                    id="exampleDate"
                    placeholder="date placeholder"
                    style={{ color: "white" }}
                    value={meeting?.date}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="exampleTime">Time</Label>
                  <Input
                    type="time"
                    name="time"
                    id="exampleTime"
                    placeholder="time placeholder"
                    value={meeting?.time}
                  />
                </FormGroup>
              </Form>
            </CardBody>
          </div>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onClose}>
          Close
        </Button>
        <Button className="btn-fill" color="primary" type="submit">
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CreateMeetingModal;
