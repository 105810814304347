import { Meeting } from "../types/meeting";

export const mockMeeting: Meeting = {
  name: "Morning Meeting",
  date: "2023-04-04",
  time: "11:30",
  invitedContributors: null,
};

export const fetchMockMeeting = (): Promise<Meeting> => {
  return new Promise((resolve) => {
    resolve(mockMeeting);
  });
};
