import { User, userColors } from "../types/user";
import { uniqueId } from "lodash";

export const mockUsers: User[] = [
  {
    userId: uniqueId(),
    projectId: uniqueId(),
    name: "Andrew Mike",
    email: "andrewmike@gmail.com",
    role: "Architect",
    userColor: userColors[Math.floor(Math.random() * 11)],
    creationTime: ''
  },
  {
    userId: uniqueId(),
    projectId: uniqueId(),
    name: "Alex Mike",
    email: "a.mike@gmail.com",
    role: "Engineer",
    userColor: userColors[Math.floor(Math.random() * 11)],
    creationTime: ''

  },
  {
    userId: uniqueId(),
    projectId: uniqueId(),
    name: "Manuel Rico",
    email: "manuel.rico@gmail.com",
    role: "Guest",
    userColor: userColors[Math.floor(Math.random() * 11)],
    creationTime: ''
  },
];

export const fetchMockUsers = (): Promise<User[]> => {
  return new Promise((resolve) => {
    resolve(mockUsers);
  });
};
