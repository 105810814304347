import React from "react";
import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";

import {
  BuildingPlan,
  FlightPlanType,
  Mission,
  Project,
} from "../../types/project";
import { Marker } from "../../types/marker";
import { UserInfoInterface } from "../../recoil/atoms/userInfo.atom";
import { User } from "../../types/user";

type ReportPdfProps = {
  project: Project | undefined;
  buildingPlan: BuildingPlan | undefined;
  flightPlan: FlightPlanType | undefined;
  mission: Mission | undefined;
  markers: Marker[];
  userInfo: UserInfoInterface;
  allUsers: User[];
  participants: User[];
};

const space = 24;
const baseFontSize = 8;

Font.register({
  family: "Poppins",
  format: "truetype",
  fonts: [
    {
      src: "/assets/fonts/Poppins-Regular.ttf",
      fontWeight: 400,
    },
    {
      src: "/assets/fonts/Poppins-Italic.ttf",
      fontStyle: "italic",
      fontWeight: 400,
    },
    {
      src: "/assets/fonts/Poppins-Medium.ttf",
      fontWeight: 500,
    },
    {
      src: "/assets/fonts/Poppins-SemiBold.ttf",
      fontWeight: 600,
    },
    {
      src: "/assets/fonts/Poppins-Bold.ttf",
      fontWeight: 700,
    },
  ],
});

const styles = StyleSheet.create({
  page: { fontFamily: "Poppins", fontSize: baseFontSize, margin: space },
  titleContainer: {
    marginBottom: baseFontSize * 2,
    marginRight: 2 * space,
    textAlign: "center",
  },
  bigTitle: {
    fontSize: baseFontSize * 2,
  },
  date: {
    textAlign: "center",
  },
  title: {
    fontSize: baseFontSize * 1.5,
    fontWeight: "normal",
    marginBottom: baseFontSize,
  },
  titleBold: {
    fontSize: baseFontSize * 1.5,
    fontWeight: "semibold",
    marginBottom: baseFontSize,
  },
  textBold: {
    fontWeight: "semibold",
  },
  orderNumber: {
    minWidth: baseFontSize,
  },
  image: { maxWidth: "100%", marginRight: 2 * space },
  section: {
    marginTop: baseFontSize * 2,
    marginBottom: 0,
    marginRight: 2 * space,
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
  comment: {
    flex: 1,
    maxWidth: "100%",
    wordWrap: "break-word",
    overflow: "hidden",
  },
});

const ReportPdf: React.FC<ReportPdfProps> = ({
  project,
  buildingPlan,
  flightPlan,
  mission,
  markers,
  userInfo,
  allUsers,
  participants,
}) => {
  const thisUser = allUsers.find((u) => u.userId === userInfo.userId);

  const getImage = () => {
    const canvas = document.querySelector(
      ".report-preview canvas"
    ) as HTMLCanvasElement;

    return canvas?.toDataURL("image/png");
  };

  return (
    <Document>
      <Page size="A4" wrap={true} style={styles.page}>
        <View>
          <View style={styles.titleContainer}>
            <Text style={styles.bigTitle}>Analysis Report</Text>
            <Text>{new Date().toLocaleString("pl-PL")}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.title}>Project: </Text>
            <Text style={styles.titleBold}>{project?.name}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.title}>Building Plan: </Text>
            <Text style={styles.titleBold}>{buildingPlan?.name}</Text>
          </View>

          <Image style={styles.image} src={getImage} />

          <View style={styles.section}>
            <Text style={styles.title}>Participants: </Text>
            {participants.map((user) => (
              <View style={styles.row} key={user.userId}>
                <Text style={styles.textBold}>{user.name} </Text>
                <Text>({user.email}) </Text>
                <Text>{user.role}</Text>
              </View>
            ))}
          </View>

          <View style={styles.section}>
            <Text style={styles.title}>Comments: </Text>
            {markers.map((marker) => {
              const user = allUsers.find((u) => u.userId === marker.userId);
              return (
                <View style={styles.row} key={marker.markerId}>
                  <Text style={styles.orderNumber}>{marker.label}</Text>
                  <Text style={styles.textBold}> {user?.name}: </Text>
                  <Text style={styles.comment}>{marker.comment.split("")}</Text>
                </View>
              );
            })}
          </View>

          <View style={styles.section}>
            <Text style={styles.title}>Accepted by: </Text>
            <Text>
              {thisUser?.name} ({thisUser?.email}) {thisUser?.role}
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default ReportPdf;
