import * as THREE from "three";

const droneColor = new THREE.Color('firebrick');
const droneShape = new THREE.Shape()
    .moveTo(0, -1.5)
    .lineTo(2.5, 1.5)
    .lineTo(0, 1.0)
    .lineTo(-2.5, 1.5)
    .lineTo(0, -1.5);
const extrudeSettings = {
    steps: 1,
    depth: 0.1
};


const DRONE_SCALE = 0.12;
const ROT90 = THREE.MathUtils.degToRad(90);
const RX = new THREE.Matrix4().makeRotationX(ROT90);
const RY = new THREE.Matrix4().makeRotationY(ROT90);
const MTX_ROT = new THREE.Matrix4().multiply(RY.multiply(RX));
const MTX_SCALE = new THREE.Matrix4().makeScale(DRONE_SCALE, DRONE_SCALE, DRONE_SCALE);


export class Drone {
  #projectContext
  #droneMesh;
  #pos;
  #globalRotation = new THREE.Matrix4();

  constructor(projectContext) {
    this.#projectContext = projectContext;

    const droneGeometry = new THREE.ExtrudeGeometry(droneShape, extrudeSettings);
    const droneMaterial = new THREE.MeshBasicMaterial({ color: droneColor })
    this.#droneMesh = new THREE.Mesh(droneGeometry, droneMaterial);
    this.#droneMesh.geometry.computeBoundingBox();
    this.#droneMesh.geometry.computeBoundingSphere();
    this.#droneMesh.matrixAutoUpdate = false;
    this.#droneMesh.visible = false;
    this.#projectContext.scene.add(this.#droneMesh);

    this.setPos({
      position: {
        x: 0.0,
        y: 0.0,
        z: 0.0
      },
      orientation: {
        x: 0.0,
        y: 0.0,
        z: 0.0,
        w: 0.0
      }
    });
  }

  setPos(pos) {
    this.#pos = pos;
    this.#update();
  }

  get visible() {
    return this.#droneMesh.visible;
  }

  set visible(visible) {
    this.#droneMesh.visible = visible;
  }

  set globalRotation(gr) {
    this.#globalRotation = new THREE.Matrix4().makeRotationY(THREE.MathUtils.degToRad(gr));
    this.#update();
  }

  dispose() {
    if (this.#droneMesh) {
      this.#droneMesh.material.dispose();
      this.#droneMesh.geometry.dispose();
      this.#projectContext.scene.add(this.#droneMesh);
      this.#droneMesh = null;
    }
  }

  #update() {
    if (this.#droneMesh) {
      const p = this.#pos.position;
      const o = this.#pos.orientation;
      const t = new THREE.Matrix4().makeTranslation(p.x, p.y, p.z);
      const s = MTX_SCALE.clone();
      const q = new THREE.Quaternion(o.x, o.z, o.y, o.w);
      const r = new THREE.Matrix4()
        .makeRotationFromQuaternion(q)
        .multiply(this.#globalRotation);

      this.#droneMesh.matrix = t.multiply(s.multiply(r.multiply(MTX_ROT)));
    }
  }
}
