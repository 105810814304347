import React, { useEffect, useState } from "react";
import {
  Button,
  CardBody,
  CardTitle,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { CreateNewProject } from "../../../types/project";
import "./styles.scss";
import schema from "./schema";

interface Props {
  onClose: () => void;
  postData: (obj: CreateNewProject) => void;
  setErrorMessage: (val: string) => void;
}

const AddNewProjectModal: React.FC<Props> = ({
  onClose,
  setErrorMessage,
  postData,
}) => {
  const [newProject, setNewProject] = useState<CreateNewProject>({
    name: "",
    address: "",
  });
  const [validationErrors, setValidationErrors] = useState<CreateNewProject>({
    name: "",
    address: "",
  });
  const [initValidation, setInitValidation] = useState<boolean>(false);

  useEffect(() => {
    const validation = () => {
      const validationResult = schema.safeParse(newProject);
      if (!validationResult.success) {
        const formattedErrors = validationResult.error.format();
        if (formattedErrors._errors) {
          setValidationErrors({
            name: formattedErrors.name?._errors.join(", ") || "",
            address: formattedErrors.address?._errors.join(", ") || "",
          });
        }
      } else {
        setValidationErrors({
          name: "",
          address: "",
        });
      }
    };
    if (initValidation) {
      validation();
    }
  }, [initValidation, newProject]);
  return (
    <Modal isOpen={true} modalClassName="modal-black">
      <div className="modal-header">
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={onClose}
        >
          <i className="tim-icons icon-simple-remove"></i>
        </button>
      </div>
      <ModalBody>
        <Form action="" className="form" method="">
          <div className="card-content">
            <CardTitle tag="h4">Create new project</CardTitle>
            <CardBody>
              <Form action="#">
                <Label for="projectName">Project name</Label>
                <FormGroup>
                  <div className="inputContainer">
                    <Input
                      id="projectName"
                      type="text"
                      value={newProject.name}
                      onChange={(e) => {
                        const newName = e.target.value;
                        setNewProject((prevProject) => ({
                          ...prevProject,
                          name: newName,
                        }));
                      }}
                    />
                    {
                      <div
                        className="text-danger"
                        style={{
                          display: validationErrors.name ? "block" : "none",
                        }}
                      >
                        {validationErrors.name}
                      </div>
                    }
                  </div>
                </FormGroup>
                <FormGroup>
                  <Label for="projectAddress">Address</Label>
                  <div className="inputContainer">
                    <Input
                      type="text"
                      id="projectAddress"
                      value={newProject.address}
                      onChange={(e) => {
                        const newAddress = e.target.value;
                        setNewProject((prevProject) => ({
                          ...prevProject,
                          address: newAddress,
                        }));
                      }}
                    />
                    {
                      <div
                        className="text-danger"
                        style={{
                          display: validationErrors.address ? "block" : "none",
                        }}
                      >
                        {validationErrors.address}
                      </div>
                    }
                  </div>
                </FormGroup>
              </Form>
            </CardBody>
          </div>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onClose}>
          Close
        </Button>
        <Button
          className="btn-fill"
          color="primary"
          type="submit"
          onClick={() => {
            setInitValidation(true);
            const validationResult = schema.safeParse(newProject);
            if (validationResult.success) {
              postData(newProject);
            }
          }}
        >
          Add
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddNewProjectModal;
