import * as THREE from "three";

import * as PARAMS from "../params.js";
import { labelFont, createTextGeometry } from "./text.js";

const textMaterial = new THREE.MeshBasicMaterial({
  color: new THREE.Color(0x000000),
  side: THREE.DoubleSide,
});
const textBackMaterial = new THREE.MeshBasicMaterial({
  color: new THREE.Color(0xFFFFFF),
  side: THREE.DoubleSide,
});

const ROT90 = THREE.MathUtils.degToRad(90);
const ROT180 = ROT90 * 2;

const Z_POS_DELTA = 0.0001;
const ARROW_Z_POS = PARAMS.MEASURINGS_Z_POS;
const ARROW_SEL_Z_POS = ARROW_Z_POS - Z_POS_DELTA;
const TEXT_Z_POS = ARROW_SEL_Z_POS - Z_POS_DELTA;
const TEXT_BACK_Z_POS = TEXT_Z_POS - Z_POS_DELTA;

export const DEFAULT_LABEL_FONT_SIZE = 0.05;

export const LabelZoomMode = Object.freeze({
  Zoomable: Symbol("Zoomable"),
  Fixed: Symbol("Fixed")
});

export const LabelHorizAlignMode = Object.freeze({
  Left: Symbol("Left"),
  Center: Symbol("Center"),
  Right: Symbol("Right")
});

export const LabelVertAlignMode = Object.freeze({
  Top: Symbol("Top"),
  Center: Symbol("Center"),
  Bottom: Symbol("Bottom")
});


export class Label {
  #projectContext;
  #textMesh;
  #textGeom;
  #textBack;
  #textBackGeom;
  #zoomMode;
  #horizAlign;
  #vertAlign;

  #text = "";
  #fontSize = DEFAULT_LABEL_FONT_SIZE;
  #pos = new THREE.Vector3();

  constructor(projectContext, text, fontSize, pos,
              zoomMode=LabelZoomMode.Zoomable,
              horizAlign=LabelHorizAlignMode.Center,
              vertAlign=LabelVertAlignMode.Center) {
    this.#projectContext = projectContext;
    this.#text = text;
    this.#fontSize = fontSize;
    this.#pos = pos;
    this.#zoomMode = zoomMode;
    this.#horizAlign = horizAlign;
    this.#vertAlign = vertAlign;
    this.#update(text, fontSize, pos);
  }

  setText(t) {
    this.#text = t;
    this.#update();
  }

  setFontSize(s) {
    this.#fontSize = s;
    this.#update();
  }

  setPos(p) {
    if (this.#textMesh) {
      const textSize = this.#getTextSize();
      let mx = p.x;
      let my = p.z;
      let tx = p.x;
      let ty = p.z;
      switch (this.#horizAlign) {
        case LabelHorizAlignMode.Left:
          mx -= textSize.x;
          tx -= textSize.x / 2.0;
          break;
        case LabelHorizAlignMode.Center:
          mx -= textSize.x / 2.0;
          break;
        case LabelHorizAlignMode.Right:
          tx += textSize.x / 2.0;
          break;
        default:
          break;
      }
      switch (this.#vertAlign) {
        case LabelVertAlignMode.Top:
          my += textSize.y;
          ty += textSize.y / 2.0;
          break;
        case LabelVertAlignMode.Center:
          my += textSize.y / 2.0;
          break;
        case LabelVertAlignMode.Bottom:
          ty -= textSize.y / 2.0;
          break;
        default:
          break;
      }
      this.#textMesh.position.set(mx, TEXT_Z_POS, my);
      this.#textBack.position.set(tx, TEXT_BACK_Z_POS, ty);
    }
  }

  setZoomMode(zoomMode) {
    const changed = this.#zoomMode !== zoomMode;
    if (changed) {
      this.#zoomMode = zoomMode;
      this.#update();
    }
  }

  calcBox3() {
    return new THREE.Box3().setFromObject(this.#textBack);
  }

  #getTextSize() {
    if (this.#textMesh) {
      const textSize = new THREE.Vector3();
      this.#textGeom.boundingBox.getSize(textSize);
      return textSize;
    } else {
      return new THREE.Vector3();
    }
  }

  dispose() {
    if (this.#textMesh) {
      this.#projectContext.scene.remove(this.#textMesh);
      this.#textMesh = null;
      this.#textGeom.dispose();
      this.#textGeom = null;
    }
    if (this.#textBack) {
      this.#projectContext.scene.remove(this.#textBack);
      this.#textBack = null;
      this.#textBackGeom.dispose();
      this.#textBackGeom = null;
    }
  }

  #update() {
    this.dispose();

    let fontSize = this.#fontSize;
    if (this.#zoomMode === LabelZoomMode.Fixed) {
      fontSize *= (1.0 / this.#projectContext.camera.zoom);
    }

    this.#textGeom = createTextGeometry(this.#text, labelFont, fontSize);
    this.#textMesh = this.#textGeom
      ? new THREE.Mesh(this.#textGeom, textMaterial)
      : undefined;
    if (this.#textMesh) {
      this.#textMesh.rotation.set(ROT90, 0.0, ROT180);
      this.#textMesh.scale.set(-1, 1);
      this.#projectContext.scene.add(this.#textMesh);

      const textSize = this.#getTextSize();
      this.#textBackGeom = new THREE.PlaneGeometry(textSize.x * 1.2, textSize.y * 1.2);
      this.#textBack = new THREE.Mesh(this.#textBackGeom, textBackMaterial);
      this.#textBack.rotation.set(ROT90, ROT180, ROT180);
      this.#textBack.scale.set(-1, 1);
      this.#projectContext.scene.add(this.#textBack);

      this.setPos(this.#pos);
    }
  }
};