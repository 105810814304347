import React, { useCallback, useEffect, useState } from "react";
import { IconContext } from "react-icons";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import {
  IoIosArrowBack,
  IoIosArrowDown,
  IoIosArrowForward,
  IoIosArrowUp,
} from "react-icons/io";
import { Direction } from "../FlightControlPanel";
import "./styles.scss";
import { securedApi } from "../../../api";
import { useParams } from "react-router-dom";
import { FlightParams } from "../../../pages/Flight/Flight";

interface Props {
  positionRotation: number;
  handleRotation: (val: number) => void;
}
const Protractor: React.FC<Props> = ({ positionRotation, handleRotation }) => {
  const deltaPosition = 30;
  const { projectId, buildingPlanId, flightPlanId, missionId } =
    useParams<FlightParams>();

  const [highlightForward, setHighlightForward] = useState<boolean>(false);
  const [highlightLeft, setHighlightLeft] = useState<boolean>(false);
  const [highlightRight, setHighlightRight] = useState<boolean>(false);
  const [highlightBackward, setHighlightBackward] = useState<boolean>(false);
  const [highlightRotateLeft, setHighlightRotateLeft] =
    useState<boolean>(false);
  const [highlightRotateRight, setHighlightRotateRight] =
    useState<boolean>(false);

  const updatePosition = useCallback(
    (data: any) => {
      securedApi
        .post(
          `/projects/${projectId}/buildingPlans/${buildingPlanId}/flightPlans/${flightPlanId}/missions/${missionId}/sendCommand`,
          data
        )
        .catch((e) => console.error(e));
    },
    [buildingPlanId, flightPlanId, missionId, projectId]
  );

  const updateDronePosition = useCallback(
    (direction: Direction) => {
      if (direction === Direction.Forward) {
        updatePosition({ type: "forward", data: { distance: deltaPosition } });
        setHighlightForward(true);
        setTimeout(() => {
          setHighlightForward(false);
        }, 500);
      } else if (direction === Direction.Left) {
        updatePosition({ type: "left", data: { distance: deltaPosition } });
        setHighlightLeft(true);
        setTimeout(() => {
          setHighlightLeft(false);
        }, 500);
      } else if (direction === Direction.Right) {
        updatePosition({ type: "right", data: { distance: deltaPosition } });
        setHighlightRight(true);
        setTimeout(() => {
          setHighlightRight(false);
        }, 500);
      } else if (direction === Direction.Backward) {
        updatePosition({ type: "back", data: { distance: deltaPosition } });
        setHighlightBackward(true);
        setTimeout(() => {
          setHighlightBackward(false);
        }, 500);
      } else if (direction === Direction.RotateLeft) {
        updatePosition({ type: "rotate", data: { angle: -15 } });
        setHighlightRotateLeft(true);
        setTimeout(() => {
          setHighlightRotateLeft(false);
        }, 500);
      } else if (direction === Direction.RotateRight) {
        updatePosition({ type: "rotate", data: { angle: 15 } });
        setHighlightRotateRight(true);
        setTimeout(() => {
          setHighlightRotateRight(false);
        }, 500);
      }
    },
    [updatePosition]
  );

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      switch (event.key) {
        case "w":
          updateDronePosition(Direction.Forward);
          break;
        case "s":
          updateDronePosition(Direction.Backward);
          break;
        case "a":
          updateDronePosition(Direction.Left);
          break;
        case "d":
          updateDronePosition(Direction.Right);
          break;
        case "q":
          handleRotation(Direction.RotateLeft);
          updateDronePosition(Direction.RotateLeft);
          break;
        case "e":
          handleRotation(Direction.RotateRight);
          updateDronePosition(Direction.RotateRight);
          break;
        default:
          break;
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleRotation, updateDronePosition]);

  return (
    <div className="protractorContainer">
      <div className="buttons">
        <div className="rotationArrowButtons">
          <div
            className={`transparent-button pr-4 ${
              highlightRotateLeft ? "highlight-icon" : ""
            }`}
            onClick={() => {
              handleRotation(Direction.RotateLeft);
              updateDronePosition(Direction.RotateLeft);
            }}
          >
            <IconContext.Provider value={{ size: "2rem" }}>
              <BsArrowLeft style={{ color: "white" }} />
            </IconContext.Provider>
          </div>
          <div
            className={`transparent-button ${
              highlightRotateRight ? "highlight-icon" : ""
            }`}
            onClick={() => {
              handleRotation(Direction.RotateRight);
              updateDronePosition(Direction.RotateRight);
            }}
          >
            <IconContext.Provider value={{ size: "2rem" }}>
              <BsArrowRight style={{ color: "white" }} />
            </IconContext.Provider>
          </div>
        </div>
      </div>
      <div className="protractor">
        <img
          className="protractorIMG"
          src={require("./protractor.png")}
          style={{ rotate: `${positionRotation}deg` }}
          alt="protractor"
        />
        <img
          className="protractorPointerIMG"
          src={require("./protractor_double_pointer.png")}
          alt="protractor pointer"
        />

        <div className="droneFromAbove">
          <img
            className="droneFromAboveIMG"
            src={require("./daave_from_above.png")}
            alt="drone from above"
          />
          <div className="control-buttons-container">
            <div className="grid-container">
              <div className="grid-item"></div>
              <div
                className={`grid-item transparent-button ${
                  highlightForward ? "highlight-icon" : ""
                }`}
                onClick={() => {
                  updateDronePosition(Direction.Forward);
                }}
              >
                <div>
                  <IconContext.Provider value={{ size: "2rem" }}>
                    <IoIosArrowUp style={{ color: "white" }} />
                  </IconContext.Provider>
                </div>
              </div>
              <div className="grid-item"></div>
              <div
                className={`grid-item transparent-button ${
                  highlightLeft ? "highlight-icon" : ""
                }`}
                onClick={() => {
                  updateDronePosition(Direction.Left);
                }}
              >
                <div>
                  <IconContext.Provider value={{ size: "2rem" }}>
                    <IoIosArrowBack style={{ color: "white" }} />
                  </IconContext.Provider>
                </div>
              </div>
              <div className="grid-item"></div>
              <div
                className={`grid-item transparent-button ${
                  highlightRight ? "highlight-icon" : ""
                }`}
                onClick={() => {
                  updateDronePosition(Direction.Right);
                }}
              >
                <div>
                  <IconContext.Provider value={{ size: "2rem" }}>
                    <IoIosArrowForward style={{ color: "white" }} />
                  </IconContext.Provider>
                </div>
              </div>

              <div className="grid-item"></div>
              <div
                className={`grid-item transparent-button ${
                  highlightBackward ? "highlight-icon" : ""
                }`}
                onClick={() => {
                  updateDronePosition(Direction.Backward);
                }}
              >
                <div>
                  <IconContext.Provider value={{ size: "2rem" }}>
                    <IoIosArrowDown
                      style={{
                        color: "white",
                      }}
                    />
                  </IconContext.Provider>
                </div>
              </div>
              <div className="grid-item"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Protractor;
