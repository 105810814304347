import React, { useCallback, useEffect, useState } from "react";
import { Button } from "reactstrap";
import { IconContext } from "react-icons";
import { HiPlayPause } from "react-icons/hi2";
import { GiLandMine } from "react-icons/gi";
import { SlSettings } from "react-icons/sl";
import SelectDroneModal from "../../Modals/SelectDroneModal/SelectDroneModal";
import { Mission } from "../../../types/project";
import { Drone } from "../../../types/drone";
import { ImSwitch } from "react-icons/im";
import { securedApi } from "../../../api";
import { useParams } from "react-router-dom";
import { FlightParams } from "../../../pages/Flight/Flight";
import axios from "axios";
import { useRecoilState } from "recoil";
import { flightScreenAtom } from "../../../recoil/atoms/flightScreen.atom";

interface SelectDrone {
  name: "SelectDrone";
}

type VisibleModal = SelectDrone | undefined;

interface Props {
  isFlying: boolean;
  mission: Mission | undefined;
}
const ControlButtons: React.FC<Props> = ({ isFlying, mission }) => {
  const { projectId, buildingPlanId, flightPlanId, missionId } =
    useParams<FlightParams>();
  const [selectedDrone, setSelectedDrone] = useState<Drone | undefined>();
  const [visibleModal, setVisibleModal] = useState<VisibleModal>();
  const [, setErrorMessage] = useState<string>("");

  const [flightScreen, setFlightScreen] = useRecoilState(flightScreenAtom);

  useEffect(() => {
    if (selectedDrone === undefined) {
      securedApi.get(`/uavs/${mission?.uavId || ""}`).then((res) => {
        setSelectedDrone(res.data);
      });
    }
  }, [mission, selectedDrone]);

  const turnOnDrone = () => {
    setFlightScreen((flightScreen) => ({
      ...flightScreen,
      visibleStream: true,
      isDroneLoading: true,
    }));

    securedApi
      .post(
        `/projects/${projectId}/buildingPlans/${buildingPlanId}/flightPlans/${flightPlanId}/missions/${missionId}/start`,
        { id: selectedDrone?.id }
      )
      .then(() =>
        securedApi.post(
          `/projects/${projectId}/buildingPlans/${buildingPlanId}/flightPlans/${flightPlanId}/missions/${missionId}/sendCommand`,
          { type: "executePlan" }
        )
      );
    axios
      .post(
        "https://0gr675bzb8.execute-api.eu-central-1.amazonaws.com/default/uav-stream-video-trigger",
        { body: { mission_id: missionId } }
      )
      .then((res) => console.log("LOAD VIDEO", res))
      .catch((err) => console.error(err));
    setTimeout(() => {
      setFlightScreen((flightScreen) => ({
        ...flightScreen,
        isDroneLoading: false,
      }));
    }, 26000);
  };

  const startFlight = () => {
    console.log("start aws lambda");
    resumePausePlan();
    axios
      .post(
        "https://0gr675bzb8.execute-api.eu-central-1.amazonaws.com/default/uav-emulator"
      )
      .catch((err) => console.error(err));

    setFlightScreen((flightScreen) => ({
      ...flightScreen,
      startVideo: true,
    }));
  };

  const resumePausePlan = useCallback(() => {
    securedApi
      .post(
        `/projects/${projectId}/buildingPlans/${buildingPlanId}/flightPlans/${flightPlanId}/missions/${missionId}/sendCommand`,
        isFlying ? { type: "planResume" } : { type: "planPause" }
      )
      .catch((e) => console.error(e));
  }, [buildingPlanId, flightPlanId, isFlying, missionId, projectId]);

  const droneLanding = () => {
    securedApi
      .post(
        `/projects/${projectId}/buildingPlans/${buildingPlanId}/flightPlans/${flightPlanId}/missions/${missionId}/sendCommand`,
        { type: "land" }
      )
      .catch((e) => console.error(e));
  };

  const handleModalClose = useCallback(() => {
    setVisibleModal(undefined);
  }, []);

  const handleModalOpen = useCallback((modalType: any) => {
    setVisibleModal({ name: modalType });
  }, []);

  const renderModals = useCallback(() => {
    if (visibleModal !== undefined) {
      switch (visibleModal.name) {
        case "SelectDrone":
          return (
            <SelectDroneModal
              onClose={handleModalClose}
              setErrorMessage={setErrorMessage}
              selectedDrone={selectedDrone}
              setSelectedDrone={setSelectedDrone}
              mission={mission}
            />
          );
        default:
          break;
      }
    }
  }, [handleModalClose, mission, selectedDrone, visibleModal]);

  return (
    <div className="droneControllerButtons d-flex">
      {renderModals()}
      <div className="buttons">
        <div className="flightControl">
          <Button
            size="sm"
            data-toggle="tooltip"
            data-placement="top"
            title={`${isFlying ? "Pause Flight" : "Resume Flight"}`}
            color={`${flightScreen.startVideo ? "success" : "danger"}`}
            disabled={
              !selectedDrone?.id ||
              flightScreen.startVideo ||
              !flightScreen.visibleStream
            }
            onClick={startFlight}
          >
            <IconContext.Provider value={{ size: "1.5rem" }}>
              <HiPlayPause />
            </IconContext.Provider>
          </Button>
          <Button
            size="sm"
            data-toggle="tooltip"
            data-placement="top"
            title="Land here"
            disabled={
              !selectedDrone?.id ||
              flightScreen.startVideo ||
              !flightScreen.visibleStream
            }
            onClick={droneLanding}
          >
            <IconContext.Provider value={{ size: "1.5rem" }}>
              <GiLandMine />
            </IconContext.Provider>
          </Button>
        </div>
        <div className="droneName pl-2 d-flex align-items-center">
          Drone assigned:{" "}
          <div className="pl-1" style={{ color: "#e14eca" }}>
            {selectedDrone?.name}
          </div>
        </div>
        <div className="settings">
          <Button
            size="sm"
            data-toggle="tooltip"
            data-placement="top"
            title="Select drone"
            disabled={flightScreen.startVideo}
            onClick={() => handleModalOpen("SelectDrone")}
          >
            <IconContext.Provider value={{ size: "1.5rem" }}>
              <SlSettings />
            </IconContext.Provider>
          </Button>
          <Button
            size="sm"
            data-toggle="tooltip"
            data-placement="top"
            title={`${
              flightScreen.startVideo ? "Turn Off drone" : "Turn On drone"
            }`}
            onClick={() => turnOnDrone()}
            disabled={!selectedDrone?.id || flightScreen.startVideo}
            color={`${flightScreen.visibleStream ? "success" : "danger"}`}
          >
            <IconContext.Provider value={{ size: "1.5rem" }}>
              <ImSwitch />
            </IconContext.Provider>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ControlButtons;
