import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";

export type Project = {
  id: string;
  name: string;
  address?: string;
  location?: string;
};

interface Props {
  projects: Project[];
}

type Position = {
  lat: number;
  lng: number;
};

type MapMarker = { project: Project; position: Position };

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const defaultCenter = {
  lat: 52.2,
  lng: 21.0,
};
const defaultZoom = 12;

function isDefined<T>(argument: T | undefined): argument is T {
  return argument !== undefined;
}

async function fetchGeocode(address: string): Promise<Position> {
  const apiKey = process.env.REACT_APP_OPEN_CAGE_API_KEY;
  const url = `https://api.opencagedata.com/geocode/v1/json?q=${encodeURIComponent(
    address
  )}&key=${apiKey}`;

  const response = await fetch(url);
  const data = await response.json();

  if (data.results && data.results.length > 0) {
    return data.results[0].geometry;
  } else {
    throw new Error("Failed to geocode address");
  }
}

const CustomMap: React.FC<{
  markers: Array<{ position: Position }>;
}> = ({ markers }) => {
  const map = useMap();

  useEffect(() => {
    if (markers.length > 0) {
      const bounds = L.latLngBounds(markers.map((marker) => marker.position));
      map.fitBounds(bounds, { padding: [50, 50] });
    }
  }, [markers, map]);

  return null;
};

export const LeafletMap: React.FC<Props> = ({ projects }) => {
  const [markers, setMarkers] = useState<MapMarker[]>([]);

  useEffect(() => {
    async function fetchMarkers() {
      const fetchedMarkers: Array<MapMarker | undefined> = await Promise.all(
        projects.map(async (project) => {
          const location = (project.location || "")
            .split(",")
            .map((coord) => Number(coord));
          if (location.length > 1) {
            return {
              project,
              position: { lat: location[0], lng: location[1] },
            };
          } else if (project.address) {
            const position = await fetchGeocode(project.address);
            return { project, position };
          } else {
            console.warn("Invalid address and position data for ", project);
            return undefined;
          }
        })
      );

      const validMarkers: MapMarker[] = fetchedMarkers.filter(isDefined);

      setMarkers(validMarkers);
    }

    fetchMarkers();
  }, [projects]);

  return (
    <MapContainer
      center={defaultCenter}
      zoom={defaultZoom}
      style={{ height: "100%", width: "100%" }}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      />
      {markers.map((marker, idx) => (
        <Marker key={idx} position={marker.position}>
          <Popup>
            <a href={"/projects/" + marker.project.id}>{marker.project.name}</a>
          </Popup>
        </Marker>
      ))}
      <CustomMap markers={markers} />
    </MapContainer>
  );
};

export default LeafletMap;
