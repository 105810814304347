import React, { useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import CustomSpinner from "../../CustomSpinner/CustomSpinner";
import "./styles.scss";
import { Line } from "rc-progress";

interface Props {
  onClose: () => void;
  isLoading: boolean | undefined;
}

const DroneLoadingModal: React.FC<Props> = ({ isLoading, onClose }) => {
  const [value, setValue] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setValue((oldValue) => {
        if (oldValue < 100) {
          return oldValue + 1;
        } else {
          clearInterval(interval);
          return oldValue;
        }
      });
    }, 260);

    return () => clearInterval(interval);
  }, []);

  return (
    <Modal
      isOpen={true}
      backdrop="static"
      modalClassName="modal-black"
      fade={true}
    >
      <div className="modal-header">
        <h5>Drone loading</h5>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={onClose}
        >
          <i className="tim-icons icon-simple-remove"></i>
        </button>
      </div>
      <ModalBody>
        <h3>Drone is setting up...</h3>
        <Line percent={value} strokeWidth={4} strokeColor="#D3D3D3" />
        <div className="pt-4"></div>
        <CustomSpinner />
      </ModalBody>
    </Modal>
  );
};

export default DroneLoadingModal;
