export const MARKER_TYPES = {
  PIN_MARKER: "PIN_MARKER",
  MEASUREMENT: "MEASUREMENT",
  FLIGHT_PATH: "FLIGHT_PATH",
};

export const MARKER_EVENTS = {
  CREATED: "CREATED",
  SELECTED: "SELECTED",
  UPDATED: "UPDATED",
  DELETED: "DELETED",
};

// Layers Z order
export const Z_POS_DELTA = 0.00001;
export const GROUND_Z_POS = -0.01;
export const GRID_HELPER_Z_POS = 0.01;
export const OCC_GRID_Z_POS = 0.03;
export const BUILDING_OUTLINE_Z_POS = 0.04;
export const FLIGHT_PLAN_Z_POS = 1.5;
export const MEASURINGS_Z_POS = 0.06;

// Colors
export const GRID_HELPER_COLOR_GRID = 0xaaaaaa;
export const GRID_HELPER_COLOR_CENTER_LINE = 0xff7777;

export const FLIGHT_PLAN_ARROW = 0xb23333;
export const FLIGHT_PLAN_SMALL_ARROW = 0xff1919;
export const FLIGHT_PLAN_SELECTED_NODE = 0x888888;
export const FLIGHT_PLAN_HOVERED_NODE = 0xaaaaaa;

export const MEASURE_ARROW_COLOR = 0x000000;

export const BUILDING_PLAN_WALLS = 0x2d466b;

// Occupancy grid
export const OCC_GRID_POINT_MAX_VALUE = 100.0;

// File paths
export const PATHS = {
  IMAGES: "/assets/images",
};
