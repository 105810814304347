import React, { useCallback, useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import BuildingPlanCard from "../BuildingPlanCard/BuildingPlanCard";
import "./styles.scss";
import DragAndDropUpload from "../DragAndDropUpload/DragAndDropUpload";
import AddNewBuildingPlanModal from "../Modals/AddNewBuildingPlanModal/AddNewBuildingPlanModal";
import { BuildingPlan, Mission } from "../../types/project";
import { useRecoilValue } from "recoil";
import { userInfoAtom } from "../../recoil/atoms/userInfo.atom";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { securedApi } from "../../api";
import { Button } from "reactstrap";
import UniversalModal from "../Modals/UniversalModal/UniversalModal";
import CustomSpinner from "../CustomSpinner/CustomSpinner";

interface AddNewBuildingPlan {
  name: "AddNewBuildingPlan";
}

interface DeleteBuildingPlan {
  name: "DeleteBuildingPlan";
}

type VisibleModal = AddNewBuildingPlan | DeleteBuildingPlan | undefined;

type BuildingPlanListProps = {
  projectName: string;
};

const BuildingPlanList: React.FC<BuildingPlanListProps> = ({ projectName }) => {
  const userInfo = useRecoilValue(userInfoAtom);
  const { projectId } = useParams();

  const [visibleModal, setVisibleModal] = useState<VisibleModal>();
  const [, setErrorMessage] = useState<string>("");
  const [fileToUpload, setFileToUpload] = useState(null);
  const [, setFileName] = useState();
  const [buildingPlans, setBuildingPlans] = useState<BuildingPlan[]>();
  const [created, setCreated] = useState<boolean>(false);
  const [createdBuildingPlanId, setCreatedBuildingPlanId] = useState<
    undefined | string
  >();
  const [selectedBuildingPlan, setSelectedBuildingPlan] = useState();
  const [flightPlanIds, setFlightPlanIds] = useState<{ [key: string]: string }>(
    {}
  );
  const [missionIds, setMissionIds] = useState<{ [key: string]: string }>({});
  const [missions, setMissions] = useState<Mission[]>([]);

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    customClass: "toast-custom-class",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const createNewBuildingPlan = useCallback(
    (newBuildingPlan: any) => {
      securedApi
        .put(
          `/projects/${projectId}/buildingPlans/${createdBuildingPlanId}`,
          newBuildingPlan
        )
        .then(() => {
          setCreated(true);
          setVisibleModal(undefined);
          Toast.fire({
            icon: "success",
            title: "New building plan created",
          });
        })
        .catch((error) => {
          console.error(error);
          Toast.fire({
            icon: "warning",
            title: "Something went wrong with new building plan creation",
          });
        });
    },
    [Toast, createdBuildingPlanId, projectId]
  );

  const deleteBuildingPlan = useCallback(() => {
    securedApi
      .delete(`/projects/${projectId}/buildingPlans/${selectedBuildingPlan}`)
      .then(() => {
        setVisibleModal(undefined);
        setSelectedBuildingPlan(undefined);
        Toast.fire({
          icon: "success",
          title: "Building plan deleted",
        });
      })
      .catch((error) => {
        console.error(error);
        Toast.fire({
          icon: "warning",
          title: "Something went wrong with building plan deletion",
        });
      });
  }, [Toast, projectId, selectedBuildingPlan]);

  const handleModalClose = useCallback(() => {
    securedApi
      .delete(`/projects/${projectId}/buildingPlans/${createdBuildingPlanId}`)
      .then(() => {})
      .catch((error) => {
        console.error(error);
      });

    setVisibleModal(undefined);
  }, [createdBuildingPlanId, projectId]);

  const handleModalOpen = useCallback((modalType: any) => {
    setVisibleModal({ name: modalType });
  }, []);

  const renderModals = useCallback(() => {
    if (visibleModal !== undefined) {
      switch (visibleModal.name) {
        case "AddNewBuildingPlan":
          return (
            <AddNewBuildingPlanModal
              onClose={handleModalClose}
              createdBuildingPlanId={createdBuildingPlanId}
              createNewBuildingPlan={createNewBuildingPlan}
              setErrorMessage={setErrorMessage}
            />
          );
        case "DeleteBuildingPlan":
          return (
            <UniversalModal
              onClose={handleModalClose}
              handleAction={deleteBuildingPlan}
              setErrorMessage={setErrorMessage}
              title={"Delete building plan"}
              text={"Are you sure you want to delete this building plan?"}
              buttonName={"Delete"}
            />
          );
      }
    }
  }, [
    createNewBuildingPlan,
    createdBuildingPlanId,
    deleteBuildingPlan,
    handleModalClose,
    visibleModal,
  ]);

  useEffect(() => {
    if (fileToUpload !== null) {
      handleModalOpen("AddNewBuildingPlan");
    }
  }, [createdBuildingPlanId, fileToUpload, handleModalOpen]);

  useEffect(() => {
    const fetchBuildingPlans = () => {
      securedApi
        .get(`/projects/${projectId}/buildingPlans`)
        .then((response) => {
          const bPlans = response.data.buildingPlans;
          setBuildingPlans(bPlans);
          bPlans.forEach((bPlan: BuildingPlan) => {
            securedApi
              .get(
                `/projects/${projectId}/buildingPlans/${bPlan.buildingPlanId}/flightPlans`
              )
              .then((res) => {
                const flightPlanId = res.data.flightPlans[0]?.flightPlanId;
                if (flightPlanId) {
                  setFlightPlanIds((flightPlanIds) => ({
                    ...flightPlanIds,
                    [bPlan.buildingPlanId]: flightPlanId,
                  }));

                  securedApi
                    .get(
                      `/projects/${projectId}/buildingPlans/${bPlan.buildingPlanId}/flightPlans/${flightPlanId}/missions`
                    )
                    .then((res) => {
                      const missionId = res.data.missions[0].missionId;
                      if (res.data.missions) {
                        setMissions((missions) => [
                          ...missions,
                          res.data.missions[0],
                        ]);
                      }
                      if (missionId) {
                        setMissionIds((missionIds) => ({
                          ...missionIds,
                          [bPlan.buildingPlanId]: missionId,
                        }));
                      } else {
                        console.warn("no mission found");
                      }
                    })
                    .catch((error) => console.error(error));
                } else {
                  console.warn("no flight plan found");
                }
              })
              .catch((error) => console.error(error));
          });
        })
        .catch((error) => console.error(error));
    };

    if (projectId) {
      fetchBuildingPlans();
      setCreated(false);
    }
  }, [selectedBuildingPlan, created, projectId, userInfo.tenantName]);

  const isURL = (str: any) => {
    const urlPattern = /^(https?:\/\/)?([\w.-]+)\.([a-z]{2,})(\/\S*)?$/i;
    return urlPattern.test(str);
  };

  return (
    <>
      {renderModals()}

      <Container className="buildingPlanList">
        <h1 className="text-truncate" title={projectName}>
          {projectName}
        </h1>

        {buildingPlans === undefined ? (
          <div
            className="d-flex w-100 justify-content-center align-items-center"
            style={{ height: "600px" }}
          >
            <CustomSpinner />
          </div>
        ) : null}

        {buildingPlans?.length !== 0 ? (
          buildingPlans?.map((buildingPlan) => {
            const bId = buildingPlan.buildingPlanId;

            const flightLink =
              flightPlanIds[bId] && missionIds[bId]
                ? `/projects/${projectId}/flight/${bId}/flightPlan/${flightPlanIds[bId]}/mission/${missionIds[bId]}`
                : undefined;

            const firstTileLink =
              userInfo.type === "limited"
                ? flightLink
                : `/projects/${buildingPlan?.projectId}/flightPlan/${bId}`;

            const analysisLink =
              flightPlanIds[bId] && missionIds[bId]
                ? `/projects/${projectId}/analysis/${bId}/flightPlan/${flightPlanIds[bId]}/mission/${missionIds[bId]}`
                : undefined;

            const missionForBuildingPlan = missions
              ? missions.find(
                  (el: any) => el.buildingPlanId === buildingPlan.buildingPlanId
                )?.gridThumbnailUrl
              : null;

            return (
              <Container key={bId} className="d-flex align-items-center">
                <Container>
                  <Row>
                    <BuildingPlanCard
                      label={buildingPlan.name}
                      labelDescription={"Arch plan:"}
                      imageUrl={buildingPlan.mapPreviewLocation}
                      linkUrl={firstTileLink}
                    />
                    <BuildingPlanCard
                      label={buildingPlan.name}
                      labelDescription={"Scan:"}
                      imageUrl={missionForBuildingPlan}
                      linkUrl={
                        missionForBuildingPlan ? analysisLink : firstTileLink
                      }
                    />
                    <BuildingPlanCard
                      label={buildingPlan.name}
                      labelDescription={"Comparison:"}
                      imageUrl={
                        isURL(buildingPlan.comparisonPreviewLocation)
                          ? buildingPlan.comparisonPreviewLocation
                          : null
                      }
                      linkUrl={analysisLink}
                    />
                  </Row>
                </Container>
                {userInfo.type === "privileged" && (
                  <Button
                    size="sm"
                    className="btn-link btn-icon mb-4"
                    onClick={() => {
                      handleModalOpen("DeleteBuildingPlan");
                      // @ts-ignore
                      setSelectedBuildingPlan(buildingPlan.buildingPlanId);
                    }}
                  >
                    <i className="tim-icons icon-trash-simple" />
                  </Button>
                )}
              </Container>
            );
          })
        ) : (
          <h4>There are no building plans for this project.</h4>
        )}
        {userInfo.type === "privileged" && buildingPlans !== undefined ? (
          <Container className="d-flex align-items-center">
            <Container>
              <Row className="d-flex justify-content-start">
                <div className="buildingPlanCard col-lg-4 col-md-6">
                  <DragAndDropUpload
                    setFileToUpload={setFileToUpload}
                    setFileName={setFileName}
                    setCreatedBuildingPlanId={setCreatedBuildingPlanId}
                  />
                </div>
              </Row>
            </Container>
            <div className="buttonEquivalentFiller"></div>
          </Container>
        ) : null}
      </Container>
    </>
  );
};

export default BuildingPlanList;
