import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Container,
  FormGroup,
  Input,
  Row,
  Table,
  Label,
} from "reactstrap";
import CustomSidebar from "../../components/CustomSidebar/CustomSidebar";

import { fetchMockUsers } from "../../api/users";
import { User } from "../../types/user";
import "./styles.scss";
import MeetingModal from "../../components/Modals/MeetingModal/MeetingModal";
import CreateMeetingModal from "../../components/Modals/CreateMeetingModal/CreateMeetingModal";
import { fetchMockMeeting } from "../../api/meeting";
import { Meeting } from "../../types/meeting";
import { HiUserGroup } from "react-icons/hi";
import { IconContext } from "react-icons";

interface SendMeetingInvitationsModal {
  name: "SendMeetingInvitations";
}
interface MeetingConfigModal {
  name: "MeetingConfig";
}

type VisibleModal =
  | SendMeetingInvitationsModal
  | MeetingConfigModal
  | undefined;

const MeetingPlanner: React.FC = () => {
  const [visibleModal, setVisibleModal] = useState<VisibleModal>();
  const [, setErrorMessage] = useState<string>("");
  const [selectedUsers, setSelectedUsers] = useState<User[]>();
  const [meeting, setMeeting] = useState<Meeting>();

  const [users, setUsers] = useState<User[]>();

  useEffect(() => {
    fetchMockUsers().then((users) => {
      setUsers(users);
      setSelectedUsers(users);
    });
    fetchMockMeeting().then((meeting) => {
      setMeeting(meeting);
    });
  }, []);

  const handleModalClose = useCallback(() => {
    setVisibleModal(undefined);
  }, []);

  const handleModalOpen = useCallback((modalType: any) => {
    setVisibleModal({ name: modalType });
  }, []);

  const renderModals = useCallback(() => {
    if (visibleModal !== undefined) {
      switch (visibleModal.name) {
        case "SendMeetingInvitations":
          return (
            <MeetingModal
              onClose={handleModalClose}
              setErrorMessage={setErrorMessage}
              selectedUsers={selectedUsers}
            />
          );
        case "MeetingConfig":
          return (
            <CreateMeetingModal
              onClose={handleModalClose}
              setErrorMessage={setErrorMessage}
              meeting={meeting}
              setMeeting={setMeeting}
            />
          );
      }
    }
  }, [handleModalClose, meeting, selectedUsers, visibleModal]);

  return (
    <Container className="usersManagerContainer pt-5">
      <CustomSidebar />
      <div className="d-flex justify-content-between">
        <h2>
          {meeting?.name} - {meeting?.date}
          {" | "}
          {meeting?.time}
        </h2>{" "}
        <div>
          <Button
            onClick={() => handleModalOpen("MeetingConfig")}
            className="btn-icon btn-simple"
            color="success"
            size="sm"
          >
            <i className="fa fa-edit"></i>
          </Button>
          {` `}
          <Button className="btn-icon btn-simple" color="danger" size="sm">
            <i className="fa fa-times" />
          </Button>
        </div>
      </div>
      <hr className="solid" style={{ borderColor: "#c3c3c3" }} />
      <h3>Contributors</h3>
      <Table responsive striped>
        <thead className="text-primary">
          <tr>
            <th className="text-center"></th>
            <th>Name</th>
            <th>Email</th>
            <th className="text-center">Role</th>
            <th className="text-center">Accepted</th>
            <th className="text-right">Invitation</th>
          </tr>
        </thead>
        <tbody>
          {users?.map((user) => (
            <tr key={user.userId}>
              <td className="text-center">
                <FormGroup check>
                  <Label check>
                    <Input defaultChecked type="checkbox" />
                    <span className="form-check-sign" />
                  </Label>
                </FormGroup>
              </td>
              <td>{user.name}</td>
              <td>{user.email}</td>
              <td className="text-center">{user.role}</td>
              <td className="text-center">
                {/* {" "}
                {user.acceptedMeetingInvitation ? (
                  <FcCheckmark />
                ) : user.invitationTS ? (
                  <FaTimes color="red" />
                ) : null} */}
              </td>
              <td className="text-right">
                {/* {user.invitationTS ? `sent ${user.invitationTS}` : null} */}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Row className="justify-content-end">
        <Button
          className="btn-facebook"
          onClick={() => handleModalOpen("SendMeetingInvitations")}
        >
          <div>
            <i className="tim-icons icon-send pr-2 font-weight-bold" />
            Send invitations for meeting
          </div>
        </Button>
      </Row>
      {renderModals()}
      <hr
        className="solid"
        style={{ borderColor: "#c3c3c3", marginTop: "150px" }}
      />
      <h2>Meetings history</h2>

      <Table responsive striped>
        <thead className="text-primary">
          <tr>
            <th>Name</th>
            <th className="text-right">Contributors</th>
            <th className="text-right">Date</th>
          </tr>
        </thead>
        <tbody>
          <tr className="historyMeetingRow">
            <td className="historyMeetingName">Very important call</td>
            <td className="text-right">
              15{" "}
              <IconContext.Provider value={{ size: "1rem" }}>
                <HiUserGroup style={{ marginBottom: "5px" }} />
              </IconContext.Provider>
            </td>
            <td className="text-right">2023-03-13</td>
          </tr>
          <tr className="historyMeetingRow">
            <td>Another meeting</td>
            <td className="text-right">
              4{" "}
              <IconContext.Provider value={{ size: "1rem" }}>
                <HiUserGroup style={{ marginBottom: "5px" }} />
              </IconContext.Provider>
            </td>
            <td className="text-right">2023-01-05</td>
          </tr>
        </tbody>
      </Table>
    </Container>
  );
};

export default MeetingPlanner;
