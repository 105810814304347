const url = document.URL.split("/");
const index = url.indexOf("projects");
const projectID = url[index + 1];

const magicLinkToken = localStorage.getItem("magicLinkToken");

const RoutesCognitoUser = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "tim-icons icon-chart-pie-36",
    layout: "",
  },
  {
    path: projectID,
    name: "Project",
    icon: "tim-icons icon-app",
    layout: "/projects/",
  },
  {
    path: projectID + "/users",
    name: "Users",
    icon: "tim-icons icon-single-02",
    layout: "/projects/",
  },
  {
    path: projectID + "/meeting",
    name: "Meeting",
    icon: "tim-icons icon-vector",
    layout: "/projects/",
  },
];

const RoutesMagicLinkUser = [
  {
    path: projectID,
    name: "Project",
    icon: "tim-icons icon-app",
    layout: "/projects/",
  },
];

let ProjectRoutes = [{}];

magicLinkToken
  ? (ProjectRoutes = RoutesMagicLinkUser)
  : (ProjectRoutes = RoutesCognitoUser);

export default ProjectRoutes;
