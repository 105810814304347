import React, { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, Table } from "reactstrap";
import { useParams } from "react-router-dom";

import { securedApi } from "../../../api";
import { Drone } from "../../../types/drone";
import Select from "react-select";
import { Mission } from "../../../types/project";
import { FlightParams } from "../../../pages/Flight/Flight";

interface Props {
  onClose: () => void;
  selectedDrone: any;
  setSelectedDrone: any;
  setErrorMessage: (val: string) => void;
  mission: Mission | undefined;
}

const SelectDroneModal: React.FC<Props> = ({
  onClose,
  selectedDrone,
  setSelectedDrone,
}) => {
  const { projectId } = useParams<FlightParams>();
  const [drones, setDrones] = useState<Drone[]>();
  const [selected, setSelected] = useState<Drone | undefined>();

  useEffect(() => {
    securedApi
      .get(`/uavs`)
      .then((response) => {
        setDrones(response.data.uavs);
      })
      .catch((error) => console.error(error));
  }, [projectId]);

  const options: any = drones?.map((uav) => {
    return {
      value: uav.id,
      label: `${uav.name} | ${uav.id}`,
    };
  });

  const handleSelectDrone = (id: string) => {
    setSelected(drones?.filter((drone) => drone.id === id)[0]);
  };

  return (
    <Modal
      isOpen={true}
      toggle={onClose}
      modalClassName="modal-black"
      size="lg"
    >
      <div className="modal-header">
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={onClose}
        >
          <i className="tim-icons icon-simple-remove"></i>
        </button>
        <h5 className="modal-title">Select drone for flight</h5>
      </div>
      <ModalBody>
        <Select
          id="selectDrone"
          className="react-select primary select-customization"
          classNamePrefix="react-select"
          name="droneSelect"
          onChange={(event: any) => handleSelectDrone(event.value)}
          options={options}
          placeholder={selectedDrone?.name || "Select Drone"}
        />

        {selected && (
          <Table striped className="usersInvitationList">
            <thead className="text-primary">
              <tr>
                <th className="text-center">Name</th>
                <th className="text-center">ID</th>
                <th className="text-center">Status</th>
                <th className="text-center">Description</th>
              </tr>
            </thead>
            <tbody>
              {drones
                ?.filter((drone) => drone.id === selected.id)
                .map((drone) => (
                  <tr key={drone.id}>
                    <td className="text-center">{drone.name}</td>
                    <td className="text-center">{drone.id}</td>
                    <td className="text-center">{drone.status}</td>
                    <td className="text-center">{drone.description}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onClose}>
          Close
        </Button>
        <Button
          onClick={() => {
            setSelectedDrone(selected);
            onClose();
          }}
          color="info"
          className="ml-5"
        >
          Assign drone to flight
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default SelectDroneModal;
