import React, { useCallback, useEffect, useState } from "react";
import { IconContext } from "react-icons";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { MdArrowLeft } from "react-icons/md";
import { Direction } from "../FlightControlPanel";
import "./styles.scss";

interface Props {
  positionAltitude: number;
  handleAltitude: (val: number) => void;
}
const DroneAltitude: React.FC<Props> = ({
  positionAltitude,
  handleAltitude,
}) => {
  const [highlightUp, setHighlightUp] = useState<boolean>(false);
  const [highlightDown, setHighlightDown] = useState<boolean>(false);

  const highlight = useCallback((direction: Direction) => {
    if (direction === Direction.Up) {
      setHighlightUp(true);
      setTimeout(() => {
        setHighlightUp(false);
      }, 500);
    } else if (direction === Direction.Down) {
      setHighlightDown(true);
      setTimeout(() => {
        setHighlightDown(false);
      }, 500);
    }
  }, []);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      switch (event.key) {
        case "+":
          handleAltitude(Direction.Up);
          highlight(Direction.Up);
          break;
        case "-":
          handleAltitude(Direction.Down);
          highlight(Direction.Down);
          break;
        default:
          break;
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleAltitude, highlight]);

  return (
    <div className="droneHeightContainer">
      <div className="heightRuler">
        <img
          className="rulerImg"
          src={require("./heightRuler.png")}
          style={{ marginTop: `${positionAltitude}px` }}
          alt="altitude measure"
        />
      </div>
      <div className="daaveSideIcon">
        <div className="containerIcon ">
          <div
            onClick={() => {
              handleAltitude(Direction.Up);
              highlight(Direction.Up);
            }}
            className={`arrowMoveUp transparent-button ${
              highlightUp ? "highlight-icon" : ""
            }`}
          >
            <IconContext.Provider value={{ size: "2rem" }}>
              <IoIosArrowUp style={{ color: "white" }} />
            </IconContext.Provider>
          </div>
          <div className="pointer">
            <div className="pointerArrow">
              <IconContext.Provider value={{ size: "2rem" }}>
                <MdArrowLeft style={{ color: "white" }} />
              </IconContext.Provider>
            </div>
            <img
              className="droneImg"
              src={require("./daave_side_view.png")}
              alt="drone"
            />
          </div>
          <div
            onClick={() => {
              handleAltitude(Direction.Down);
              highlight(Direction.Down);
            }}
            className={`arrowMoveDown transparent-button ${
              highlightDown ? "highlight-icon" : ""
            }`}
          >
            <IconContext.Provider value={{ size: "2rem" }}>
              <IoIosArrowDown style={{ color: "white" }} />
            </IconContext.Provider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DroneAltitude;
