import React, { useEffect, useRef, useState } from "react";
import OvenPlayer from "ovenplayer";
import "./styles.scss";

import img from "../../img/ovenplayer_background.png";
import { useRecoilState } from "recoil";
import { flightScreenAtom } from "../../recoil/atoms/flightScreen.atom";

interface Props {
  url: string;
}
const CameraStreamView: React.FC<Props> = ({ url }) => {
  const playerRef = useRef<any>(null);
  const [start, setStart] = useState<boolean>(false);
  const [flightScreen, setFlightScreen] = useRecoilState(flightScreenAtom);
  const [isVideoReady, setIsVideoReady] = useState(false);

  useEffect(() => {
    const checkUrl = async () => {
      try {
        const response = await fetch(url!);
        if (response.status === 200) {
          clearInterval(intervalId);
          createPlayer();
        }
      } catch (error) {
        console.error(error);
      }
    };

    const createPlayer = () => {
      const player = OvenPlayer.create("player_id", {
        sources: [
          {
            type: "llhls",
            file: url,
          },
        ],
        image: img,
        showBigPlayButton: false,
      });

      playerRef.current = player;

      return () => {
        player.remove();
      };
    };

    const intervalId = setInterval(checkUrl, 3000);

    return () => {
      clearInterval(intervalId);
    };
  }, [url]);

  useEffect(() => {
    const player = playerRef.current;

    if (player) {
      player.seek(0);
      setTimeout(() => {
        setIsVideoReady(true);
      }, 1500);
    }
  }, [playerRef.current]);

  useEffect(() => {
    if (playerRef.current) {
      if (!start) {
        if (flightScreen.startVideo) {
          setTimeout(() => {
            playerRef.current.seek(0);
            playerRef.current.play();
            setFlightScreen((flightScreen) => ({
              ...flightScreen,
              startVideo: true,
            }));
            setStart(true);
          }, 1500);

          setTimeout(() => {
            setIsVideoReady(true);
          }, 3000);
        } else {
          playerRef.current.start();
          playerRef.current.stop();
        }
      }
    }
  }, [flightScreen.startVideo, start]);

  return (
    <div style={{ width: "100%" }}>
      <div
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          left: isVideoReady ? "0px" : "-10000px",
          top: isVideoReady ? "0px" : "-10000px",
        }}
      >
        <div
          id="player_id"
          style={{ position: "absolute", paddingTop: "1000px" }}
          className={isVideoReady ? "visible" : "hidden"}
        ></div>
      </div>
    </div>
  );
};

export default CameraStreamView;
