import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { useRecoilState } from "recoil";

import { userInfoAtom } from "../../recoil/atoms/userInfo.atom";
import MapComponent from "../../components/Map/Map";
import { Project } from "../../types/project";
import CustomSidebar from "../../components/CustomSidebar/CustomSidebar";
import "./styles.scss";
import { useParams } from "react-router-dom";
import { securedApi } from "../../api";

export type MapProps = {
  projectId?: string;
  user?: any;
};

export type MapParams = {
  projectId: string;
};

const Map: React.FC<MapProps> = ({ user }) => {
  const { projectId } = useParams<MapParams>();
  const [userInfo] = useRecoilState(userInfoAtom);
  const [projects, setProjects] = useState<Project[]>();

  useEffect(() => {
    if (projectId || !userInfo.tenantName) return;

    securedApi
      .get("/projects")
      .then((res) => {
        setProjects(res.data.projects);
      })
      .catch((error) => console.error(error));
  }, [user.signInUserSession.idToken, userInfo.tenantName, projectId]);

  useEffect(() => {
    if (!projectId || !userInfo.tenantName) return;

    securedApi.get(`/projects/${projectId}`).then((res) => {
      setProjects([res.data]);
    });
  }, [user.signInUserSession.idToken, userInfo.tenantName, projectId]);

  return (
    <div className="mapPage">
      <CustomSidebar />
      <Container className="mapContainer">
        <MapComponent projects={projects || []} />
      </Container>
    </div>
  );
};

export default withAuthenticator(Map);
