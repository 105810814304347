import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Card, CardBody, CardHeader, Col } from "reactstrap";
import { useRecoilValue } from "recoil";

import SLAM from "../../components/SLAM/SLAM";
import { ControlMode } from "../../components/SLAM/slam-lib/project-editor";
import CustomSidebar from "../../components/CustomSidebar/CustomSidebar";
import CameraStreamView from "../../components/CameraStreamView/CameraStreamView";
import FlightControlPanel from "../../components/FlightControlPanel/FlightControlPanel";
import {
  BuildingPlan,
  FlightPlanType,
  Mission,
  Project,
} from "../../types/project";
import { Marker } from "../../types/marker";
import { securedApi } from "../../api";
import useWebsockets from "../../api/websockets";
import useMarkerComms from "../../utils/useMarkerComms";
import { userInfoAtom } from "../../recoil/atoms/userInfo.atom";
import "./styles.scss";
import { User } from "../../types/user";
import { userRoles } from "../../utils/roles";
import useUAVWebsocket from "../../api/websockets/UAVWebsocket";
import { HiOutlineVideoCamera } from "react-icons/hi";
import { IconContext } from "react-icons";
import DroneLoadingModal from "../../components/Modals/DroneLoadingModal/DroneLoadingModal";
import { flightScreenAtom } from "../../recoil/atoms/flightScreen.atom";
import AnalyzeReadyModal from "../../components/Modals/AnalyzeReadyModal/AnalyzeReadyModal";

type VisibleModal = "LoadingDrone" | "AnalyzeReady" | undefined;

export type FlightParams = {
  projectId: string;
  buildingPlanId: string;
  flightPlanId: string;
  missionId: string;
};

const MODELS_PATH = "/assets/models";
const availableControlModes = [ControlMode.EditPinMarker];

const Flight: React.FC = () => {
  const navigate = useNavigate();
  const { projectId, buildingPlanId, flightPlanId, missionId } =
    useParams<FlightParams>();
  const [visibleModal, setVisibleModal] = useState<VisibleModal>();

  const [project, setProject] = useState<Project>();
  const [buildingPlan, setBuildingPlan] = useState<BuildingPlan>();
  const [flightPlan, setFlightPlan] = useState<FlightPlanType>();
  const [mission, setMission] = useState<Mission>();
  const [allUsers, setAllUsers] = useState<User[]>([]);
  const [selectedMarkerId, setSelectedMarkerId] = useState<
    string | undefined
  >();

  const [url, setUrl] = useState<string>("");
  const askForStreamVideoUrl = `http://18.159.13.108:5000/play`;

  const flightScreen = useRecoilValue(flightScreenAtom);
  const [enableAnalyze, setEnableAnalyze] = useState<boolean>(false);

  useEffect(() => {
    if (mission === undefined) {
      setEnableAnalyze(true);
    } else {
      setEnableAnalyze(false);
    }
  }, [mission]);

  const handleModalClose = useCallback(() => {
    setVisibleModal(undefined);
  }, []);

  const handleModalOpen = useCallback((modalType: VisibleModal) => {
    setVisibleModal(modalType);
  }, []);

  useEffect(() => {
    if (flightScreen.isDroneLoading) {
      handleModalOpen("LoadingDrone");
    } else {
      handleModalClose();
    }
  }, [handleModalClose, handleModalOpen, flightScreen.isDroneLoading]);

  const renderModals = useCallback(() => {
    if (visibleModal !== undefined) {
      switch (visibleModal) {
        case "LoadingDrone":
          return (
            <DroneLoadingModal
              isLoading={flightScreen.isDroneLoading}
              onClose={handleModalClose}
            />
          );
        case "AnalyzeReady":
          return <AnalyzeReadyModal onClose={handleModalClose} />;
      }
    }
  }, [handleModalClose, flightScreen.isDroneLoading, visibleModal]);

  useEffect(() => {
    if (flightScreen.startVideo) {
      setTimeout(() => {
        handleModalOpen("AnalyzeReady");
        setEnableAnalyze(true);
      }, 95000);
    }
  }, [flightScreen.startVideo, handleModalOpen]);

  useEffect(() => {
    if (flightScreen.visibleStream) {
      setUrl(`https://18.159.13.108:3334/app/${missionId}/llhls.m3u8`);
    }
  }, [askForStreamVideoUrl, missionId, flightScreen.visibleStream]);

  const userInfo = useRecoilValue(userInfoAtom);

  const {
    markers,
    undo,
    redo,
    onMarkerEvent,
    onMarkerDelete,
    onMarkerMessage,
  } = useMarkerComms({
    projectId,
    buildingPlanId,
    flightPlanId,
    missionId,
    selectedMarkerId,
    setSelectedMarkerId,
    users: allUsers,
  });
  const UAVPosition = useUAVWebsocket({
    userId: userInfo.userId,
    missionId: mission?.missionId,
    connectionType: "mission",
  });

  const { users } = useWebsockets({
    userId: userInfo.userId,
    missionId: mission?.missionId,
    connectionType: "mission",
    onMessage: onMarkerMessage,
  });

  useEffect(() => {
    const fetchUsers = () => {
      securedApi
        .get(`/projects/${projectId}/users?type=all`)
        .then((res) => {
          setAllUsers(res.data.users);
        })
        .catch((error) => console.error(error));
    };

    const fetchProject = () => {
      securedApi
        .get(`/projects/${projectId}`)
        .then((res) => {
          setProject(res.data);
        })
        .catch((error) => console.error(error));
    };

    const fetchBuildingPlan = () => {
      securedApi
        .get(`/projects/${projectId}/buildingPlans/${buildingPlanId}`)
        .then((res) => {
          setBuildingPlan({
            name: res.data.name,
            buildingPlanId: res.data.buildingPlanId,
            projectId: res.data.projectId,
            created: res.data.creationTime,
            mapFileLocation: res.data.mapFileLocation,
            description: res.data.description,
            buildingGeometry: {
              /// TODO should come from backend
              meshUrl: `${MODELS_PATH}/biuro_base1.obj`,
              outlineUrl: res.data.mapFileLocation,
              xOffset: 0,
              yOffset: 0,
              rotation: 0,
            },
          });
        })
        .catch((error) => console.error(error));
    };

    const fetchFlightPlan = () => {
      securedApi
        .get(
          `/projects/${projectId}/buildingPlans/${buildingPlanId}/flightPlans`
        )
        .then((res) => {
          if (res.data.flightPlans?.length === 0) {
            console.warn("No flight plans returned from backend!");
          } else {
            setFlightPlan({
              id: res.data.flightPlans[0]?.flightPlanId,
              coordinates: res.data.flightPlans[0]?.data || [],
            });
          }
        })
        .catch((error) => console.error(error));
    };

    const fetchMission = () => {
      securedApi
        .get(
          `/projects/${projectId}/buildingPlans/${buildingPlanId}/flightPlans/${flightPlanId}/missions/${missionId}`
        )
        .then((res) => {
          setMission({
            ...res.data,
            id: res.data.id,
            occGrid: {
              /// TODO should come from backend
              // url: `${MODELS_PATH}/entire_office_first.yaml`,
              url: "",
              xOffset: 1.68,
              yOffset: 0.06,
              rotation: 0,
              visible: true,
            },
          });
        })
        .catch((e) => console.error(e));
    };
    if (projectId && buildingPlanId && flightPlanId && missionId) {
      fetchUsers();
      fetchProject();
      fetchBuildingPlan();
      fetchFlightPlan();
      fetchMission();
    }
  }, [projectId, buildingPlanId, flightPlanId, missionId]);

  const handleAnalyzeClick = () => {
    navigate(
      `/projects/${projectId}/analysis/${buildingPlanId}/flightPlan/${flightPlanId}/mission/${missionId}`
    );
  };

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "x" && selectedMarkerId) {
        const selectedMarker = markers.find(
          (m: Marker) => m.tmpId === selectedMarkerId
        );

        if (selectedMarker && selectedMarker.userId === userInfo.userId) {
          onMarkerDelete(selectedMarkerId);
        }
      } else if (event.key === "z" && event.ctrlKey) {
        undo();
      } else if (event.key === "y" && event.ctrlKey) {
        redo();
      }
    },
    [onMarkerDelete, selectedMarkerId, undo, redo, markers, userInfo]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown, false);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  const title = `${project?.name || ""} - ${buildingPlan?.name || ""}`;

  const userColor = userInfo
    ? userRoles.find((role) => role.name === userInfo.role)?.color
    : undefined;

  return (
    <div className="flightView">
      {renderModals()}
      <CustomSidebar isExpanded={false} />
      <div className="flightContainer">
        <div className="title-bar">
          <h4 className="text-truncate" title={title}>
            {title}
          </h4>
        </div>
        <div className="main-controls">
          <Col md="12">
            <Card className="large-container">
              <SLAM
                controlMode={ControlMode.EditPinMarker}
                availableModes={availableControlModes}
                project={project}
                buildingPlan={buildingPlan}
                flightPlan={flightPlan}
                mission={mission}
                markers={markers}
                selectedMarkerId={selectedMarkerId}
                userColor={userColor}
                onMarkerEvent={onMarkerEvent}
                UAVPosition={UAVPosition}
                laserScan={UAVPosition.laserScan}
                droneVisible={true}
              />
            </Card>
          </Col>
          <div className="horizontal-panels">
            <Col md="6">
              <Card className="small-container placeholder">
                <CardBody className="centered">
                  {flightScreen.visibleStream ? (
                    <CameraStreamView url={url} />
                  ) : (
                    <IconContext.Provider value={{ size: "6rem" }}>
                      <HiOutlineVideoCamera />
                    </IconContext.Provider>
                  )}
                </CardBody>
              </Card>
            </Col>
            <Col md="6">
              <Card className="control-panel placeholder">
                <CardBody className="centered">
                  <FlightControlPanel mission={mission} />
                </CardBody>
              </Card>
            </Col>
          </div>
        </div>
      </div>
      <Card className="flight-toolbar placeholder">
        <CardHeader>
          <Button
            size="sm"
            color="secondary"
            disabled={!enableAnalyze}
            onClick={handleAnalyzeClick}
          >
            Analyze <i className="tim-icons icon-double-right pl-1" />
          </Button>
        </CardHeader>
        <CardBody>
          <div className="user-list">
            <div className="user-list-title">Users In Meeting</div>
            {users.map((user: any) => (
              <div className="user-row" title={user.name} key={user.userId}>
                <i className="tim-icons icon-single-02"></i> {user.name}
              </div>
            ))}
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default Flight;
