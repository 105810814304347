import React from "react";
import "./styles.scss";

const ErrorPage: React.FC<{ error?: string }> = ({ error }) => {
  return (
    <div className="d-flex w-100 justify-content-center align-items-center error-page">
      <h1>Error</h1>
      <p>{error}</p>
    </div>
  );
};

export default ErrorPage;
